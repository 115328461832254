import "./index.css";
import React, {useContext} from "../../../_snowpack/pkg/react.js";
import HoldingsInput from "../HoldingsInput/index.js";
import {StockContext} from "../../context/StockContext.js";
const CurrentHoldings = () => {
  const {
    newPrice,
    company,
    newQuantity,
    setCurrentHoldingsPrice,
    currentHoldingsPrice,
    setCurrentQuantity,
    currentQuantity
  } = useContext(StockContext);
  const validatePrice = ({target: {value}}) => {
    setCurrentHoldingsPrice(Number(value));
    if (Number(value) > 9999999) {
      setCurrentHoldingsPrice(Number(value.slice(0, 7)));
    }
  };
  const validateQuantity = ({target: {value}}) => {
    setCurrentQuantity(Number(value));
    if (Number(value) > 99999) {
      setCurrentQuantity(Number(value.slice(0, 5)));
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, !!company && newQuantity !== 0 && /* @__PURE__ */ React.createElement("div", {
    className: "current-holdings-form-wrapper"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "container"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "input-container"
  }, /* @__PURE__ */ React.createElement(HoldingsInput, {
    name: "holdings-price",
    onChange: validatePrice,
    beforeText: "내가 가진 평균 단가는",
    afterText: " 원,",
    strong: "strong",
    value: currentHoldingsPrice === 0 ? "" : currentHoldingsPrice
  }), /* @__PURE__ */ React.createElement(HoldingsInput, {
    name: "quantity",
    onChange: validateQuantity,
    beforeText: "총 ",
    afterText: " 주 있어요.",
    value: currentQuantity === 0 ? "" : currentQuantity
  })))));
};
export default CurrentHoldings;
