export const LOCALE = {
  US: {
    locale: 'en-US',
    currency: 'USD',
  },
  KR: {
    locale: 'ko-KR',
    currency: 'KRW',
  },
};
