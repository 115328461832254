import React from "../../../_snowpack/pkg/react.js";
import "./index.css";
const PageWrapper = ({children}) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: "page"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "page-container"
  }, children));
};
export default PageWrapper;
