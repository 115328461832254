export const stockData = [
  {
    id: 0,
    code: '060310',
    companyName: '3S',
    market: 'KOSDAQ',
    marketCap: 145061193255,
  },
  {
    id: 1,
    code: '054620',
    companyName: 'APS홀딩스',
    market: 'KOSDAQ',
    marketCap: 305913315000,
  },
  {
    id: 2,
    code: 265520,
    companyName: 'AP시스템',
    market: 'KOSDAQ',
    marketCap: 435854832700,
  },
  {
    id: 3,
    code: 211270,
    companyName: 'AP위성',
    market: 'KOSDAQ',
    marketCap: 242825094400,
  },
  {
    id: 4,
    code: '035760',
    companyName: 'CJ ENM',
    market: 'KOSDAQ',
    marketCap: 3894617750400,
  },
  {
    id: 5,
    code: '051500',
    companyName: 'CJ프레시웨이',
    market: 'KOSDAQ',
    marketCap: 426783516700,
  },
  {
    id: 6,
    code: '058820',
    companyName: 'CMG제약',
    market: 'KOSDAQ',
    marketCap: 765296264440,
  },
  {
    id: 7,
    code: '023460',
    companyName: 'CNH',
    market: 'KOSDAQ',
    marketCap: 133734000000,
  },
  {
    id: 8,
    code: '056000',
    companyName: 'COWON',
    market: 'KOSDAQ',
    marketCap: 56399843500,
  },
  {
    id: 9,
    code: '065770',
    companyName: 'CS',
    market: 'KOSDAQ',
    marketCap: 122240123500,
  },
  {
    id: 10,
    code: '083660',
    companyName: 'CSA 코스믹',
    market: 'KOSDAQ',
    marketCap: 74514810450,
  },
  {
    id: 11,
    code: 367340,
    companyName: 'DB금융스팩8호',
    market: 'KOSDAQ',
    marketCap: 12886500000,
  },
  {
    id: 12,
    code: 367360,
    companyName: 'DB금융스팩9호',
    market: 'KOSDAQ',
    marketCap: 10381800000,
  },
  {
    id: 13,
    code: '068790',
    companyName: 'DMS',
    market: 'KOSDAQ',
    marketCap: 191669875800,
  },
  {
    id: 14,
    code: 241520,
    companyName: 'DSC인베스트먼트',
    market: 'KOSDAQ',
    marketCap: 160471795310,
  },
  {
    id: 15,
    code: 245620,
    companyName: 'EDGC',
    market: 'KOSDAQ',
    marketCap: 404769563000,
  },
  {
    id: 16,
    code: '037370',
    companyName: 'EG',
    market: 'KOSDAQ',
    marketCap: 86250000000,
  },
  {
    id: 17,
    code: '079190',
    companyName: 'EMW',
    market: 'KOSDAQ',
    marketCap: 88360067660,
  },
  {
    id: 18,
    code: '050120',
    companyName: 'ES큐브',
    market: 'KOSDAQ',
    marketCap: 96305010600,
  },
  {
    id: 19,
    code: 130500,
    companyName: 'GH신소재',
    market: 'KOSDAQ',
    marketCap: 71390000000,
  },
  {
    id: 20,
    code: 900290,
    companyName: 'GRT',
    market: 'KOSDAQ',
    marketCap: 64073625000,
  },
  {
    id: 21,
    code: '083450',
    companyName: 'GST',
    market: 'KOSDAQ',
    marketCap: 254840325750,
  },
  {
    id: 22,
    code: '028150',
    companyName: 'GS홈쇼핑',
    market: 'KOSDAQ',
    marketCap: 1012593750000,
  },
  {
    id: 23,
    code: '045890',
    companyName: 'GV',
    market: 'KOSDAQ',
    marketCap: 20823884880,
  },
  {
    id: 24,
    code: '078150',
    companyName: 'HB테크놀러지',
    market: 'KOSDAQ',
    marketCap: 214459006590,
  },
  {
    id: 25,
    code: '036640',
    companyName: 'HRS',
    market: 'KOSDAQ',
    marketCap: 97474608000,
  },
  {
    id: 26,
    code: 335870,
    companyName: 'IBKS제12호스팩',
    market: 'KOSDAQ',
    marketCap: 6848400000,
  },
  {
    id: 27,
    code: 351340,
    companyName: 'IBKS제13호스팩',
    market: 'KOSDAQ',
    marketCap: 11141350000,
  },
  {
    id: 28,
    code: 351320,
    companyName: 'IBKS제14호스팩',
    market: 'KOSDAQ',
    marketCap: 9872400000,
  },
  {
    id: 29,
    code: 373200,
    companyName: 'IBKS제15호스팩',
    market: 'KOSDAQ',
    marketCap: 7603200000,
  },
  {
    id: 30,
    code: '095340',
    companyName: 'ISC',
    market: 'KOSDAQ',
    marketCap: 363070477200,
  },
  {
    id: 31,
    code: '099520',
    companyName: 'ITX-AI',
    market: 'KOSDAQ',
    marketCap: 37652662655,
  },
  {
    id: 32,
    code: 950170,
    companyName: 'JTC',
    market: 'KOSDAQ',
    marketCap: 221234867440,
  },
  {
    id: 33,
    code: '067290',
    companyName: 'JW신약',
    market: 'KOSDAQ',
    marketCap: 220563027160,
  },
  {
    id: 34,
    code: '035900',
    companyName: 'JYP Ent.',
    market: 'KOSDAQ',
    marketCap: 1448297673600,
  },
  {
    id: 35,
    code: '024840',
    companyName: 'KBI메탈',
    market: 'KOSDAQ',
    marketCap: 100500086400,
  },
  {
    id: 36,
    code: '024120',
    companyName: 'KB오토시스',
    market: 'KOSDAQ',
    marketCap: 107640000000,
  },
  {
    id: 37,
    code: '021320',
    companyName: 'KCC건설',
    market: 'KOSDAQ',
    marketCap: 222560000000,
  },
  {
    id: 38,
    code: '036670',
    companyName: 'KCI',
    market: 'KOSDAQ',
    marketCap: 113827000000,
  },
  {
    id: 39,
    code: '044180',
    companyName: 'KD',
    market: 'KOSDAQ',
    marketCap: 22887623600,
  },
  {
    id: 40,
    code: 151860,
    companyName: 'KG ETS',
    market: 'KOSDAQ',
    marketCap: 561600000000,
  },
  {
    id: 41,
    code: '046440',
    companyName: 'KG모빌리언스',
    market: 'KOSDAQ',
    marketCap: 405727185600,
  },
  {
    id: 42,
    code: '035600',
    companyName: 'KG이니시스',
    market: 'KOSDAQ',
    marketCap: 606921439500,
  },
  {
    id: 43,
    code: 226360,
    companyName: 'KH E&T',
    market: 'KOSDAQ',
    marketCap: 129044444625,
  },
  {
    id: 44,
    code: 111870,
    companyName: 'KH 일렉트론',
    market: 'KOSDAQ',
    marketCap: 113425666480,
  },
  {
    id: 45,
    code: '060720',
    companyName: 'KH바텍',
    market: 'KOSDAQ',
    marketCap: 475845450300,
  },
  {
    id: 46,
    code: 122450,
    companyName: 'KMH',
    market: 'KOSDAQ',
    marketCap: 557632357200,
  },
  {
    id: 47,
    code: '052900',
    companyName: 'KMH하이텍',
    market: 'KOSDAQ',
    marketCap: 91426723050,
  },
  {
    id: 48,
    code: '058400',
    companyName: 'KNN',
    market: 'KOSDAQ',
    marketCap: 271480926000,
  },
  {
    id: 49,
    code: 114450,
    companyName: 'KPX생명과학',
    market: 'KOSDAQ',
    marketCap: 138450000000,
  },
  {
    id: 50,
    code: '036030',
    companyName: 'KTH',
    market: 'KOSDAQ',
    marketCap: 444652023900,
  },
  {
    id: 51,
    code: '060370',
    companyName: 'KT서브마린',
    market: 'KOSDAQ',
    marketCap: 176295000000,
  },
  {
    id: 52,
    code: '065150',
    companyName: 'MP그룹',
    market: 'KOSDAQ',
    marketCap: 125908817025,
  },
  {
    id: 53,
    code: 160550,
    companyName: 'NEW',
    market: 'KOSDAQ',
    marketCap: 284642281200,
  },
  {
    id: 54,
    code: '053290',
    companyName: 'NE능률',
    market: 'KOSDAQ',
    marketCap: 418115567100,
  },
  {
    id: 55,
    code: 104200,
    companyName: 'NHN벅스',
    market: 'KOSDAQ',
    marketCap: 91930810000,
  },
  {
    id: 56,
    code: '060250',
    companyName: 'NHN한국사이버결제',
    market: 'KOSDAQ',
    marketCap: 1337851187400,
  },
  {
    id: 57,
    code: '030190',
    companyName: 'NICE평가정보',
    market: 'KOSDAQ',
    marketCap: 1472334385000,
  },
  {
    id: 58,
    code: '078590',
    companyName: 'OQP',
    market: 'KOSDAQ',
    marketCap: 322897066620,
  },
  {
    id: 59,
    code: 178920,
    companyName: 'PI첨단소재',
    market: 'KOSDAQ',
    marketCap: 1468316100000,
  },
  {
    id: 60,
    code: '024940',
    companyName: 'PN풍년',
    market: 'KOSDAQ',
    marketCap: 102000000000,
  },
  {
    id: 61,
    code: 218410,
    companyName: 'RFHIC',
    market: 'KOSDAQ',
    marketCap: 964636229000,
  },
  {
    id: 62,
    code: 327260,
    companyName: 'RF머트리얼즈',
    market: 'KOSDAQ',
    marketCap: 114910986600,
  },
  {
    id: 63,
    code: '091340',
    companyName: 'S&K폴리텍',
    market: 'KOSDAQ',
    marketCap: 70573437630,
  },
  {
    id: 64,
    code: '019550',
    companyName: 'SBI인베스트먼트',
    market: 'KOSDAQ',
    marketCap: 299012830875,
  },
  {
    id: 65,
    code: 950110,
    companyName: 'SBI핀테크솔루션즈',
    market: 'KOSDAQ',
    marketCap: 205851222400,
  },
  {
    id: 66,
    code: '046140',
    companyName: 'SBS콘텐츠허브',
    market: 'KOSDAQ',
    marketCap: 200684239250,
  },
  {
    id: 67,
    code: '036120',
    companyName: 'SCI평가정보',
    market: 'KOSDAQ',
    marketCap: 145550000000,
  },
  {
    id: 68,
    code: '099220',
    companyName: 'SDN',
    market: 'KOSDAQ',
    marketCap: 139718270600,
  },
  {
    id: 69,
    code: '036540',
    companyName: 'SFA반도체',
    market: 'KOSDAQ',
    marketCap: 1249898302800,
  },
  {
    id: 70,
    code: 255220,
    companyName: 'SG',
    market: 'KOSDAQ',
    marketCap: 103379804160,
  },
  {
    id: 71,
    code: '040610',
    companyName: 'SG&G',
    market: 'KOSDAQ',
    marketCap: 105158999660,
  },
  {
    id: 72,
    code: '049470',
    companyName: 'SGA',
    market: 'KOSDAQ',
    marketCap: 76628345990,
  },
  {
    id: 73,
    code: 184230,
    companyName: 'SGA솔루션즈',
    market: 'KOSDAQ',
    marketCap: 86207433165,
  },
  {
    id: 74,
    code: '016250',
    companyName: 'SGC이테크건설',
    market: 'KOSDAQ',
    marketCap: 141697290000,
  },
  {
    id: 75,
    code: 307070,
    companyName: 'SK4호스팩',
    market: 'KOSDAQ',
    marketCap: 10861800000,
  },
  {
    id: 76,
    code: 337450,
    companyName: 'SK5호스팩',
    market: 'KOSDAQ',
    marketCap: 12132650000,
  },
  {
    id: 77,
    code: 340350,
    companyName: 'SK6호스팩',
    market: 'KOSDAQ',
    marketCap: 12647650000,
  },
  {
    id: 78,
    code: '036490',
    companyName: 'SK머티리얼즈',
    market: 'KOSDAQ',
    marketCap: 3418500819300,
  },
  {
    id: 79,
    code: '048550',
    companyName: 'SM C&C',
    market: 'KOSDAQ',
    marketCap: 318836104400,
  },
  {
    id: 80,
    code: '063440',
    companyName: 'SM Life Design',
    market: 'KOSDAQ',
    marketCap: 138095211240,
  },
  {
    id: 81,
    code: 950180,
    companyName: 'SNK',
    market: 'KOSDAQ',
    marketCap: 459147240000,
  },
  {
    id: 82,
    code: 289080,
    companyName: 'SV인베스트먼트',
    market: 'KOSDAQ',
    marketCap: 235294280000,
  },
  {
    id: 83,
    code: '089230',
    companyName: 'THE E&M',
    market: 'KOSDAQ',
    marketCap: 191351227560,
  },
  {
    id: 84,
    code: 161570,
    companyName: 'THE MIDONG',
    market: 'KOSDAQ',
    marketCap: 28672407200,
  },
  {
    id: 85,
    code: 192410,
    companyName: 'THQ',
    market: 'KOSDAQ',
    marketCap: 116049343375,
  },
  {
    id: 86,
    code: '032540',
    companyName: 'TJ미디어',
    market: 'KOSDAQ',
    marketCap: 77599062130,
  },
  {
    id: 87,
    code: '048770',
    companyName: 'TPC',
    market: 'KOSDAQ',
    marketCap: 64162165480,
  },
  {
    id: 88,
    code: 246690,
    companyName: 'TS인베스트먼트',
    market: 'KOSDAQ',
    marketCap: 166679948935,
  },
  {
    id: 89,
    code: 317240,
    companyName: 'TS트릴리온',
    market: 'KOSDAQ',
    marketCap: 128295987540,
  },
  {
    id: 90,
    code: '038340',
    companyName: 'UCI',
    market: 'KOSDAQ',
    marketCap: 16641828315,
  },
  {
    id: 91,
    code: '073570',
    companyName: 'WI',
    market: 'KOSDAQ',
    marketCap: 132683991720,
  },
  {
    id: 92,
    code: '057030',
    companyName: 'YBM넷',
    market: 'KOSDAQ',
    marketCap: 135721639040,
  },
  {
    id: 93,
    code: '040300',
    companyName: 'YTN',
    market: 'KOSDAQ',
    marketCap: 167370000000,
  },
  {
    id: 94,
    code: '051390',
    companyName: 'YW',
    market: 'KOSDAQ',
    marketCap: 55641303200,
  },
  {
    id: 95,
    code: '052220',
    companyName: 'iMBC',
    market: 'KOSDAQ',
    marketCap: 100510000000,
  },
  {
    id: 96,
    code: '079940',
    companyName: '가비아',
    market: 'KOSDAQ',
    marketCap: 265299406400,
  },
  {
    id: 97,
    code: '078890',
    companyName: '가온미디어',
    market: 'KOSDAQ',
    marketCap: 208453089900,
  },
  {
    id: 98,
    code: '036620',
    companyName: '감성코퍼레이션',
    market: 'KOSDAQ',
    marketCap: 102584215750,
  },
  {
    id: 99,
    code: 217730,
    companyName: '강스템바이오텍',
    market: 'KOSDAQ',
    marketCap: 153114511120,
  },
  {
    id: 100,
    code: 114190,
    companyName: '강원',
    market: 'KOSDAQ',
    marketCap: 45387277500,
  },
  {
    id: 101,
    code: '094480',
    companyName: '갤럭시아머니트리',
    market: 'KOSDAQ',
    marketCap: 273039672480,
  },
  {
    id: 102,
    code: '063080',
    companyName: '게임빌',
    market: 'KOSDAQ',
    marketCap: 262158882000,
  },
  {
    id: 103,
    code: '039240',
    companyName: '경남스틸',
    market: 'KOSDAQ',
    marketCap: 104875000000,
  },
  {
    id: 104,
    code: '053950',
    companyName: '경남제약',
    market: 'KOSDAQ',
    marketCap: 126807462090,
  },
  {
    id: 105,
    code: 223310,
    companyName: '경남제약헬스케어',
    market: 'KOSDAQ',
    marketCap: 37836250520,
  },
  {
    id: 106,
    code: '011040',
    companyName: '경동제약',
    market: 'KOSDAQ',
    marketCap: 332302672800,
  },
  {
    id: 107,
    code: '024910',
    companyName: '경창산업',
    market: 'KOSDAQ',
    marketCap: 102800544350,
  },
  {
    id: 108,
    code: 198440,
    companyName: '고려시멘트',
    market: 'KOSDAQ',
    marketCap: 170772986400,
  },
  {
    id: 109,
    code: '049720',
    companyName: '고려신용정보',
    market: 'KOSDAQ',
    marketCap: 148005000000,
  },
  {
    id: 110,
    code: '014570',
    companyName: '고려제약',
    market: 'KOSDAQ',
    marketCap: 122100000000,
  },
  {
    id: 111,
    code: 348150,
    companyName: '고바이오랩',
    market: 'KOSDAQ',
    marketCap: 510704758800,
  },
  {
    id: 112,
    code: '098460',
    companyName: '고영',
    market: 'KOSDAQ',
    marketCap: 1733532563750,
  },
  {
    id: 113,
    code: '035290',
    companyName: '골드앤에스',
    market: 'KOSDAQ',
    marketCap: 47272202550,
  },
  {
    id: 114,
    code: '038530',
    companyName: '골드퍼시픽',
    market: 'KOSDAQ',
    marketCap: 104239475020,
  },
  {
    id: 115,
    code: 900280,
    companyName: '골든센츄리',
    market: 'KOSDAQ',
    marketCap: 96220680000,
  },
  {
    id: 116,
    code: 215000,
    companyName: '골프존',
    market: 'KOSDAQ',
    marketCap: 702846480000,
  },
  {
    id: 117,
    code: 121440,
    companyName: '골프존뉴딘홀딩스',
    market: 'KOSDAQ',
    marketCap: 363256216640,
  },
  {
    id: 118,
    code: '014200',
    companyName: '광림',
    market: 'KOSDAQ',
    marketCap: 201898322710,
  },
  {
    id: 119,
    code: '026910',
    companyName: '광진실업',
    market: 'KOSDAQ',
    marketCap: 45158105250,
  },
  {
    id: 120,
    code: '090150',
    companyName: '광진윈텍',
    market: 'KOSDAQ',
    marketCap: 48968052510,
  },
  {
    id: 121,
    code: 355150,
    companyName: '교보10호스팩',
    market: 'KOSDAQ',
    marketCap: 11331600000,
  },
  {
    id: 122,
    code: 331520,
    companyName: '교보9호스팩',
    market: 'KOSDAQ',
    marketCap: 8361400000,
  },
  {
    id: 123,
    code: '053270',
    companyName: '구영테크',
    market: 'KOSDAQ',
    marketCap: 110327647320,
  },
  {
    id: 124,
    code: '066620',
    companyName: '국보디자인',
    market: 'KOSDAQ',
    marketCap: 185250000000,
  },
  {
    id: 125,
    code: '043650',
    companyName: '국순당',
    market: 'KOSDAQ',
    marketCap: 155188661760,
  },
  {
    id: 126,
    code: '006050',
    companyName: '국영지앤엠',
    market: 'KOSDAQ',
    marketCap: 107651824655,
  },
  {
    id: 127,
    code: '060480',
    companyName: '국일신동',
    market: 'KOSDAQ',
    marketCap: 57446200000,
  },
  {
    id: 128,
    code: '078130',
    companyName: '국일제지',
    market: 'KOSDAQ',
    marketCap: 731666853820,
  },
  {
    id: 129,
    code: 307750,
    companyName: '국전약품',
    market: 'KOSDAQ',
    marketCap: 227759692290,
  },
  {
    id: 130,
    code: 204020,
    companyName: '그리티',
    market: 'KOSDAQ',
    marketCap: 89656063500,
  },
  {
    id: 131,
    code: 186230,
    companyName: '그린플러스',
    market: 'KOSDAQ',
    marketCap: 154684646200,
  },
  {
    id: 132,
    code: 900070,
    companyName: '글로벌에스엠',
    market: 'KOSDAQ',
    marketCap: 67179960000,
  },
  {
    id: 133,
    code: 204620,
    companyName: '글로벌텍스프리',
    market: 'KOSDAQ',
    marketCap: 198114648915,
  },
  {
    id: 134,
    code: '019660',
    companyName: '글로본',
    market: 'KOSDAQ',
    marketCap: 141050393295,
  },
  {
    id: 135,
    code: '053260',
    companyName: '금강철강',
    market: 'KOSDAQ',
    marketCap: 141336000000,
  },
  {
    id: 136,
    code: '036190',
    companyName: '금화피에스시',
    market: 'KOSDAQ',
    marketCap: 216900000000,
  },
  {
    id: 137,
    code: '049080',
    companyName: '기가레인',
    market: 'KOSDAQ',
    marketCap: 183366321075,
  },
  {
    id: 138,
    code: '035460',
    companyName: '기산텔레콤',
    market: 'KOSDAQ',
    marketCap: 45481300800,
  },
  {
    id: 139,
    code: 308100,
    companyName: '까스텔바작',
    market: 'KOSDAQ',
    marketCap: 94992480000,
  },
  {
    id: 140,
    code: 187790,
    companyName: '나노',
    market: 'KOSDAQ',
    marketCap: 50354436375,
  },
  {
    id: 141,
    code: 286750,
    companyName: '나노브릭',
    market: 'KOSDAQ',
    marketCap: 75615259500,
  },
  {
    id: 142,
    code: 151910,
    companyName: '나노스',
    market: 'KOSDAQ',
    marketCap: 991331064490,
  },
  {
    id: 143,
    code: 121600,
    companyName: '나노신소재',
    market: 'KOSDAQ',
    marketCap: 334671073050,
  },
  {
    id: 144,
    code: 247660,
    companyName: '나노씨엠에스',
    market: 'KOSDAQ',
    marketCap: 64606922000,
  },
  {
    id: 145,
    code: '039860',
    companyName: '나노엔텍',
    market: 'KOSDAQ',
    marketCap: 211690983300,
  },
  {
    id: 146,
    code: '091970',
    companyName: '나노캠텍',
    market: 'KOSDAQ',
    marketCap: 70762671000,
  },
  {
    id: 147,
    code: '051490',
    companyName: '나라엠앤디',
    market: 'KOSDAQ',
    marketCap: 155490000000,
  },
  {
    id: 148,
    code: 190510,
    companyName: '나무가',
    market: 'KOSDAQ',
    marketCap: 120793762200,
  },
  {
    id: 149,
    code: 242040,
    companyName: '나무기술',
    market: 'KOSDAQ',
    marketCap: 109514230800,
  },
  {
    id: 150,
    code: '089600',
    companyName: '나스미디어',
    market: 'KOSDAQ',
    marketCap: 403406220000,
  },
  {
    id: 151,
    code: 293580,
    companyName: '나우IB',
    market: 'KOSDAQ',
    marketCap: 131772000000,
  },
  {
    id: 152,
    code: 138610,
    companyName: '나이벡',
    market: 'KOSDAQ',
    marketCap: 336701286000,
  },
  {
    id: 153,
    code: 130580,
    companyName: '나이스디앤비',
    market: 'KOSDAQ',
    marketCap: 167090000000,
  },
  {
    id: 154,
    code: '036800',
    companyName: '나이스정보통신',
    market: 'KOSDAQ',
    marketCap: 354000000000,
  },
  {
    id: 155,
    code: 267320,
    companyName: '나인테크',
    market: 'KOSDAQ',
    marketCap: 183193976100,
  },
  {
    id: 156,
    code: 111710,
    companyName: '남화산업',
    market: 'KOSDAQ',
    marketCap: 220291600000,
  },
  {
    id: 157,
    code: '091590',
    companyName: '남화토건',
    market: 'KOSDAQ',
    marketCap: 136771000000,
  },
  {
    id: 158,
    code: 168330,
    companyName: '내츄럴엔도텍',
    market: 'KOSDAQ',
    marketCap: 69623158200,
  },
  {
    id: 159,
    code: '094860',
    companyName: '네오리진',
    market: 'KOSDAQ',
    marketCap: 91521059905,
  },
  {
    id: 160,
    code: 253590,
    companyName: '네오셈',
    market: 'KOSDAQ',
    marketCap: 122684294820,
  },
  {
    id: 161,
    code: 212560,
    companyName: '네오오토',
    market: 'KOSDAQ',
    marketCap: 64417197340,
  },
  {
    id: 162,
    code: '095660',
    companyName: '네오위즈',
    market: 'KOSDAQ',
    marketCap: 551944660950,
  },
  {
    id: 163,
    code: '042420',
    companyName: '네오위즈홀딩스',
    market: 'KOSDAQ',
    marketCap: 269248726400,
  },
  {
    id: 164,
    code: 950220,
    companyName: '네오이뮨텍(Reg.S)',
    market: 'KOSDAQ',
    marketCap: 1072472909000,
  },
  {
    id: 165,
    code: 311390,
    companyName: '네오크레마',
    market: 'KOSDAQ',
    marketCap: 77915346750,
  },
  {
    id: 166,
    code: '085910',
    companyName: '네오티스',
    market: 'KOSDAQ',
    marketCap: 68587492450,
  },
  {
    id: 167,
    code: '092730',
    companyName: '네오팜',
    market: 'KOSDAQ',
    marketCap: 298747940400,
  },
  {
    id: 168,
    code: 290660,
    companyName: '네오펙트',
    market: 'KOSDAQ',
    marketCap: 71938395400,
  },
  {
    id: 169,
    code: 306620,
    companyName: '네온테크',
    market: 'KOSDAQ',
    marketCap: 136193266080,
  },
  {
    id: 170,
    code: 153460,
    companyName: '네이블',
    market: 'KOSDAQ',
    marketCap: 45187696880,
  },
  {
    id: 171,
    code: '007390',
    companyName: '네이처셀',
    market: 'KOSDAQ',
    marketCap: 1097385544500,
  },
  {
    id: 172,
    code: '033640',
    companyName: '네패스',
    market: 'KOSDAQ',
    marketCap: 887779277000,
  },
  {
    id: 173,
    code: 330860,
    companyName: '네패스아크',
    market: 'KOSDAQ',
    marketCap: 526350744000,
  },
  {
    id: 174,
    code: '089140',
    companyName: '넥스턴바이오',
    market: 'KOSDAQ',
    marketCap: 204352470000,
  },
  {
    id: 175,
    code: '065170',
    companyName: '넥스트BT',
    market: 'KOSDAQ',
    marketCap: 143466174600,
  },
  {
    id: 176,
    code: 137940,
    companyName: '넥스트아이',
    market: 'KOSDAQ',
    marketCap: 157702573785,
  },
  {
    id: 177,
    code: 348210,
    companyName: '넥스틴',
    market: 'KOSDAQ',
    marketCap: 657961920000,
  },
  {
    id: 178,
    code: '041140',
    companyName: '넥슨지티',
    market: 'KOSDAQ',
    marketCap: 458084519900,
  },
  {
    id: 179,
    code: 217270,
    companyName: '넵튠',
    market: 'KOSDAQ',
    marketCap: 821336189800,
  },
  {
    id: 180,
    code: 225570,
    companyName: '넷게임즈',
    market: 'KOSDAQ',
    marketCap: 338998862250,
  },
  {
    id: 181,
    code: 104620,
    companyName: '노랑풍선',
    market: 'KOSDAQ',
    marketCap: 142492935000,
  },
  {
    id: 182,
    code: 194700,
    companyName: '노바렉스',
    market: 'KOSDAQ',
    marketCap: 394363812000,
  },
  {
    id: 183,
    code: 285490,
    companyName: '노바텍',
    market: 'KOSDAQ',
    marketCap: 417384201600,
  },
  {
    id: 184,
    code: 278650,
    companyName: '노터스',
    market: 'KOSDAQ',
    marketCap: 204199062400,
  },
  {
    id: 185,
    code: 144510,
    companyName: '녹십자랩셀',
    market: 'KOSDAQ',
    marketCap: 981527022000,
  },
  {
    id: 186,
    code: '031390',
    companyName: '녹십자셀',
    market: 'KOSDAQ',
    marketCap: 511687065600,
  },
  {
    id: 187,
    code: 142280,
    companyName: '녹십자엠에스',
    market: 'KOSDAQ',
    marketCap: 209061101700,
  },
  {
    id: 188,
    code: 234690,
    companyName: '녹십자웰빙',
    market: 'KOSDAQ',
    marketCap: 208589243000,
  },
  {
    id: 189,
    code: '065560',
    companyName: '녹원씨엔아이',
    market: 'KOSDAQ',
    marketCap: 99593806250,
  },
  {
    id: 190,
    code: '054050',
    companyName: '농우바이오',
    market: 'KOSDAQ',
    marketCap: 218817157650,
  },
  {
    id: 191,
    code: '069140',
    companyName: '누리플랜',
    market: 'KOSDAQ',
    marketCap: 61509754380,
  },
  {
    id: 192,
    code: '040160',
    companyName: '누리플렉스',
    market: 'KOSDAQ',
    marketCap: 134419215250,
  },
  {
    id: 193,
    code: 126870,
    companyName: '뉴로스',
    market: 'KOSDAQ',
    marketCap: 79475797330,
  },
  {
    id: 194,
    code: '060260',
    companyName: '뉴보텍',
    market: 'KOSDAQ',
    marketCap: 53326831250,
  },
  {
    id: 195,
    code: '012340',
    companyName: '뉴인텍',
    market: 'KOSDAQ',
    marketCap: 122130900630,
  },
  {
    id: 196,
    code: 214870,
    companyName: '뉴지랩파마',
    market: 'KOSDAQ',
    marketCap: 329672810800,
  },
  {
    id: 197,
    code: 270870,
    companyName: '뉴트리',
    market: 'KOSDAQ',
    marketCap: 331265692400,
  },
  {
    id: 198,
    code: 144960,
    companyName: '뉴파워프라즈마',
    market: 'KOSDAQ',
    marketCap: 270020416320,
  },
  {
    id: 199,
    code: '085670',
    companyName: '뉴프렉스',
    market: 'KOSDAQ',
    marketCap: 42325084800,
  },
  {
    id: 200,
    code: 119860,
    companyName: '다나와',
    market: 'KOSDAQ',
    marketCap: 385707249000,
  },
  {
    id: 201,
    code: '064260',
    companyName: '다날',
    market: 'KOSDAQ',
    marketCap: 438515894400,
  },
  {
    id: 202,
    code: '093640',
    companyName: '다믈멀티미디어',
    market: 'KOSDAQ',
    marketCap: 46715814180,
  },
  {
    id: 203,
    code: '039560',
    companyName: '다산네트웍스',
    market: 'KOSDAQ',
    marketCap: 393008761600,
  },
  {
    id: 204,
    code: '032190',
    companyName: '다우데이타',
    market: 'KOSDAQ',
    marketCap: 622375000000,
  },
  {
    id: 205,
    code: '068240',
    companyName: '다원시스',
    market: 'KOSDAQ',
    marketCap: 618662377200,
  },
  {
    id: 206,
    code: '020400',
    companyName: '대동금속',
    market: 'KOSDAQ',
    marketCap: 45126698900,
  },
  {
    id: 207,
    code: '008830',
    companyName: '대동기어',
    market: 'KOSDAQ',
    marketCap: 90324576000,
  },
  {
    id: 208,
    code: '048470',
    companyName: '대동스틸',
    market: 'KOSDAQ',
    marketCap: 77300000000,
  },
  {
    id: 209,
    code: '004780',
    companyName: '대륙제관',
    market: 'KOSDAQ',
    marketCap: 99235961760,
  },
  {
    id: 210,
    code: '017650',
    companyName: '대림제지',
    market: 'KOSDAQ',
    marketCap: 130050000000,
  },
  {
    id: 211,
    code: '007720',
    companyName: '대명소노시즌',
    market: 'KOSDAQ',
    marketCap: 170352760500,
  },
  {
    id: 212,
    code: 317850,
    companyName: '대모',
    market: 'KOSDAQ',
    marketCap: 52527090200,
  },
  {
    id: 213,
    code: 290670,
    companyName: '대보마그네틱',
    market: 'KOSDAQ',
    marketCap: 258848065000,
  },
  {
    id: 214,
    code: '078140',
    companyName: '대봉엘에스',
    market: 'KOSDAQ',
    marketCap: 123615355850,
  },
  {
    id: 215,
    code: '036480',
    companyName: '대성미생물',
    market: 'KOSDAQ',
    marketCap: 77710000000,
  },
  {
    id: 216,
    code: '025440',
    companyName: '대성엘텍',
    market: 'KOSDAQ',
    marketCap: 130719039185,
  },
  {
    id: 217,
    code: '027830',
    companyName: '대성창투',
    market: 'KOSDAQ',
    marketCap: 188000000000,
  },
  {
    id: 218,
    code: 104040,
    companyName: '대성파인텍',
    market: 'KOSDAQ',
    marketCap: 93434323785,
  },
  {
    id: 219,
    code: 332290,
    companyName: '대신밸런스제7호스팩',
    market: 'KOSDAQ',
    marketCap: 11190750000,
  },
  {
    id: 220,
    code: 336570,
    companyName: '대신밸런스제8호스팩',
    market: 'KOSDAQ',
    marketCap: 12033450000,
  },
  {
    id: 221,
    code: 369370,
    companyName: '대신밸런스제9호스팩',
    market: 'KOSDAQ',
    marketCap: 9177800000,
  },
  {
    id: 222,
    code: '020180',
    companyName: '대신정보통신',
    market: 'KOSDAQ',
    marketCap: 92805829725,
  },
  {
    id: 223,
    code: '045390',
    companyName: '대아티아이',
    market: 'KOSDAQ',
    marketCap: 461061862560,
  },
  {
    id: 224,
    code: 108380,
    companyName: '대양전기공업',
    market: 'KOSDAQ',
    marketCap: 179865860400,
  },
  {
    id: 225,
    code: '006580',
    companyName: '대양제지',
    market: 'KOSDAQ',
    marketCap: 87531000000,
  },
  {
    id: 226,
    code: '007680',
    companyName: '대원',
    market: 'KOSDAQ',
    marketCap: 144788282250,
  },
  {
    id: 227,
    code: '048910',
    companyName: '대원미디어',
    market: 'KOSDAQ',
    marketCap: 435231531600,
  },
  {
    id: 228,
    code: '005710',
    companyName: '대원산업',
    market: 'KOSDAQ',
    marketCap: 168916968000,
  },
  {
    id: 229,
    code: 290380,
    companyName: '대유',
    market: 'KOSDAQ',
    marketCap: 92744008000,
  },
  {
    id: 230,
    code: 290120,
    companyName: '대유에이피',
    market: 'KOSDAQ',
    marketCap: 95024068290,
  },
  {
    id: 231,
    code: 120240,
    companyName: '대정화금',
    market: 'KOSDAQ',
    marketCap: 188747763750,
  },
  {
    id: 232,
    code: '003310',
    companyName: '대주산업',
    market: 'KOSDAQ',
    marketCap: 105823126500,
  },
  {
    id: 233,
    code: '078600',
    companyName: '대주전자재료',
    market: 'KOSDAQ',
    marketCap: 729374325600,
  },
  {
    id: 234,
    code: '096350',
    companyName: '대창솔루션',
    market: 'KOSDAQ',
    marketCap: 108246026949,
  },
  {
    id: 235,
    code: 140520,
    companyName: '대창스틸',
    market: 'KOSDAQ',
    marketCap: 79581846110,
  },
  {
    id: 236,
    code: 131220,
    companyName: '대한과학',
    market: 'KOSDAQ',
    marketCap: 100262890500,
  },
  {
    id: 237,
    code: '010170',
    companyName: '대한광통신',
    market: 'KOSDAQ',
    marketCap: 241503821875,
  },
  {
    id: 238,
    code: '060900',
    companyName: '대한그린파워',
    market: 'KOSDAQ',
    marketCap: 349778940745,
  },
  {
    id: 239,
    code: '054670',
    companyName: '대한뉴팜',
    market: 'KOSDAQ',
    marketCap: 165799326000,
  },
  {
    id: 240,
    code: '023910',
    companyName: '대한약품',
    market: 'KOSDAQ',
    marketCap: 212100000000,
  },
  {
    id: 241,
    code: '021040',
    companyName: '대호특수강',
    market: 'KOSDAQ',
    marketCap: 69310959048,
  },
  {
    id: 242,
    code: '021045',
    companyName: '대호특수강우',
    market: 'KOSDAQ',
    marketCap: 7466740160,
  },
  {
    id: 243,
    code: '067080',
    companyName: '대화제약',
    market: 'KOSDAQ',
    marketCap: 234569790000,
  },
  {
    id: 244,
    code: 298540,
    companyName: '더네이쳐홀딩스',
    market: 'KOSDAQ',
    marketCap: 475610251200,
  },
  {
    id: 245,
    code: 299170,
    companyName: '더블유에스아이',
    market: 'KOSDAQ',
    marketCap: 89175787150,
  },
  {
    id: 246,
    code: 213420,
    companyName: '덕산네오룩스',
    market: 'KOSDAQ',
    marketCap: 1243718621600,
  },
  {
    id: 247,
    code: 317330,
    companyName: '덕산테코피아',
    market: 'KOSDAQ',
    marketCap: 419005358400,
  },
  {
    id: 248,
    code: '077360',
    companyName: '덕산하이메탈',
    market: 'KOSDAQ',
    marketCap: 310107538650,
  },
  {
    id: 249,
    code: '090410',
    companyName: '덕신하우징',
    market: 'KOSDAQ',
    marketCap: 84103473375,
  },
  {
    id: 250,
    code: 263600,
    companyName: '덕우전자',
    market: 'KOSDAQ',
    marketCap: 135566938100,
  },
  {
    id: 251,
    code: 194480,
    companyName: '데브시스터즈',
    market: 'KOSDAQ',
    marketCap: 1048255744000,
  },
  {
    id: 252,
    code: 263800,
    companyName: '데이타솔루션',
    market: 'KOSDAQ',
    marketCap: 120527401600,
  },
  {
    id: 253,
    code: 206560,
    companyName: '덱스터',
    market: 'KOSDAQ',
    marketCap: 198475904880,
  },
  {
    id: 254,
    code: 261200,
    companyName: '덴티스',
    market: 'KOSDAQ',
    marketCap: 215465194000,
  },
  {
    id: 255,
    code: '067990',
    companyName: '도이치모터스',
    market: 'KOSDAQ',
    marketCap: 263650983400,
  },
  {
    id: 256,
    code: '006620',
    companyName: '동구바이오제약',
    market: 'KOSDAQ',
    marketCap: 262162576320,
  },
  {
    id: 257,
    code: 100130,
    companyName: '동국S&C',
    market: 'KOSDAQ',
    marketCap: 352572310000,
  },
  {
    id: 258,
    code: '005160',
    companyName: '동국산업',
    market: 'KOSDAQ',
    marketCap: 222402376200,
  },
  {
    id: 259,
    code: '075970',
    companyName: '동국알앤에스',
    market: 'KOSDAQ',
    marketCap: 116656000000,
  },
  {
    id: 260,
    code: '086450',
    companyName: '동국제약',
    market: 'KOSDAQ',
    marketCap: 1258218000000,
  },
  {
    id: 261,
    code: '099410',
    companyName: '동방선기',
    market: 'KOSDAQ',
    marketCap: 52877612810,
  },
  {
    id: 262,
    code: '033500',
    companyName: '동성화인텍',
    market: 'KOSDAQ',
    marketCap: 375639858000,
  },
  {
    id: 263,
    code: '025950',
    companyName: '동신건설',
    market: 'KOSDAQ',
    marketCap: 452760000000,
  },
  {
    id: 264,
    code: '088130',
    companyName: '동아엘텍',
    market: 'KOSDAQ',
    marketCap: 105349827440,
  },
  {
    id: 265,
    code: '041930',
    companyName: '동아화성',
    market: 'KOSDAQ',
    marketCap: 212510000000,
  },
  {
    id: 266,
    code: '060380',
    companyName: '동양에스텍',
    market: 'KOSDAQ',
    marketCap: 85596500000,
  },
  {
    id: 267,
    code: '079960',
    companyName: '동양이엔피',
    market: 'KOSDAQ',
    marketCap: 137943000000,
  },
  {
    id: 268,
    code: 228340,
    companyName: '동양파일',
    market: 'KOSDAQ',
    marketCap: 133600000000,
  },
  {
    id: 269,
    code: '088910',
    companyName: '동우팜투테이블',
    market: 'KOSDAQ',
    marketCap: 95448367980,
  },
  {
    id: 270,
    code: '094170',
    companyName: '동운아나텍',
    market: 'KOSDAQ',
    marketCap: 134467920900,
  },
  {
    id: 271,
    code: '013120',
    companyName: '동원개발',
    market: 'KOSDAQ',
    marketCap: 554837491000,
  },
  {
    id: 272,
    code: 109860,
    companyName: '동일금속',
    market: 'KOSDAQ',
    marketCap: 98000000000,
  },
  {
    id: 273,
    code: '032960',
    companyName: '동일기연',
    market: 'KOSDAQ',
    marketCap: 63934951250,
  },
  {
    id: 274,
    code: '023790',
    companyName: '동일철강',
    market: 'KOSDAQ',
    marketCap: 53619120500,
  },
  {
    id: 275,
    code: '005290',
    companyName: '동진쎄미켐',
    market: 'KOSDAQ',
    marketCap: 1485878876600,
  },
  {
    id: 276,
    code: '025900',
    companyName: '동화기업',
    market: 'KOSDAQ',
    marketCap: 1383780624500,
  },
  {
    id: 277,
    code: '073190',
    companyName: '듀오백',
    market: 'KOSDAQ',
    marketCap: 49966567000,
  },
  {
    id: 278,
    code: '030350',
    companyName: '드래곤플라이',
    market: 'KOSDAQ',
    marketCap: 39518077830,
  },
  {
    id: 279,
    code: 203650,
    companyName: '드림시큐리티',
    market: 'KOSDAQ',
    marketCap: 285416452560,
  },
  {
    id: 280,
    code: 223250,
    companyName: '드림씨아이에스',
    market: 'KOSDAQ',
    marketCap: 92053706500,
  },
  {
    id: 281,
    code: '060570',
    companyName: '드림어스컴퍼니',
    market: 'KOSDAQ',
    marketCap: 351962452930,
  },
  {
    id: 282,
    code: 217620,
    companyName: '디딤',
    market: 'KOSDAQ',
    marketCap: 85213799400,
  },
  {
    id: 283,
    code: 187870,
    companyName: '디바이스이엔지',
    market: 'KOSDAQ',
    marketCap: 265983820200,
  },
  {
    id: 284,
    code: '066670',
    companyName: '디스플레이텍',
    market: 'KOSDAQ',
    marketCap: 94020347540,
  },
  {
    id: 285,
    code: 110990,
    companyName: '디아이티',
    market: 'KOSDAQ',
    marketCap: 203175000000,
  },
  {
    id: 286,
    code: 263690,
    companyName: '디알젬',
    market: 'KOSDAQ',
    marketCap: 155727137500,
  },
  {
    id: 287,
    code: 214680,
    companyName: '디알텍',
    market: 'KOSDAQ',
    marketCap: 96297680925,
  },
  {
    id: 288,
    code: 263720,
    companyName: '디앤씨미디어',
    market: 'KOSDAQ',
    marketCap: 527829773000,
  },
  {
    id: 289,
    code: 109740,
    companyName: '디에스케이',
    market: 'KOSDAQ',
    marketCap: 162023913480,
  },
  {
    id: 290,
    code: '033430',
    companyName: '디에스티',
    market: 'KOSDAQ',
    marketCap: 27363736280,
  },
  {
    id: 291,
    code: 131030,
    companyName: '디에이치피코리아',
    market: 'KOSDAQ',
    marketCap: 139806886680,
  },
  {
    id: 292,
    code: 196490,
    companyName: '디에이테크놀로지',
    market: 'KOSDAQ',
    marketCap: 141785097000,
  },
  {
    id: 293,
    code: '066900',
    companyName: '디에이피',
    market: 'KOSDAQ',
    marketCap: 92115237150,
  },
  {
    id: 294,
    code: 127120,
    companyName: '디엔에이링크',
    market: 'KOSDAQ',
    marketCap: 65574768000,
  },
  {
    id: 295,
    code: '092070',
    companyName: '디엔에프',
    market: 'KOSDAQ',
    marketCap: 251826775200,
  },
  {
    id: 296,
    code: 134580,
    companyName: '디엠티',
    market: 'KOSDAQ',
    marketCap: 38025000000,
  },
  {
    id: 297,
    code: '039840',
    companyName: '디오',
    market: 'KOSDAQ',
    marketCap: 636467682000,
  },
  {
    id: 298,
    code: 104460,
    companyName: '디와이피엔에프',
    market: 'KOSDAQ',
    marketCap: 308552400000,
  },
  {
    id: 299,
    code: '079810',
    companyName: '디이엔티',
    market: 'KOSDAQ',
    marketCap: 90345432870,
  },
  {
    id: 300,
    code: 227100,
    companyName: '디자인',
    market: 'KOSDAQ',
    marketCap: 53319877050,
  },
  {
    id: 301,
    code: 113810,
    companyName: '디젠스',
    market: 'KOSDAQ',
    marketCap: 32301770490,
  },
  {
    id: 302,
    code: '043360',
    companyName: '디지아이',
    market: 'KOSDAQ',
    marketCap: 30915000000,
  },
  {
    id: 303,
    code: 197140,
    companyName: '디지캡',
    market: 'KOSDAQ',
    marketCap: 58076120520,
  },
  {
    id: 304,
    code: 106520,
    companyName: '디지탈옵틱',
    market: 'KOSDAQ',
    marketCap: 89857759920,
  },
  {
    id: 305,
    code: '068930',
    companyName: '디지털대성',
    market: 'KOSDAQ',
    marketCap: 249690704990,
  },
  {
    id: 306,
    code: '033130',
    companyName: '디지틀조선',
    market: 'KOSDAQ',
    marketCap: 226403128700,
  },
  {
    id: 307,
    code: 105740,
    companyName: '디케이락',
    market: 'KOSDAQ',
    marketCap: 106891386800,
  },
  {
    id: 308,
    code: 263020,
    companyName: '디케이앤디',
    market: 'KOSDAQ',
    marketCap: 88500502800,
  },
  {
    id: 309,
    code: 290550,
    companyName: '디케이티',
    market: 'KOSDAQ',
    marketCap: 162568826750,
  },
  {
    id: 310,
    code: 187220,
    companyName: '디티앤씨',
    market: 'KOSDAQ',
    marketCap: 82627362300,
  },
  {
    id: 311,
    code: 131180,
    companyName: '딜리',
    market: 'KOSDAQ',
    marketCap: 58993500000,
  },
  {
    id: 312,
    code: 317120,
    companyName: '라닉스',
    market: 'KOSDAQ',
    marketCap: 108568125000,
  },
  {
    id: 313,
    code: '042510',
    companyName: '라온시큐어',
    market: 'KOSDAQ',
    marketCap: 230198006230,
  },
  {
    id: 314,
    code: 232680,
    companyName: '라온테크',
    market: 'KOSDAQ',
    marketCap: 100163102500,
  },
  {
    id: 315,
    code: 300120,
    companyName: '라온피플',
    market: 'KOSDAQ',
    marketCap: 245073034800,
  },
  {
    id: 316,
    code: 171120,
    companyName: '라이온켐텍',
    market: 'KOSDAQ',
    marketCap: 204050620800,
  },
  {
    id: 317,
    code: '069540',
    companyName: '라이트론',
    market: 'KOSDAQ',
    marketCap: 141255801520,
  },
  {
    id: 318,
    code: 347700,
    companyName: '라이프시맨틱스',
    market: 'KOSDAQ',
    marketCap: 122449217000,
  },
  {
    id: 319,
    code: 214260,
    companyName: '라파스',
    market: 'KOSDAQ',
    marketCap: 264929496100,
  },
  {
    id: 320,
    code: 171010,
    companyName: '램테크놀러지',
    market: 'KOSDAQ',
    marketCap: 79275591440,
  },
  {
    id: 321,
    code: '084650',
    companyName: '랩지노믹스',
    market: 'KOSDAQ',
    marketCap: 259344697050,
  },
  {
    id: 322,
    code: 217500,
    companyName: '러셀',
    market: 'KOSDAQ',
    marketCap: 166694880000,
  },
  {
    id: 323,
    code: '033600',
    companyName: '럭슬',
    market: 'KOSDAQ',
    marketCap: 9456230437,
  },
  {
    id: 324,
    code: 141080,
    companyName: '레고켐바이오',
    market: 'KOSDAQ',
    marketCap: 1200968732400,
  },
  {
    id: 325,
    code: '060300',
    companyName: '레드로버',
    market: 'KOSDAQ',
    marketCap: 48840480210,
  },
  {
    id: 326,
    code: '038390',
    companyName: '레드캡투어',
    market: 'KOSDAQ',
    marketCap: 240075966000,
  },
  {
    id: 327,
    code: 294140,
    companyName: '레몬',
    market: 'KOSDAQ',
    marketCap: 314130000000,
  },
  {
    id: 328,
    code: 228670,
    companyName: '레이',
    market: 'KOSDAQ',
    marketCap: 229205048150,
  },
  {
    id: 329,
    code: 228850,
    companyName: '레이언스',
    market: 'KOSDAQ',
    marketCap: 249694760700,
  },
  {
    id: 330,
    code: 281740,
    companyName: '레이크머티리얼즈',
    market: 'KOSDAQ',
    marketCap: 263250844740,
  },
  {
    id: 331,
    code: 277810,
    companyName: '레인보우로보틱스',
    market: 'KOSDAQ',
    marketCap: 345293579600,
  },
  {
    id: 332,
    code: 215100,
    companyName: '로보로보',
    market: 'KOSDAQ',
    marketCap: 80182035270,
  },
  {
    id: 333,
    code: '090360',
    companyName: '로보스타',
    market: 'KOSDAQ',
    marketCap: 270562500000,
  },
  {
    id: 334,
    code: 108490,
    companyName: '로보티즈',
    market: 'KOSDAQ',
    marketCap: 178890900000,
  },
  {
    id: 335,
    code: 900260,
    companyName: '로스웰',
    market: 'KOSDAQ',
    marketCap: 50190562500,
  },
  {
    id: 336,
    code: '067730',
    companyName: '로지시스',
    market: 'KOSDAQ',
    marketCap: 89290300060,
  },
  {
    id: 337,
    code: '071280',
    companyName: '로체시스템즈',
    market: 'KOSDAQ',
    marketCap: 91626651970,
  },
  {
    id: 338,
    code: '038060',
    companyName: '루멘스',
    market: 'KOSDAQ',
    marketCap: 72876149535,
  },
  {
    id: 339,
    code: '085370',
    companyName: '루트로닉',
    market: 'KOSDAQ',
    marketCap: 400179980200,
  },
  {
    id: 340,
    code: '08537M',
    companyName: '루트로닉3우C',
    market: 'KOSDAQ',
    marketCap: 10118968600,
  },
  {
    id: 341,
    code: '060240',
    companyName: '룽투코리아',
    market: 'KOSDAQ',
    marketCap: 166053224520,
  },
  {
    id: 342,
    code: '058470',
    companyName: '리노공업',
    market: 'KOSDAQ',
    marketCap: 2545475790000,
  },
  {
    id: 343,
    code: '039980',
    companyName: '리노스',
    market: 'KOSDAQ',
    marketCap: 76912144790,
  },
  {
    id: 344,
    code: '019570',
    companyName: '리더스 기술투자',
    market: 'KOSDAQ',
    marketCap: 115992687840,
  },
  {
    id: 345,
    code: '016100',
    companyName: '리더스코스메틱',
    market: 'KOSDAQ',
    marketCap: 125725701780,
  },
  {
    id: 346,
    code: '012700',
    companyName: '리드코프',
    market: 'KOSDAQ',
    marketCap: 293552098500,
  },
  {
    id: 347,
    code: 302550,
    companyName: '리메드',
    market: 'KOSDAQ',
    marketCap: 158964029300,
  },
  {
    id: 348,
    code: '029480',
    companyName: '릭스솔루션',
    market: 'KOSDAQ',
    marketCap: 50059778527,
  },
  {
    id: 349,
    code: 277070,
    companyName: '린드먼아시아',
    market: 'KOSDAQ',
    marketCap: 93690000000,
  },
  {
    id: 350,
    code: '042500',
    companyName: '링네트',
    market: 'KOSDAQ',
    marketCap: 107037439660,
  },
  {
    id: 351,
    code: 219420,
    companyName: '링크제니시스',
    market: 'KOSDAQ',
    marketCap: 81500000000,
  },
  {
    id: 352,
    code: 195500,
    companyName: '마니커에프앤지',
    market: 'KOSDAQ',
    marketCap: 101072880000,
  },
  {
    id: 353,
    code: 222810,
    companyName: '마이더스AI',
    market: 'KOSDAQ',
    marketCap: 70754874294,
  },
  {
    id: 354,
    code: 305090,
    companyName: '마이크로디지탈',
    market: 'KOSDAQ',
    marketCap: 181176920000,
  },
  {
    id: 355,
    code: '098120',
    companyName: '마이크로컨텍솔',
    market: 'KOSDAQ',
    marketCap: 62096362020,
  },
  {
    id: 356,
    code: 147760,
    companyName: '마이크로프랜드',
    market: 'KOSDAQ',
    marketCap: 93700039560,
  },
  {
    id: 357,
    code: '038290',
    companyName: '마크로젠',
    market: 'KOSDAQ',
    marketCap: 330881662000,
  },
  {
    id: 358,
    code: 220630,
    companyName: '맘스터치',
    market: 'KOSDAQ',
    marketCap: 381815741250,
  },
  {
    id: 359,
    code: 267980,
    companyName: '매일유업',
    market: 'KOSDAQ',
    marketCap: 607881945000,
  },
  {
    id: 360,
    code: '005990',
    companyName: '매일홀딩스',
    market: 'KOSDAQ',
    marketCap: 157760496000,
  },
  {
    id: 361,
    code: 127160,
    companyName: '매직마이크로',
    market: 'KOSDAQ',
    marketCap: 24427827282,
  },
  {
    id: 362,
    code: '093520',
    companyName: '매커스',
    market: 'KOSDAQ',
    marketCap: 111040442040,
  },
  {
    id: 363,
    code: 141070,
    companyName: '맥스로텍',
    market: 'KOSDAQ',
    marketCap: 26670245050,
  },
  {
    id: 364,
    code: 100590,
    companyName: '머큐리',
    market: 'KOSDAQ',
    marketCap: 152803260000,
  },
  {
    id: 365,
    code: '067280',
    companyName: '멀티캠퍼스',
    market: 'KOSDAQ',
    marketCap: 236774821050,
  },
  {
    id: 366,
    code: '072870',
    companyName: '메가스터디',
    market: 'KOSDAQ',
    marketCap: 172257857550,
  },
  {
    id: 367,
    code: 215200,
    companyName: '메가스터디교육',
    market: 'KOSDAQ',
    marketCap: 775766952000,
  },
  {
    id: 368,
    code: 133750,
    companyName: '메가엠디',
    market: 'KOSDAQ',
    marketCap: 142549098930,
  },
  {
    id: 369,
    code: 235980,
    companyName: '메드팩토',
    market: 'KOSDAQ',
    marketCap: 1372141879800,
  },
  {
    id: 370,
    code: '041920',
    companyName: '메디아나',
    market: 'KOSDAQ',
    marketCap: 132785113600,
  },
  {
    id: 371,
    code: '014100',
    companyName: '메디앙스',
    market: 'KOSDAQ',
    marketCap: 73160000000,
  },
  {
    id: 372,
    code: '054180',
    companyName: '메디콕스',
    market: 'KOSDAQ',
    marketCap: 84185756040,
  },
  {
    id: 373,
    code: '086900',
    companyName: '메디톡스',
    market: 'KOSDAQ',
    marketCap: 1214601372800,
  },
  {
    id: 374,
    code: '078160',
    companyName: '메디포스트',
    market: 'KOSDAQ',
    marketCap: 508256062500,
  },
  {
    id: 375,
    code: '065650',
    companyName: '메디프론',
    market: 'KOSDAQ',
    marketCap: 151972782720,
  },
  {
    id: 376,
    code: '021880',
    companyName: '메이슨캐피탈',
    market: 'KOSDAQ',
    marketCap: 110062249024,
  },
  {
    id: 377,
    code: 140410,
    companyName: '메지온',
    market: 'KOSDAQ',
    marketCap: 1213854369000,
  },
  {
    id: 378,
    code: 241770,
    companyName: '메카로',
    market: 'KOSDAQ',
    marketCap: 118208356000,
  },
  {
    id: 379,
    code: '059210',
    companyName: '메타바이오메드',
    market: 'KOSDAQ',
    marketCap: 76120899690,
  },
  {
    id: 380,
    code: '058110',
    companyName: '멕아이씨에스',
    market: 'KOSDAQ',
    marketCap: 247178162000,
  },
  {
    id: 381,
    code: '096640',
    companyName: '멜파스',
    market: 'KOSDAQ',
    marketCap: 72512051670,
  },
  {
    id: 382,
    code: 257370,
    companyName: '명성티엔에스',
    market: 'KOSDAQ',
    marketCap: 59319850890,
  },
  {
    id: 383,
    code: '080420',
    companyName: '모다이노칩',
    market: 'KOSDAQ',
    marketCap: 326460042090,
  },
  {
    id: 384,
    code: '080160',
    companyName: '모두투어',
    market: 'KOSDAQ',
    marketCap: 542430000000,
  },
  {
    id: 385,
    code: 100030,
    companyName: '모바일리더',
    market: 'KOSDAQ',
    marketCap: 70271479600,
  },
  {
    id: 386,
    code: '087260',
    companyName: '모바일어플라이언스',
    market: 'KOSDAQ',
    marketCap: 138776956650,
  },
  {
    id: 387,
    code: 101330,
    companyName: '모베이스',
    market: 'KOSDAQ',
    marketCap: 132967943400,
  },
  {
    id: 388,
    code: '012860',
    companyName: '모베이스전자',
    market: 'KOSDAQ',
    marketCap: 152635540800,
  },
  {
    id: 389,
    code: 348030,
    companyName: '모비릭스',
    market: 'KOSDAQ',
    marketCap: 197244800000,
  },
  {
    id: 390,
    code: 250060,
    companyName: '모비스',
    market: 'KOSDAQ',
    marketCap: 96513942000,
  },
  {
    id: 391,
    code: '033200',
    companyName: '모아텍',
    market: 'KOSDAQ',
    marketCap: 124967933200,
  },
  {
    id: 392,
    code: 118990,
    companyName: '모트렉스',
    market: 'KOSDAQ',
    marketCap: 230886150880,
  },
  {
    id: 393,
    code: '006920',
    companyName: '모헨즈',
    market: 'KOSDAQ',
    marketCap: 71962800000,
  },
  {
    id: 394,
    code: '001810',
    companyName: '무림SP',
    market: 'KOSDAQ',
    marketCap: 87221750000,
  },
  {
    id: 395,
    code: 279600,
    companyName: '미디어젠',
    market: 'KOSDAQ',
    marketCap: 65118455900,
  },
  {
    id: 396,
    code: '095500',
    companyName: '미래나노텍',
    market: 'KOSDAQ',
    marketCap: 133852777205,
  },
  {
    id: 397,
    code: 218150,
    companyName: '미래생명자원',
    market: 'KOSDAQ',
    marketCap: 95224282800,
  },
  {
    id: 398,
    code: 353490,
    companyName: '미래에셋대우스팩 5호',
    market: 'KOSDAQ',
    marketCap: 10803100000,
  },
  {
    id: 399,
    code: 328380,
    companyName: '미래에셋대우스팩3호',
    market: 'KOSDAQ',
    marketCap: 13741840000,
  },
  {
    id: 400,
    code: 100790,
    companyName: '미래에셋벤처투자',
    market: 'KOSDAQ',
    marketCap: 376033999220,
  },
  {
    id: 401,
    code: '049950',
    companyName: '미래컴퍼니',
    market: 'KOSDAQ',
    marketCap: 269684368500,
  },
  {
    id: 402,
    code: 213090,
    companyName: '미래테크놀로지',
    market: 'KOSDAQ',
    marketCap: 80488362200,
  },
  {
    id: 403,
    code: 207760,
    companyName: '미스터블루',
    market: 'KOSDAQ',
    marketCap: 273616066750,
  },
  {
    id: 404,
    code: '059090',
    companyName: '미코',
    market: 'KOSDAQ',
    marketCap: 450959083200,
  },
  {
    id: 405,
    code: 214610,
    companyName: '미코바이오메드',
    market: 'KOSDAQ',
    marketCap: 270282528400,
  },
  {
    id: 406,
    code: 201490,
    companyName: '미투온',
    market: 'KOSDAQ',
    marketCap: 203488082700,
  },
  {
    id: 407,
    code: 950190,
    companyName: '미투젠',
    market: 'KOSDAQ',
    marketCap: 311658521400,
  },
  {
    id: 408,
    code: 214180,
    companyName: '민앤지',
    market: 'KOSDAQ',
    marketCap: 242922653400,
  },
  {
    id: 409,
    code: 206640,
    companyName: '바디텍메드',
    market: 'KOSDAQ',
    marketCap: 522575960000,
  },
  {
    id: 410,
    code: '018700',
    companyName: '바른손',
    market: 'KOSDAQ',
    marketCap: 87530316390,
  },
  {
    id: 411,
    code: '035620',
    companyName: '바른손이앤에이',
    market: 'KOSDAQ',
    marketCap: 113539737135,
  },
  {
    id: 412,
    code: '064520',
    companyName: '바른전자',
    market: 'KOSDAQ',
    marketCap: 74054909385,
  },
  {
    id: 413,
    code: '053030',
    companyName: '바이넥스',
    market: 'KOSDAQ',
    marketCap: 655865641200,
  },
  {
    id: 414,
    code: 301300,
    companyName: '바이브컴퍼니',
    market: 'KOSDAQ',
    marketCap: 163982768000,
  },
  {
    id: 415,
    code: '064550',
    companyName: '바이오니아',
    market: 'KOSDAQ',
    marketCap: 442323868800,
  },
  {
    id: 416,
    code: 314930,
    companyName: '바이오다인',
    market: 'KOSDAQ',
    marketCap: 276026726250,
  },
  {
    id: 417,
    code: 208710,
    companyName: '바이오로그디바이스',
    market: 'KOSDAQ',
    marketCap: 176083501000,
  },
  {
    id: 418,
    code: 142760,
    companyName: '바이오리더스',
    market: 'KOSDAQ',
    marketCap: 175712304560,
  },
  {
    id: 419,
    code: '086820',
    companyName: '바이오솔루션',
    market: 'KOSDAQ',
    marketCap: 234651354000,
  },
  {
    id: 420,
    code: '038460',
    companyName: '바이오스마트',
    market: 'KOSDAQ',
    marketCap: 137400552000,
  },
  {
    id: 421,
    code: '086040',
    companyName: '바이오톡스텍',
    market: 'KOSDAQ',
    marketCap: 157951328400,
  },
  {
    id: 422,
    code: '032980',
    companyName: '바이온',
    market: 'KOSDAQ',
    marketCap: 99013149960,
  },
  {
    id: 423,
    code: '043150',
    companyName: '바텍',
    market: 'KOSDAQ',
    marketCap: 572631568800,
  },
  {
    id: 424,
    code: 323990,
    companyName: '박셀바이오',
    market: 'KOSDAQ',
    marketCap: 1409649800000,
  },
  {
    id: 425,
    code: 267790,
    companyName: '배럴',
    market: 'KOSDAQ',
    marketCap: 90288975000,
  },
  {
    id: 426,
    code: '046310',
    companyName: '백금T&A',
    market: 'KOSDAQ',
    marketCap: 68055266945,
  },
  {
    id: 427,
    code: '066410',
    companyName: '버킷스튜디오',
    market: 'KOSDAQ',
    marketCap: 86336487730,
  },
  {
    id: 428,
    code: 206400,
    companyName: '베노홀딩스',
    market: 'KOSDAQ',
    marketCap: 153945686880,
  },
  {
    id: 429,
    code: '019010',
    companyName: '베뉴지',
    market: 'KOSDAQ',
    marketCap: 126525000000,
  },
  {
    id: 430,
    code: 177350,
    companyName: '베셀',
    market: 'KOSDAQ',
    marketCap: 58759932000,
  },
  {
    id: 431,
    code: 299910,
    companyName: '베스파',
    market: 'KOSDAQ',
    marketCap: 217930003000,
  },
  {
    id: 432,
    code: 225530,
    companyName: '보광산업',
    market: 'KOSDAQ',
    marketCap: 276901099740,
  },
  {
    id: 433,
    code: 250000,
    companyName: '보라티알',
    market: 'KOSDAQ',
    marketCap: 114762461000,
  },
  {
    id: 434,
    code: '006910',
    companyName: '보성파워텍',
    market: 'KOSDAQ',
    marketCap: 227962383360,
  },
  {
    id: 435,
    code: 226340,
    companyName: '본느',
    market: 'KOSDAQ',
    marketCap: 116811962780,
  },
  {
    id: 436,
    code: '014470',
    companyName: '부방',
    market: 'KOSDAQ',
    marketCap: 229699894500,
  },
  {
    id: 437,
    code: '008470',
    companyName: '부스타',
    market: 'KOSDAQ',
    marketCap: 58665504000,
  },
  {
    id: 438,
    code: 338220,
    companyName: '뷰노',
    market: 'KOSDAQ',
    marketCap: 241801402400,
  },
  {
    id: 439,
    code: 100120,
    companyName: '뷰웍스',
    market: 'KOSDAQ',
    marketCap: 382071243000,
  },
  {
    id: 440,
    code: 337930,
    companyName: '브랜드엑스코퍼레이션',
    market: 'KOSDAQ',
    marketCap: 237333951600,
  },
  {
    id: 441,
    code: '064480',
    companyName: '브리지텍',
    market: 'KOSDAQ',
    marketCap: 61674900000,
  },
  {
    id: 442,
    code: 288330,
    companyName: '브릿지바이오테라퓨틱스',
    market: 'KOSDAQ',
    marketCap: 291796146600,
  },
  {
    id: 443,
    code: 251630,
    companyName: '브이원텍',
    market: 'KOSDAQ',
    marketCap: 162546264000,
  },
  {
    id: 444,
    code: '018290',
    companyName: '브이티지엠피',
    market: 'KOSDAQ',
    marketCap: 322124283030,
  },
  {
    id: 445,
    code: '044480',
    companyName: '블루베리 NFT',
    market: 'KOSDAQ',
    marketCap: 88645544700,
  },
  {
    id: 446,
    code: '033560',
    companyName: '블루콤',
    market: 'KOSDAQ',
    marketCap: 177262860600,
  },
  {
    id: 447,
    code: 126340,
    companyName: '비나텍',
    market: 'KOSDAQ',
    marketCap: 223729504500,
  },
  {
    id: 448,
    code: 121800,
    companyName: '비덴트',
    market: 'KOSDAQ',
    marketCap: 408323816700,
  },
  {
    id: 449,
    code: 148140,
    companyName: '비디아이',
    market: 'KOSDAQ',
    marketCap: 93236692875,
  },
  {
    id: 450,
    code: '082800',
    companyName: '비보존 헬스케어',
    market: 'KOSDAQ',
    marketCap: 266046548805,
  },
  {
    id: 451,
    code: 318410,
    companyName: '비비씨',
    market: 'KOSDAQ',
    marketCap: 103174617000,
  },
  {
    id: 452,
    code: 200780,
    companyName: '비씨월드제약',
    market: 'KOSDAQ',
    marketCap: 152949004800,
  },
  {
    id: 453,
    code: 141000,
    companyName: '비아트론',
    market: 'KOSDAQ',
    marketCap: 135079016500,
  },
  {
    id: 454,
    code: '090460',
    companyName: '비에이치',
    market: 'KOSDAQ',
    marketCap: 609837652800,
  },
  {
    id: 455,
    code: '083650',
    companyName: '비에이치아이',
    market: 'KOSDAQ',
    marketCap: 153920000000,
  },
  {
    id: 456,
    code: '086670',
    companyName: '비엠티',
    market: 'KOSDAQ',
    marketCap: 123012587900,
  },
  {
    id: 457,
    code: 335890,
    companyName: '비올',
    market: 'KOSDAQ',
    marketCap: 128935861435,
  },
  {
    id: 458,
    code: 138580,
    companyName: '비즈니스온',
    market: 'KOSDAQ',
    marketCap: 242318172000,
  },
  {
    id: 459,
    code: '082920',
    companyName: '비츠로셀',
    market: 'KOSDAQ',
    marketCap: 326318152800,
  },
  {
    id: 460,
    code: '054220',
    companyName: '비츠로시스',
    market: 'KOSDAQ',
    marketCap: 129588605720,
  },
  {
    id: 461,
    code: '042370',
    companyName: '비츠로테크',
    market: 'KOSDAQ',
    marketCap: 301034167600,
  },
  {
    id: 462,
    code: '032850',
    companyName: '비트컴퓨터',
    market: 'KOSDAQ',
    marketCap: 189505540200,
  },
  {
    id: 463,
    code: 238200,
    companyName: '비피도',
    market: 'KOSDAQ',
    marketCap: 91616000000,
  },
  {
    id: 464,
    code: '093190',
    companyName: '빅솔론',
    market: 'KOSDAQ',
    marketCap: 116395375250,
  },
  {
    id: 465,
    code: '065450',
    companyName: '빅텍',
    market: 'KOSDAQ',
    marketCap: 203148352000,
  },
  {
    id: 466,
    code: '072950',
    companyName: '빛샘전자',
    market: 'KOSDAQ',
    marketCap: 53523078810,
  },
  {
    id: 467,
    code: 143240,
    companyName: '사람인에이치알',
    market: 'KOSDAQ',
    marketCap: 451213478100,
  },
  {
    id: 468,
    code: 100090,
    companyName: '삼강엠앤티',
    market: 'KOSDAQ',
    marketCap: 662605890900,
  },
  {
    id: 469,
    code: 122350,
    companyName: '삼기',
    market: 'KOSDAQ',
    marketCap: 159108626200,
  },
  {
    id: 470,
    code: '014970',
    companyName: '삼륭물산',
    market: 'KOSDAQ',
    marketCap: 121151250000,
  },
  {
    id: 471,
    code: '018310',
    companyName: '삼목에스폼',
    market: 'KOSDAQ',
    marketCap: 239610000000,
  },
  {
    id: 472,
    code: '053700',
    companyName: '삼보모터스',
    market: 'KOSDAQ',
    marketCap: 133721301120,
  },
  {
    id: 473,
    code: '009620',
    companyName: '삼보산업',
    market: 'KOSDAQ',
    marketCap: 90850006710,
  },
  {
    id: 474,
    code: '023600',
    companyName: '삼보판지',
    market: 'KOSDAQ',
    marketCap: 268422000000,
  },
  {
    id: 475,
    code: 380320,
    companyName: '삼성머스트스팩5호',
    market: 'KOSDAQ',
    marketCap: 25012000000,
  },
  {
    id: 476,
    code: 291230,
    companyName: '삼성스팩2호',
    market: 'KOSDAQ',
    marketCap: 58059500000,
  },
  {
    id: 477,
    code: 377630,
    companyName: '삼성스팩4호',
    market: 'KOSDAQ',
    marketCap: 44622000000,
  },
  {
    id: 478,
    code: '009300',
    companyName: '삼아제약',
    market: 'KOSDAQ',
    marketCap: 105742000000,
  },
  {
    id: 479,
    code: 225190,
    companyName: '삼양옵틱스',
    market: 'KOSDAQ',
    marketCap: 109505795000,
  },
  {
    id: 480,
    code: 361670,
    companyName: '삼영에스앤씨',
    market: 'KOSDAQ',
    marketCap: 77522354100,
  },
  {
    id: 481,
    code: '054540',
    companyName: '삼영엠텍',
    market: 'KOSDAQ',
    marketCap: 80098659480,
  },
  {
    id: 482,
    code: '065570',
    companyName: '삼영이엔씨',
    market: 'KOSDAQ',
    marketCap: 86570370040,
  },
  {
    id: 483,
    code: '032280',
    companyName: '삼일',
    market: 'KOSDAQ',
    marketCap: 55207273950,
  },
  {
    id: 484,
    code: '002290',
    companyName: '삼일기업공사',
    market: 'KOSDAQ',
    marketCap: 99696000000,
  },
  {
    id: 485,
    code: '037460',
    companyName: '삼지전자',
    market: 'KOSDAQ',
    marketCap: 240698199500,
  },
  {
    id: 486,
    code: '032750',
    companyName: '삼진',
    market: 'KOSDAQ',
    marketCap: 75000000000,
  },
  {
    id: 487,
    code: '054090',
    companyName: '삼진엘앤디',
    market: 'KOSDAQ',
    marketCap: 95219470080,
  },
  {
    id: 488,
    code: '000250',
    companyName: '삼천당제약',
    market: 'KOSDAQ',
    marketCap: 1218090193500,
  },
  {
    id: 489,
    code: '024950',
    companyName: '삼천리자전거',
    market: 'KOSDAQ',
    marketCap: 195121581900,
  },
  {
    id: 490,
    code: '038500',
    companyName: '삼표시멘트',
    market: 'KOSDAQ',
    marketCap: 644143038000,
  },
  {
    id: 491,
    code: '017480',
    companyName: '삼현철강',
    market: 'KOSDAQ',
    marketCap: 121383339700,
  },
  {
    id: 492,
    code: '046390',
    companyName: '삼화네트웍스',
    market: 'KOSDAQ',
    marketCap: 148299024855,
  },
  {
    id: 493,
    code: '027580',
    companyName: '상보',
    market: 'KOSDAQ',
    marketCap: 99738574860,
  },
  {
    id: 494,
    code: '038540',
    companyName: '상상인',
    market: 'KOSDAQ',
    marketCap: 451479034080,
  },
  {
    id: 495,
    code: 307870,
    companyName: '상상인이안1호스팩',
    market: 'KOSDAQ',
    marketCap: 10450000000,
  },
  {
    id: 496,
    code: 329560,
    companyName: '상상인이안제2호스팩',
    market: 'KOSDAQ',
    marketCap: 8219750000,
  },
  {
    id: 497,
    code: 101000,
    companyName: '상상인인더스트리',
    market: 'KOSDAQ',
    marketCap: 93759529600,
  },
  {
    id: 498,
    code: '091580',
    companyName: '상신이디피',
    market: 'KOSDAQ',
    marketCap: 181331873600,
  },
  {
    id: 499,
    code: 263810,
    companyName: '상신전자',
    market: 'KOSDAQ',
    marketCap: 78915737720,
  },
  {
    id: 500,
    code: '089980',
    companyName: '상아프론테크',
    market: 'KOSDAQ',
    marketCap: 715476807500,
  },
  {
    id: 501,
    code: '042940',
    companyName: '상지카일룸',
    market: 'KOSDAQ',
    marketCap: 87863970900,
  },
  {
    id: 502,
    code: '042600',
    companyName: '새로닉스',
    market: 'KOSDAQ',
    marketCap: 137901671400,
  },
  {
    id: 503,
    code: 252990,
    companyName: '샘씨엔에스',
    market: 'KOSDAQ',
    marketCap: 342550768180,
  },
  {
    id: 504,
    code: 263540,
    companyName: '샘코',
    market: 'KOSDAQ',
    marketCap: 60278610145,
  },
  {
    id: 505,
    code: 294630,
    companyName: '서남',
    market: 'KOSDAQ',
    marketCap: 86238248800,
  },
  {
    id: 506,
    code: '038070',
    companyName: '서린바이오',
    market: 'KOSDAQ',
    marketCap: 157297810200,
  },
  {
    id: 507,
    code: '006730',
    companyName: '서부T&D',
    market: 'KOSDAQ',
    marketCap: 577181987940,
  },
  {
    id: 508,
    code: '079650',
    companyName: '서산',
    market: 'KOSDAQ',
    marketCap: 66800000000,
  },
  {
    id: 509,
    code: 100660,
    companyName: '서암기계공업',
    market: 'KOSDAQ',
    marketCap: 83916000000,
  },
  {
    id: 510,
    code: '019770',
    companyName: '서연탑메탈',
    market: 'KOSDAQ',
    marketCap: 130480000000,
  },
  {
    id: 511,
    code: '043710',
    companyName: '서울리거',
    market: 'KOSDAQ',
    marketCap: 52665159570,
  },
  {
    id: 512,
    code: '092190',
    companyName: '서울바이오시스',
    market: 'KOSDAQ',
    marketCap: 615400813300,
  },
  {
    id: 513,
    code: '046890',
    companyName: '서울반도체',
    market: 'KOSDAQ',
    marketCap: 1107802600000,
  },
  {
    id: 514,
    code: '063170',
    companyName: '서울옥션',
    market: 'KOSDAQ',
    marketCap: 292672750000,
  },
  {
    id: 515,
    code: '027040',
    companyName: '서울전자통신',
    market: 'KOSDAQ',
    marketCap: 70980623940,
  },
  {
    id: 516,
    code: '018680',
    companyName: '서울제약',
    market: 'KOSDAQ',
    marketCap: 69560107680,
  },
  {
    id: 517,
    code: '093920',
    companyName: '서원인텍',
    market: 'KOSDAQ',
    marketCap: 140058000000,
  },
  {
    id: 518,
    code: 189860,
    companyName: '서전기전',
    market: 'KOSDAQ',
    marketCap: 63817972400,
  },
  {
    id: 519,
    code: 178320,
    companyName: '서진시스템',
    market: 'KOSDAQ',
    marketCap: 787378971000,
  },
  {
    id: 520,
    code: 122690,
    companyName: '서진오토모티브',
    market: 'KOSDAQ',
    marketCap: 56376494710,
  },
  {
    id: 521,
    code: 140070,
    companyName: '서플러스글로벌',
    market: 'KOSDAQ',
    marketCap: 210461720000,
  },
  {
    id: 522,
    code: '011370',
    companyName: '서한',
    market: 'KOSDAQ',
    marketCap: 198258409725,
  },
  {
    id: 523,
    code: '065710',
    companyName: '서호전기',
    market: 'KOSDAQ',
    marketCap: 111755000000,
  },
  {
    id: 524,
    code: '035890',
    companyName: '서희건설',
    market: 'KOSDAQ',
    marketCap: 489492013410,
  },
  {
    id: 525,
    code: 357550,
    companyName: '석경에이티',
    market: 'KOSDAQ',
    marketCap: 106645250000,
  },
  {
    id: 526,
    code: '003100',
    companyName: '선광',
    market: 'KOSDAQ',
    marketCap: 268290000000,
  },
  {
    id: 527,
    code: 123420,
    companyName: '선데이토즈',
    market: 'KOSDAQ',
    marketCap: 229197715900,
  },
  {
    id: 528,
    code: 171090,
    companyName: '선익시스템',
    market: 'KOSDAQ',
    marketCap: 206321767150,
  },
  {
    id: 529,
    code: '086710',
    companyName: '선진뷰티사이언스',
    market: 'KOSDAQ',
    marketCap: 119255175000,
  },
  {
    id: 530,
    code: '014620',
    companyName: '성광벤드',
    market: 'KOSDAQ',
    marketCap: 270556000000,
  },
  {
    id: 531,
    code: '037350',
    companyName: '성도이엔지',
    market: 'KOSDAQ',
    marketCap: 96842200000,
  },
  {
    id: 532,
    code: '081580',
    companyName: '성우전자',
    market: 'KOSDAQ',
    marketCap: 51642116190,
  },
  {
    id: 533,
    code: '045300',
    companyName: '성우테크론',
    market: 'KOSDAQ',
    marketCap: 56273629630,
  },
  {
    id: 534,
    code: '015750',
    companyName: '성우하이텍',
    market: 'KOSDAQ',
    marketCap: 565600000000,
  },
  {
    id: 535,
    code: '080470',
    companyName: '성창오토텍',
    market: 'KOSDAQ',
    marketCap: 73000000000,
  },
  {
    id: 536,
    code: '043260',
    companyName: '성호전자',
    market: 'KOSDAQ',
    marketCap: 85448614400,
  },
  {
    id: 537,
    code: 148150,
    companyName: '세경하이테크',
    market: 'KOSDAQ',
    marketCap: 249560000000,
  },
  {
    id: 538,
    code: '053060',
    companyName: '세동',
    market: 'KOSDAQ',
    marketCap: 26014259385,
  },
  {
    id: 539,
    code: '017510',
    companyName: '세명전기',
    market: 'KOSDAQ',
    marketCap: 82023480000,
  },
  {
    id: 540,
    code: '011560',
    companyName: '세보엠이씨',
    market: 'KOSDAQ',
    marketCap: 84450600000,
  },
  {
    id: 541,
    code: 100700,
    companyName: '세운메디칼',
    market: 'KOSDAQ',
    marketCap: 237396000000,
  },
  {
    id: 542,
    code: 234100,
    companyName: '세원',
    market: 'KOSDAQ',
    marketCap: 184413025500,
  },
  {
    id: 543,
    code: '024830',
    companyName: '세원물산',
    market: 'KOSDAQ',
    marketCap: 52438000000,
  },
  {
    id: 544,
    code: 258830,
    companyName: '세종메디칼',
    market: 'KOSDAQ',
    marketCap: 69471845250,
  },
  {
    id: 545,
    code: '036630',
    companyName: '세종텔레콤',
    market: 'KOSDAQ',
    marketCap: 431634484594,
  },
  {
    id: 546,
    code: '039310',
    companyName: '세중',
    market: 'KOSDAQ',
    marketCap: 95319968420,
  },
  {
    id: 547,
    code: '067770',
    companyName: '세진티에스',
    market: 'KOSDAQ',
    marketCap: 43914181390,
  },
  {
    id: 548,
    code: '053450',
    companyName: '세코닉스',
    market: 'KOSDAQ',
    marketCap: 93069669280,
  },
  {
    id: 549,
    code: 234340,
    companyName: '세틀뱅크',
    market: 'KOSDAQ',
    marketCap: 333527250000,
  },
  {
    id: 550,
    code: 252500,
    companyName: '세화피앤씨',
    market: 'KOSDAQ',
    marketCap: 90751478125,
  },
  {
    id: 551,
    code: 347000,
    companyName: '센코',
    market: 'KOSDAQ',
    marketCap: 162549506800,
  },
  {
    id: 552,
    code: '051980',
    companyName: '센트럴바이오',
    market: 'KOSDAQ',
    marketCap: 143962187420,
  },
  {
    id: 553,
    code: 331920,
    companyName: '셀레믹스',
    market: 'KOSDAQ',
    marketCap: 146138249200,
  },
  {
    id: 554,
    code: '049180',
    companyName: '셀루메드',
    market: 'KOSDAQ',
    marketCap: 298171440300,
  },
  {
    id: 555,
    code: 299660,
    companyName: '셀리드',
    market: 'KOSDAQ',
    marketCap: 1125628272000,
  },
  {
    id: 556,
    code: 268600,
    companyName: '셀리버리',
    market: 'KOSDAQ',
    marketCap: 1592055615600,
  },
  {
    id: 557,
    code: 108860,
    companyName: '셀바스AI',
    market: 'KOSDAQ',
    marketCap: 98700353875,
  },
  {
    id: 558,
    code: 208370,
    companyName: '셀바스헬스케어',
    market: 'KOSDAQ',
    marketCap: 62331621500,
  },
  {
    id: 559,
    code: '068760',
    companyName: '셀트리온제약',
    market: 'KOSDAQ',
    marketCap: 5463657498000,
  },
  {
    id: 560,
    code: '091990',
    companyName: '셀트리온헬스케어',
    market: 'KOSDAQ',
    marketCap: 18319926980400,
  },
  {
    id: 561,
    code: 290690,
    companyName: '소룩스',
    market: 'KOSDAQ',
    marketCap: 142017699600,
  },
  {
    id: 562,
    code: '053110',
    companyName: '소리바다',
    market: 'KOSDAQ',
    marketCap: 19563783228,
  },
  {
    id: 563,
    code: 950200,
    companyName: '소마젠(Reg.S)',
    market: 'KOSDAQ',
    marketCap: 237992590150,
  },
  {
    id: 564,
    code: '032680',
    companyName: '소프트센',
    market: 'KOSDAQ',
    marketCap: 81389947275,
  },
  {
    id: 565,
    code: '032685',
    companyName: '소프트센우',
    market: 'KOSDAQ',
    marketCap: 5749994250,
  },
  {
    id: 566,
    code: 258790,
    companyName: '소프트캠프',
    market: 'KOSDAQ',
    marketCap: 94592009940,
  },
  {
    id: 567,
    code: '066910',
    companyName: '손오공',
    market: 'KOSDAQ',
    marketCap: 82974656685,
  },
  {
    id: 568,
    code: '043100',
    companyName: '솔고바이오',
    market: 'KOSDAQ',
    marketCap: 23817609536,
  },
  {
    id: 569,
    code: 154040,
    companyName: '솔루에타',
    market: 'KOSDAQ',
    marketCap: 57858528960,
  },
  {
    id: 570,
    code: '035610',
    companyName: '솔본',
    market: 'KOSDAQ',
    marketCap: 179663200290,
  },
  {
    id: 571,
    code: 357780,
    companyName: '솔브레인',
    market: 'KOSDAQ',
    marketCap: 2576261059200,
  },
  {
    id: 572,
    code: '036830',
    companyName: '솔브레인홀딩스',
    market: 'KOSDAQ',
    marketCap: 773573666400,
  },
  {
    id: 573,
    code: 304100,
    companyName: '솔트룩스',
    market: 'KOSDAQ',
    marketCap: 153697678750,
  },
  {
    id: 574,
    code: '086980',
    companyName: '쇼박스',
    market: 'KOSDAQ',
    marketCap: 302984000000,
  },
  {
    id: 575,
    code: '050960',
    companyName: '수산아이앤티',
    market: 'KOSDAQ',
    marketCap: 212656500000,
  },
  {
    id: 576,
    code: '084180',
    companyName: '수성이노베이션',
    market: 'KOSDAQ',
    marketCap: 67993865500,
  },
  {
    id: 577,
    code: 253840,
    companyName: '수젠텍',
    market: 'KOSDAQ',
    marketCap: 284694946000,
  },
  {
    id: 578,
    code: '058530',
    companyName: '슈펙스비앤피',
    market: 'KOSDAQ',
    marketCap: 21187053360,
  },
  {
    id: 579,
    code: 236200,
    companyName: '슈프리마',
    market: 'KOSDAQ',
    marketCap: 198893998450,
  },
  {
    id: 580,
    code: 317770,
    companyName: '슈프리마아이디',
    market: 'KOSDAQ',
    marketCap: 60622297000,
  },
  {
    id: 581,
    code: '094840',
    companyName: '슈프리마에이치큐',
    market: 'KOSDAQ',
    marketCap: 113619464000,
  },
  {
    id: 582,
    code: 192440,
    companyName: '슈피겐코리아',
    market: 'KOSDAQ',
    marketCap: 351224509500,
  },
  {
    id: 583,
    code: '099440',
    companyName: '스맥',
    market: 'KOSDAQ',
    marketCap: 61008198865,
  },
  {
    id: 584,
    code: '033790',
    companyName: '스카이문스테크놀로지',
    market: 'KOSDAQ',
    marketCap: 14190075900,
  },
  {
    id: 585,
    code: 131100,
    companyName: '스카이이앤엠',
    market: 'KOSDAQ',
    marketCap: 60683371350,
  },
  {
    id: 586,
    code: 159910,
    companyName: '스킨앤스킨',
    market: 'KOSDAQ',
    marketCap: 68488344954,
  },
  {
    id: 587,
    code: 158310,
    companyName: '스타모빌리티',
    market: 'KOSDAQ',
    marketCap: 6096179210,
  },
  {
    id: 588,
    code: 115570,
    companyName: '스타플렉스',
    market: 'KOSDAQ',
    marketCap: 46310146400,
  },
  {
    id: 589,
    code: 253450,
    companyName: '스튜디오드래곤',
    market: 'KOSDAQ',
    marketCap: 2883417554500,
  },
  {
    id: 590,
    code: 204630,
    companyName: '스튜디오산타클로스',
    market: 'KOSDAQ',
    marketCap: 70689252000,
  },
  {
    id: 591,
    code: '013810',
    companyName: '스페코',
    market: 'KOSDAQ',
    marketCap: 123399057400,
  },
  {
    id: 592,
    code: '039670',
    companyName: '스포츠서울',
    market: 'KOSDAQ',
    marketCap: 67797501120,
  },
  {
    id: 593,
    code: '049830',
    companyName: '승일',
    market: 'KOSDAQ',
    marketCap: 140118759200,
  },
  {
    id: 594,
    code: '020710',
    companyName: '시공테크',
    market: 'KOSDAQ',
    marketCap: 172011582600,
  },
  {
    id: 595,
    code: '033170',
    companyName: '시그네틱스',
    market: 'KOSDAQ',
    marketCap: 177886261925,
  },
  {
    id: 596,
    code: '048870',
    companyName: '시너지이노베이션',
    market: 'KOSDAQ',
    marketCap: 323208587975,
  },
  {
    id: 597,
    code: '025320',
    companyName: '시노펙스',
    market: 'KOSDAQ',
    marketCap: 269167870650,
  },
  {
    id: 598,
    code: 269620,
    companyName: '시스웍',
    market: 'KOSDAQ',
    marketCap: 142393051775,
  },
  {
    id: 599,
    code: 131090,
    companyName: '시큐브',
    market: 'KOSDAQ',
    marketCap: 69720000000,
  },
  {
    id: 600,
    code: 139050,
    companyName: '시티랩스',
    market: 'KOSDAQ',
    marketCap: 66452728419,
  },
  {
    id: 601,
    code: 290520,
    companyName: '신도기연',
    market: 'KOSDAQ',
    marketCap: 131801928000,
  },
  {
    id: 602,
    code: '001000',
    companyName: '신라섬유',
    market: 'KOSDAQ',
    marketCap: 78295066500,
  },
  {
    id: 603,
    code: '025870',
    companyName: '신라에스지',
    market: 'KOSDAQ',
    marketCap: 83400000000,
  },
  {
    id: 604,
    code: 215600,
    companyName: '신라젠',
    market: 'KOSDAQ',
    marketCap: 866567212500,
  },
  {
    id: 605,
    code: '065350',
    companyName: '신성델타테크',
    market: 'KOSDAQ',
    marketCap: 376530087600,
  },
  {
    id: 606,
    code: '002800',
    companyName: '신신제약',
    market: 'KOSDAQ',
    marketCap: 130162890000,
  },
  {
    id: 607,
    code: 323280,
    companyName: '신영스팩5호',
    market: 'KOSDAQ',
    marketCap: 8324375000,
  },
  {
    id: 608,
    code: 344050,
    companyName: '신영스팩6호',
    market: 'KOSDAQ',
    marketCap: 10633350000,
  },
  {
    id: 609,
    code: '017000',
    companyName: '신원종합개발',
    market: 'KOSDAQ',
    marketCap: 88437943830,
  },
  {
    id: 610,
    code: '012790',
    companyName: '신일제약',
    market: 'KOSDAQ',
    marketCap: 171622945800,
  },
  {
    id: 611,
    code: 138070,
    companyName: '신진에스엠',
    market: 'KOSDAQ',
    marketCap: 119330033900,
  },
  {
    id: 612,
    code: 226330,
    companyName: '신테카바이오',
    market: 'KOSDAQ',
    marketCap: 186975221600,
  },
  {
    id: 613,
    code: 333050,
    companyName: '신한제6호스팩',
    market: 'KOSDAQ',
    marketCap: 10771500000,
  },
  {
    id: 614,
    code: 366330,
    companyName: '신한제7호스팩',
    market: 'KOSDAQ',
    marketCap: 9850750000,
  },
  {
    id: 615,
    code: '056700',
    companyName: '신화인터텍',
    market: 'KOSDAQ',
    marketCap: 110859021255,
  },
  {
    id: 616,
    code: 187270,
    companyName: '신화콘텍',
    market: 'KOSDAQ',
    marketCap: 41386887600,
  },
  {
    id: 617,
    code: 243840,
    companyName: '신흥에스이씨',
    market: 'KOSDAQ',
    marketCap: 375784848100,
  },
  {
    id: 618,
    code: 108320,
    companyName: '실리콘웍스',
    market: 'KOSDAQ',
    marketCap: 1689860770000,
  },
  {
    id: 619,
    code: 222800,
    companyName: '심텍',
    market: 'KOSDAQ',
    marketCap: 742201531900,
  },
  {
    id: 620,
    code: '036710',
    companyName: '심텍홀딩스',
    market: 'KOSDAQ',
    marketCap: 118127696995,
  },
  {
    id: 621,
    code: 160980,
    companyName: '싸이맥스',
    market: 'KOSDAQ',
    marketCap: 231593951600,
  },
  {
    id: 622,
    code: 356890,
    companyName: '싸이버원',
    market: 'KOSDAQ',
    marketCap: 67988159500,
  },
  {
    id: 623,
    code: 217330,
    companyName: '싸이토젠',
    market: 'KOSDAQ',
    marketCap: 280361268000,
  },
  {
    id: 624,
    code: '010280',
    companyName: '쌍용정보통신',
    market: 'KOSDAQ',
    marketCap: 51591643350,
  },
  {
    id: 625,
    code: 208640,
    companyName: '썸에이지',
    market: 'KOSDAQ',
    marketCap: 335495341200,
  },
  {
    id: 626,
    code: 222420,
    companyName: '쎄노텍',
    market: 'KOSDAQ',
    marketCap: 90556684625,
  },
  {
    id: 627,
    code: '037760',
    companyName: '쎄니트',
    market: 'KOSDAQ',
    marketCap: 80860681190,
  },
  {
    id: 628,
    code: 136510,
    companyName: '쎄미시스코',
    market: 'KOSDAQ',
    marketCap: 266662216700,
  },
  {
    id: 629,
    code: '099320',
    companyName: '쎄트렉아이',
    market: 'KOSDAQ',
    marketCap: 474268685400,
  },
  {
    id: 630,
    code: '049960',
    companyName: '쎌바이오텍',
    market: 'KOSDAQ',
    marketCap: 194580000000,
  },
  {
    id: 631,
    code: '050890',
    companyName: '쏠리드',
    market: 'KOSDAQ',
    marketCap: 381995481000,
  },
  {
    id: 632,
    code: '066790',
    companyName: '씨씨에스',
    market: 'KOSDAQ',
    marketCap: 54492928245,
  },
  {
    id: 633,
    code: 222080,
    companyName: '씨아이에스',
    market: 'KOSDAQ',
    marketCap: 684150944400,
  },
  {
    id: 634,
    code: 352480,
    companyName: '씨앤씨인터내셔널',
    market: 'KOSDAQ',
    marketCap: 283634496000,
  },
  {
    id: 635,
    code: 264660,
    companyName: '씨앤지하이테크',
    market: 'KOSDAQ',
    marketCap: 111792884000,
  },
  {
    id: 636,
    code: 352700,
    companyName: '씨앤투스성진',
    market: 'KOSDAQ',
    marketCap: 204908385500,
  },
  {
    id: 637,
    code: '056730',
    companyName: '씨앤티85',
    market: 'KOSDAQ',
    marketCap: 109591063600,
  },
  {
    id: 638,
    code: 297090,
    companyName: '씨에스베어링',
    market: 'KOSDAQ',
    marketCap: 273520420000,
  },
  {
    id: 639,
    code: 115530,
    companyName: '씨엔플러스',
    market: 'KOSDAQ',
    marketCap: 57020957000,
  },
  {
    id: 640,
    code: 225330,
    companyName: '씨엠에스에듀',
    market: 'KOSDAQ',
    marketCap: 134542014160,
  },
  {
    id: 641,
    code: 115480,
    companyName: '씨유메디칼',
    market: 'KOSDAQ',
    marketCap: 45309474000,
  },
  {
    id: 642,
    code: 189330,
    companyName: '씨이랩',
    market: 'KOSDAQ',
    marketCap: 143989158600,
  },
  {
    id: 643,
    code: '096530',
    companyName: '씨젠',
    market: 'KOSDAQ',
    marketCap: 3305905420200,
  },
  {
    id: 644,
    code: 900120,
    companyName: '씨케이에이치',
    market: 'KOSDAQ',
    marketCap: 35771044068,
  },
  {
    id: 645,
    code: 101240,
    companyName: '씨큐브',
    market: 'KOSDAQ',
    marketCap: 89114637360,
  },
  {
    id: 646,
    code: '060590',
    companyName: '씨티씨바이오',
    market: 'KOSDAQ',
    marketCap: 168470219890,
  },
  {
    id: 647,
    code: 260930,
    companyName: '씨티케이코스메틱스',
    market: 'KOSDAQ',
    marketCap: 182004371310,
  },
  {
    id: 648,
    code: '013990',
    companyName: '아가방컴퍼니',
    market: 'KOSDAQ',
    marketCap: 178908195840,
  },
  {
    id: 649,
    code: 123860,
    companyName: '아나패스',
    market: 'KOSDAQ',
    marketCap: 272701475200,
  },
  {
    id: 650,
    code: '025980',
    companyName: '아난티',
    market: 'KOSDAQ',
    marketCap: 873616345750,
  },
  {
    id: 651,
    code: '050320',
    companyName: '아래스',
    market: 'KOSDAQ',
    marketCap: 45205142400,
  },
  {
    id: 652,
    code: '058220',
    companyName: '아리온',
    market: 'KOSDAQ',
    marketCap: 10717917100,
  },
  {
    id: 653,
    code: 125210,
    companyName: '아모그린텍',
    market: 'KOSDAQ',
    marketCap: 229305381000,
  },
  {
    id: 654,
    code: '052710',
    companyName: '아모텍',
    market: 'KOSDAQ',
    marketCap: 289866328500,
  },
  {
    id: 655,
    code: '074430',
    companyName: '아미노로직스',
    market: 'KOSDAQ',
    marketCap: 266993605760,
  },
  {
    id: 656,
    code: '092040',
    companyName: '아미코젠',
    market: 'KOSDAQ',
    marketCap: 727458453500,
  },
  {
    id: 657,
    code: '083930',
    companyName: '아바코',
    market: 'KOSDAQ',
    marketCap: 191889335100,
  },
  {
    id: 658,
    code: 149950,
    companyName: '아바텍',
    market: 'KOSDAQ',
    marketCap: 273911625000,
  },
  {
    id: 659,
    code: '036010',
    companyName: '아비코전자',
    market: 'KOSDAQ',
    marketCap: 109267917480,
  },
  {
    id: 660,
    code: '050860',
    companyName: '아세아텍',
    market: 'KOSDAQ',
    marketCap: 59412500000,
  },
  {
    id: 661,
    code: 246720,
    companyName: '아스타',
    market: 'KOSDAQ',
    marketCap: 69299913690,
  },
  {
    id: 662,
    code: '067390',
    companyName: '아스트',
    market: 'KOSDAQ',
    marketCap: 202616864790,
  },
  {
    id: 663,
    code: 127710,
    companyName: '아시아경제',
    market: 'KOSDAQ',
    marketCap: 130094543400,
  },
  {
    id: 664,
    code: 154030,
    companyName: '아시아종묘',
    market: 'KOSDAQ',
    marketCap: 77207174630,
  },
  {
    id: 665,
    code: 227610,
    companyName: '아우딘퓨쳐스',
    market: 'KOSDAQ',
    marketCap: 61400000000,
  },
  {
    id: 666,
    code: 143160,
    companyName: '아이디스',
    market: 'KOSDAQ',
    marketCap: 297915642000,
  },
  {
    id: 667,
    code: '054800',
    companyName: '아이디스홀딩스',
    market: 'KOSDAQ',
    marketCap: 150559849800,
  },
  {
    id: 668,
    code: 332370,
    companyName: '아이디피',
    market: 'KOSDAQ',
    marketCap: 70350747800,
  },
  {
    id: 669,
    code: 339950,
    companyName: '아이비김영',
    market: 'KOSDAQ',
    marketCap: 134615231725,
  },
  {
    id: 670,
    code: '099190',
    companyName: '아이센스',
    market: 'KOSDAQ',
    marketCap: 393571868700,
  },
  {
    id: 671,
    code: 289010,
    companyName: '아이스크림에듀',
    market: 'KOSDAQ',
    marketCap: 109987516350,
  },
  {
    id: 672,
    code: 214430,
    companyName: '아이쓰리시스템',
    market: 'KOSDAQ',
    marketCap: 144977904000,
  },
  {
    id: 673,
    code: '040910',
    companyName: '아이씨디',
    market: 'KOSDAQ',
    marketCap: 276400818900,
  },
  {
    id: 674,
    code: '068940',
    companyName: '아이씨케이',
    market: 'KOSDAQ',
    marketCap: 138025293560,
  },
  {
    id: 675,
    code: '052860',
    companyName: '아이앤씨',
    market: 'KOSDAQ',
    marketCap: 90743298320,
  },
  {
    id: 676,
    code: '069920',
    companyName: '아이에스이커머스',
    market: 'KOSDAQ',
    marketCap: 94395353110,
  },
  {
    id: 677,
    code: '038880',
    companyName: '아이에이',
    market: 'KOSDAQ',
    marketCap: 389095278000,
  },
  {
    id: 678,
    code: 307180,
    companyName: '아이엘사이언스',
    market: 'KOSDAQ',
    marketCap: 76293172685,
  },
  {
    id: 679,
    code: 101390,
    companyName: '아이엠',
    market: 'KOSDAQ',
    marketCap: 104235822000,
  },
  {
    id: 680,
    code: '078860',
    companyName: '아이오케이',
    market: 'KOSDAQ',
    marketCap: 193752156400,
  },
  {
    id: 681,
    code: 114810,
    companyName: '아이원스',
    market: 'KOSDAQ',
    marketCap: 244320406920,
  },
  {
    id: 682,
    code: '031310',
    companyName: '아이즈비전',
    market: 'KOSDAQ',
    marketCap: 84067819420,
  },
  {
    id: 683,
    code: 185490,
    companyName: '아이진',
    market: 'KOSDAQ',
    marketCap: 388744410600,
  },
  {
    id: 684,
    code: '059100',
    companyName: '아이컴포넌트',
    market: 'KOSDAQ',
    marketCap: 59607349800,
  },
  {
    id: 685,
    code: 262840,
    companyName: '아이퀘스트',
    market: 'KOSDAQ',
    marketCap: 118320000000,
  },
  {
    id: 686,
    code: 175250,
    companyName: '아이큐어',
    market: 'KOSDAQ',
    marketCap: 378483831300,
  },
  {
    id: 687,
    code: '052460',
    companyName: '아이크래프트',
    market: 'KOSDAQ',
    marketCap: 130156709760,
  },
  {
    id: 688,
    code: 119830,
    companyName: '아이텍',
    market: 'KOSDAQ',
    marketCap: 166469645100,
  },
  {
    id: 689,
    code: '052770',
    companyName: '아이톡시',
    market: 'KOSDAQ',
    marketCap: 35178772260,
  },
  {
    id: 690,
    code: 124500,
    companyName: '아이티센',
    market: 'KOSDAQ',
    marketCap: 131388397800,
  },
  {
    id: 691,
    code: '084850',
    companyName: '아이티엠반도체',
    market: 'KOSDAQ',
    marketCap: 1134031781700,
  },
  {
    id: 692,
    code: '027360',
    companyName: '아주IB투자',
    market: 'KOSDAQ',
    marketCap: 883644167250,
  },
  {
    id: 693,
    code: '032080',
    companyName: '아즈텍WB',
    market: 'KOSDAQ',
    marketCap: 62968714970,
  },
  {
    id: 694,
    code: '013310',
    companyName: '아진산업',
    market: 'KOSDAQ',
    marketCap: 149987439430,
  },
  {
    id: 695,
    code: '059120',
    companyName: '아진엑스텍',
    market: 'KOSDAQ',
    marketCap: 77013908400,
  },
  {
    id: 696,
    code: 158430,
    companyName: '아톤',
    market: 'KOSDAQ',
    marketCap: 99261555600,
  },
  {
    id: 697,
    code: '067160',
    companyName: '아프리카TV',
    market: 'KOSDAQ',
    marketCap: 1208100011700,
  },
  {
    id: 698,
    code: '001540',
    companyName: '안국약품',
    market: 'KOSDAQ',
    marketCap: 183246001000,
  },
  {
    id: 699,
    code: '053800',
    companyName: '안랩',
    market: 'KOSDAQ',
    marketCap: 715990632500,
  },
  {
    id: 700,
    code: '065660',
    companyName: '안트로젠',
    market: 'KOSDAQ',
    marketCap: 667066626000,
  },
  {
    id: 701,
    code: 297570,
    companyName: '알로이스',
    market: 'KOSDAQ',
    marketCap: 112814485140,
  },
  {
    id: 702,
    code: 260660,
    companyName: '알리코제약',
    market: 'KOSDAQ',
    marketCap: 99295615000,
  },
  {
    id: 703,
    code: 131370,
    companyName: '알서포트',
    market: 'KOSDAQ',
    marketCap: 447443497200,
  },
  {
    id: 704,
    code: 140670,
    companyName: '알에스오토메이션',
    market: 'KOSDAQ',
    marketCap: 94672656000,
  },
  {
    id: 705,
    code: '096610',
    companyName: '알에프세미',
    market: 'KOSDAQ',
    marketCap: 50227089960,
  },
  {
    id: 706,
    code: '061040',
    companyName: '알에프텍',
    market: 'KOSDAQ',
    marketCap: 262169246030,
  },
  {
    id: 707,
    code: 148250,
    companyName: '알엔투테크놀로지',
    market: 'KOSDAQ',
    marketCap: 111536467100,
  },
  {
    id: 708,
    code: 347860,
    companyName: '알체라',
    market: 'KOSDAQ',
    marketCap: 426157011000,
  },
  {
    id: 709,
    code: 196170,
    companyName: '알테오젠',
    market: 'KOSDAQ',
    marketCap: 3184540925000,
  },
  {
    id: 710,
    code: 123750,
    companyName: '알톤스포츠',
    market: 'KOSDAQ',
    marketCap: 73418670720,
  },
  {
    id: 711,
    code: '085810',
    companyName: '알티캐스트',
    market: 'KOSDAQ',
    marketCap: 69842054760,
  },
  {
    id: 712,
    code: 117670,
    companyName: '알파홀딩스',
    market: 'KOSDAQ',
    marketCap: 86599686910,
  },
  {
    id: 713,
    code: 291650,
    companyName: '압타머사이언스',
    market: 'KOSDAQ',
    marketCap: 176518727100,
  },
  {
    id: 714,
    code: 293780,
    companyName: '압타바이오',
    market: 'KOSDAQ',
    marketCap: 669042549900,
  },
  {
    id: 715,
    code: 196300,
    companyName: '애니젠',
    market: 'KOSDAQ',
    marketCap: 72628084250,
  },
  {
    id: 716,
    code: 310200,
    companyName: '애니플러스',
    market: 'KOSDAQ',
    marketCap: 114197904780,
  },
  {
    id: 717,
    code: 900100,
    companyName: '애머릿지',
    market: 'KOSDAQ',
    marketCap: 99937345710,
  },
  {
    id: 718,
    code: 205500,
    companyName: '액션스퀘어',
    market: 'KOSDAQ',
    marketCap: 217409712700,
  },
  {
    id: 719,
    code: '052790',
    companyName: '액토즈소프트',
    market: 'KOSDAQ',
    marketCap: 141066443100,
  },
  {
    id: 720,
    code: 290740,
    companyName: '액트로',
    market: 'KOSDAQ',
    marketCap: 92094850650,
  },
  {
    id: 721,
    code: 238090,
    companyName: '앤디포스',
    market: 'KOSDAQ',
    marketCap: 268025060300,
  },
  {
    id: 722,
    code: '092600',
    companyName: '앤씨앤',
    market: 'KOSDAQ',
    marketCap: 102328537500,
  },
  {
    id: 723,
    code: 129890,
    companyName: '앱코',
    market: 'KOSDAQ',
    marketCap: 231086827000,
  },
  {
    id: 724,
    code: 174900,
    companyName: '앱클론',
    market: 'KOSDAQ',
    marketCap: 342228745600,
  },
  {
    id: 725,
    code: 255440,
    companyName: '야스',
    market: 'KOSDAQ',
    marketCap: 224597600000,
  },
  {
    id: 726,
    code: '030960',
    companyName: '양지사',
    market: 'KOSDAQ',
    marketCap: 219725000000,
  },
  {
    id: 727,
    code: 102120,
    companyName: '어보브반도체',
    market: 'KOSDAQ',
    marketCap: 274989534750,
  },
  {
    id: 728,
    code: 238120,
    companyName: '얼라인드',
    market: 'KOSDAQ',
    marketCap: 62835775520,
  },
  {
    id: 729,
    code: '019990',
    companyName: '에너토크',
    market: 'KOSDAQ',
    marketCap: 140487667200,
  },
  {
    id: 730,
    code: '038680',
    companyName: '에스넷',
    market: 'KOSDAQ',
    marketCap: 123779155500,
  },
  {
    id: 731,
    code: 217480,
    companyName: '에스디생명공학',
    market: 'KOSDAQ',
    marketCap: 101091947660,
  },
  {
    id: 732,
    code: 121890,
    companyName: '에스디시스템',
    market: 'KOSDAQ',
    marketCap: 7101198800,
  },
  {
    id: 733,
    code: '097780',
    companyName: '에스맥',
    market: 'KOSDAQ',
    marketCap: 412015308015,
  },
  {
    id: 734,
    code: '042110',
    companyName: '에스씨디',
    market: 'KOSDAQ',
    marketCap: 123240388200,
  },
  {
    id: 735,
    code: 298060,
    companyName: '에스씨엠생명과학',
    market: 'KOSDAQ',
    marketCap: 371399838700,
  },
  {
    id: 736,
    code: '065420',
    companyName: '에스아이리소스',
    market: 'KOSDAQ',
    marketCap: 43500682854,
  },
  {
    id: 737,
    code: 103230,
    companyName: '에스앤더블류',
    market: 'KOSDAQ',
    marketCap: 18036000000,
  },
  {
    id: 738,
    code: 101490,
    companyName: '에스앤에스텍',
    market: 'KOSDAQ',
    marketCap: 623164535350,
  },
  {
    id: 739,
    code: '095910',
    companyName: '에스에너지',
    market: 'KOSDAQ',
    marketCap: 73515061515,
  },
  {
    id: 740,
    code: 275630,
    companyName: '에스에스알',
    market: 'KOSDAQ',
    marketCap: 43467178540,
  },
  {
    id: 741,
    code: '031330',
    companyName: '에스에이엠티',
    market: 'KOSDAQ',
    marketCap: 404980021350,
  },
  {
    id: 742,
    code: '060540',
    companyName: '에스에이티',
    market: 'KOSDAQ',
    marketCap: 86893242450,
  },
  {
    id: 743,
    code: '056190',
    companyName: '에스에프에이',
    market: 'KOSDAQ',
    marketCap: 1475850036000,
  },
  {
    id: 744,
    code: '080000',
    companyName: '에스엔유',
    market: 'KOSDAQ',
    marketCap: 148103184050,
  },
  {
    id: 745,
    code: 214310,
    companyName: '에스엘바이오닉스',
    market: 'KOSDAQ',
    marketCap: 45901733430,
  },
  {
    id: 746,
    code: '041510',
    companyName: '에스엠',
    market: 'KOSDAQ',
    marketCap: 1289887555000,
  },
  {
    id: 747,
    code: '007820',
    companyName: '에스엠코어',
    market: 'KOSDAQ',
    marketCap: 156665457720,
  },
  {
    id: 748,
    code: 109610,
    companyName: '에스와이',
    market: 'KOSDAQ',
    marketCap: 184898528490,
  },
  {
    id: 749,
    code: 306040,
    companyName: '에스제이그룹',
    market: 'KOSDAQ',
    marketCap: 289068760400,
  },
  {
    id: 750,
    code: '080440',
    companyName: '에스제이케이',
    market: 'KOSDAQ',
    marketCap: 6636328080,
  },
  {
    id: 751,
    code: '096630',
    companyName: '에스코넥',
    market: 'KOSDAQ',
    marketCap: 161449917560,
  },
  {
    id: 752,
    code: '069510',
    companyName: '에스텍',
    market: 'KOSDAQ',
    marketCap: 133647500000,
  },
  {
    id: 753,
    code: '041910',
    companyName: '에스텍파마',
    market: 'KOSDAQ',
    marketCap: 154677419000,
  },
  {
    id: 754,
    code: 234300,
    companyName: '에스트래픽',
    market: 'KOSDAQ',
    marketCap: 156612723200,
  },
  {
    id: 755,
    code: '039440',
    companyName: '에스티아이',
    market: 'KOSDAQ',
    marketCap: 292063500000,
  },
  {
    id: 756,
    code: '098660',
    companyName: '에스티오',
    market: 'KOSDAQ',
    marketCap: 44253455040,
  },
  {
    id: 757,
    code: '052020',
    companyName: '에스티큐브',
    market: 'KOSDAQ',
    marketCap: 250416645570,
  },
  {
    id: 758,
    code: 237690,
    companyName: '에스티팜',
    market: 'KOSDAQ',
    marketCap: 1884256000000,
  },
  {
    id: 759,
    code: '050760',
    companyName: '에스폴리텍',
    market: 'KOSDAQ',
    marketCap: 83960244920,
  },
  {
    id: 760,
    code: 288620,
    companyName: '에스퓨얼셀',
    market: 'KOSDAQ',
    marketCap: 230977017600,
  },
  {
    id: 761,
    code: 317830,
    companyName: '에스피시스템스',
    market: 'KOSDAQ',
    marketCap: 61988777000,
  },
  {
    id: 762,
    code: '058610',
    companyName: '에스피지',
    market: 'KOSDAQ',
    marketCap: 276108132000,
  },
  {
    id: 763,
    code: '043340',
    companyName: '에쎈테크',
    market: 'KOSDAQ',
    marketCap: 100800000000,
  },
  {
    id: 764,
    code: 187660,
    companyName: '에이디엠코리아',
    market: 'KOSDAQ',
    marketCap: 157657725000,
  },
  {
    id: 765,
    code: '054630',
    companyName: '에이디칩스',
    market: 'KOSDAQ',
    marketCap: 62551163980,
  },
  {
    id: 766,
    code: 200710,
    companyName: '에이디테크놀로지',
    market: 'KOSDAQ',
    marketCap: 318582984800,
  },
  {
    id: 767,
    code: '096690',
    companyName: '에이루트',
    market: 'KOSDAQ',
    marketCap: 101537594000,
  },
  {
    id: 768,
    code: 298380,
    companyName: '에이비엘바이오',
    market: 'KOSDAQ',
    marketCap: 954246318750,
  },
  {
    id: 769,
    code: 195990,
    companyName: '에이비프로바이오',
    market: 'KOSDAQ',
    marketCap: 315594327920,
  },
  {
    id: 770,
    code: '003800',
    companyName: '에이스침대',
    market: 'KOSDAQ',
    marketCap: 537310500000,
  },
  {
    id: 771,
    code: '088800',
    companyName: '에이스테크',
    market: 'KOSDAQ',
    marketCap: 785169594000,
  },
  {
    id: 772,
    code: 241840,
    companyName: '에이스토리',
    market: 'KOSDAQ',
    marketCap: 329798896000,
  },
  {
    id: 773,
    code: 138360,
    companyName: '에이씨티',
    market: 'KOSDAQ',
    marketCap: 155061688320,
  },
  {
    id: 774,
    code: '039230',
    companyName: '에이아이비트',
    market: 'KOSDAQ',
    marketCap: 19975625532,
  },
  {
    id: 775,
    code: 312610,
    companyName: '에이에프더블류',
    market: 'KOSDAQ',
    marketCap: 181906854240,
  },
  {
    id: 776,
    code: '072990',
    companyName: '에이치시티',
    market: 'KOSDAQ',
    marketCap: 117176484250,
  },
  {
    id: 777,
    code: 176440,
    companyName: '에이치엔티',
    market: 'KOSDAQ',
    marketCap: 164619542400,
  },
  {
    id: 778,
    code: '028300',
    companyName: '에이치엘비',
    market: 'KOSDAQ',
    marketCap: 3606436780000,
  },
  {
    id: 779,
    code: '067630',
    companyName: '에이치엘비생명과학',
    market: 'KOSDAQ',
    marketCap: 984190467700,
  },
  {
    id: 780,
    code: '047920',
    companyName: '에이치엘비제약',
    market: 'KOSDAQ',
    marketCap: 325306295550,
  },
  {
    id: 781,
    code: '043220',
    companyName: '에이치엘비파워',
    market: 'KOSDAQ',
    marketCap: 200453829400,
  },
  {
    id: 782,
    code: 239610,
    companyName: '에이치엘사이언스',
    market: 'KOSDAQ',
    marketCap: 215684600000,
  },
  {
    id: 783,
    code: 353070,
    companyName: '에이치엠씨제4호스팩',
    market: 'KOSDAQ',
    marketCap: 9331150000,
  },
  {
    id: 784,
    code: 353060,
    companyName: '에이치엠씨제5호스팩',
    market: 'KOSDAQ',
    marketCap: 10920525000,
  },
  {
    id: 785,
    code: '044780',
    companyName: '에이치케이',
    market: 'KOSDAQ',
    marketCap: 42378252230,
  },
  {
    id: 786,
    code: 357230,
    companyName: '에이치피오',
    market: 'KOSDAQ',
    marketCap: 355896156000,
  },
  {
    id: 787,
    code: '071670',
    companyName: '에이테크솔루션',
    market: 'KOSDAQ',
    marketCap: 162000000000,
  },
  {
    id: 788,
    code: '045660',
    companyName: '에이텍',
    market: 'KOSDAQ',
    marketCap: 305207000000,
  },
  {
    id: 789,
    code: 224110,
    companyName: '에이텍티앤',
    market: 'KOSDAQ',
    marketCap: 197313000000,
  },
  {
    id: 790,
    code: 230980,
    companyName: '에이트원',
    market: 'KOSDAQ',
    marketCap: 115446811620,
  },
  {
    id: 791,
    code: '021080',
    companyName: '에이티넘인베스트',
    market: 'KOSDAQ',
    marketCap: 213120000000,
  },
  {
    id: 792,
    code: '089530',
    companyName: '에이티세미콘',
    market: 'KOSDAQ',
    marketCap: 46350138250,
  },
  {
    id: 793,
    code: 200470,
    companyName: '에이팩트',
    market: 'KOSDAQ',
    marketCap: 130599379200,
  },
  {
    id: 794,
    code: 262260,
    companyName: '에이프로',
    market: 'KOSDAQ',
    marketCap: 289330323300,
  },
  {
    id: 795,
    code: 109960,
    companyName: '에이프로젠 H&G',
    market: 'KOSDAQ',
    marketCap: 178516521783,
  },
  {
    id: 796,
    code: '089970',
    companyName: '에이피티씨',
    market: 'KOSDAQ',
    marketCap: 584401956000,
  },
  {
    id: 797,
    code: 230240,
    companyName: '에치에프알',
    market: 'KOSDAQ',
    marketCap: 288557248000,
  },
  {
    id: 798,
    code: '064510',
    companyName: '에코마이스터',
    market: 'KOSDAQ',
    marketCap: 22321778050,
  },
  {
    id: 799,
    code: 230360,
    companyName: '에코마케팅',
    market: 'KOSDAQ',
    marketCap: 823235679350,
  },
  {
    id: 800,
    code: '038870',
    companyName: '에코바이오',
    market: 'KOSDAQ',
    marketCap: 105427825520,
  },
  {
    id: 801,
    code: 128540,
    companyName: '에코캡',
    market: 'KOSDAQ',
    marketCap: 66533716000,
  },
  {
    id: 802,
    code: '086520',
    companyName: '에코프로',
    market: 'KOSDAQ',
    marketCap: 833146790850,
  },
  {
    id: 803,
    code: 247540,
    companyName: '에코프로비엠',
    market: 'KOSDAQ',
    marketCap: 4460611331000,
  },
  {
    id: 804,
    code: 383310,
    companyName: '에코프로에이치엔',
    market: 'KOSDAQ',
    marketCap: 534907373400,
  },
  {
    id: 805,
    code: '038110',
    companyName: '에코플라스틱',
    market: 'KOSDAQ',
    marketCap: 77008110000,
  },
  {
    id: 806,
    code: '073540',
    companyName: '에프알텍',
    market: 'KOSDAQ',
    marketCap: 53295000000,
  },
  {
    id: 807,
    code: '064850',
    companyName: '에프앤가이드',
    market: 'KOSDAQ',
    marketCap: 120075200000,
  },
  {
    id: 808,
    code: '064090',
    companyName: '에프앤리퍼블릭',
    market: 'KOSDAQ',
    marketCap: 34067169450,
  },
  {
    id: 809,
    code: '036810',
    companyName: '에프에스티',
    market: 'KOSDAQ',
    marketCap: 589608981900,
  },
  {
    id: 810,
    code: 173940,
    companyName: '에프엔씨엔터',
    market: 'KOSDAQ',
    marketCap: 97250237600,
  },
  {
    id: 811,
    code: '083500',
    companyName: '에프엔에스테크',
    market: 'KOSDAQ',
    marketCap: 93500800300,
  },
  {
    id: 812,
    code: '054940',
    companyName: '엑사이엔씨',
    market: 'KOSDAQ',
    marketCap: 64688418600,
  },
  {
    id: 813,
    code: 950130,
    companyName: '엑세스바이오',
    market: 'KOSDAQ',
    marketCap: 760810290650,
  },
  {
    id: 814,
    code: 205100,
    companyName: '엑셈',
    market: 'KOSDAQ',
    marketCap: 159077271735,
  },
  {
    id: 815,
    code: '070300',
    companyName: '엑스큐어',
    market: 'KOSDAQ',
    marketCap: 34620547800,
  },
  {
    id: 816,
    code: '092870',
    companyName: '엑시콘',
    market: 'KOSDAQ',
    marketCap: 143522939900,
  },
  {
    id: 817,
    code: 317870,
    companyName: '엔바이오니아',
    market: 'KOSDAQ',
    marketCap: 85170910500,
  },
  {
    id: 818,
    code: '067570',
    companyName: '엔브이에이치코리아',
    market: 'KOSDAQ',
    marketCap: 164019683520,
  },
  {
    id: 819,
    code: 236810,
    companyName: '엔비티',
    market: 'KOSDAQ',
    marketCap: 226197551450,
  },
  {
    id: 820,
    code: 333620,
    companyName: '엔시스',
    market: 'KOSDAQ',
    marketCap: 200570536000,
  },
  {
    id: 821,
    code: 101400,
    companyName: '엔시트론',
    market: 'KOSDAQ',
    marketCap: 40531365795,
  },
  {
    id: 822,
    code: 217820,
    companyName: '엔에스',
    market: 'KOSDAQ',
    marketCap: 136431858450,
  },
  {
    id: 823,
    code: '031860',
    companyName: '엔에스엔',
    market: 'KOSDAQ',
    marketCap: 76731701970,
  },
  {
    id: 824,
    code: 310840,
    companyName: '엔에이치스팩13호',
    market: 'KOSDAQ',
    marketCap: 7695000000,
  },
  {
    id: 825,
    code: 353190,
    companyName: '엔에이치스팩16호',
    market: 'KOSDAQ',
    marketCap: 13447200000,
  },
  {
    id: 826,
    code: 359090,
    companyName: '엔에이치스팩17호',
    market: 'KOSDAQ',
    marketCap: 15106550000,
  },
  {
    id: 827,
    code: 365590,
    companyName: '엔에이치스팩18호',
    market: 'KOSDAQ',
    marketCap: 9982000000,
  },
  {
    id: 828,
    code: 265740,
    companyName: '엔에프씨',
    market: 'KOSDAQ',
    marketCap: 141122440000,
  },
  {
    id: 829,
    code: 354200,
    companyName: '엔젠바이오',
    market: 'KOSDAQ',
    marketCap: 246061748800,
  },
  {
    id: 830,
    code: 208860,
    companyName: '엔지스테크널러지',
    market: 'KOSDAQ',
    marketCap: 25233717915,
  },
  {
    id: 831,
    code: 183490,
    companyName: '엔지켐생명과학',
    market: 'KOSDAQ',
    marketCap: 783146430000,
  },
  {
    id: 832,
    code: 182400,
    companyName: '엔케이맥스',
    market: 'KOSDAQ',
    marketCap: 490475541150,
  },
  {
    id: 833,
    code: '069410',
    companyName: '엔텔스',
    market: 'KOSDAQ',
    marketCap: 81446350800,
  },
  {
    id: 834,
    code: 227950,
    companyName: '엔투텍',
    market: 'KOSDAQ',
    marketCap: 158493192300,
  },
  {
    id: 835,
    code: 198080,
    companyName: '엔피디',
    market: 'KOSDAQ',
    marketCap: 91309184400,
  },
  {
    id: 836,
    code: '048830',
    companyName: '엔피케이',
    market: 'KOSDAQ',
    marketCap: 45779991735,
  },
  {
    id: 837,
    code: '096870',
    companyName: '엘디티',
    market: 'KOSDAQ',
    marketCap: 35395520000,
  },
  {
    id: 838,
    code: 376190,
    companyName: '엘비루셈',
    market: 'KOSDAQ',
    marketCap: 353010000000,
  },
  {
    id: 839,
    code: '061970',
    companyName: '엘비세미콘',
    market: 'KOSDAQ',
    marketCap: 569199696000,
  },
  {
    id: 840,
    code: 138690,
    companyName: '엘아이에스',
    market: 'KOSDAQ',
    marketCap: 110990081100,
  },
  {
    id: 841,
    code: 290650,
    companyName: '엘앤씨바이오',
    market: 'KOSDAQ',
    marketCap: 680374948800,
  },
  {
    id: 842,
    code: '066970',
    companyName: '엘앤에프',
    market: 'KOSDAQ',
    marketCap: 2518661991300,
  },
  {
    id: 843,
    code: 156100,
    companyName: '엘앤케이바이오',
    market: 'KOSDAQ',
    marketCap: 328122203500,
  },
  {
    id: 844,
    code: '073110',
    companyName: '엘엠에스',
    market: 'KOSDAQ',
    marketCap: 98742880500,
  },
  {
    id: 845,
    code: '083310',
    companyName: '엘오티베큠',
    market: 'KOSDAQ',
    marketCap: 284960528000,
  },
  {
    id: 846,
    code: 297890,
    companyName: '엘이티',
    market: 'KOSDAQ',
    marketCap: 135790495000,
  },
  {
    id: 847,
    code: '037950',
    companyName: '엘컴텍',
    market: 'KOSDAQ',
    marketCap: 134271555210,
  },
  {
    id: 848,
    code: 170920,
    companyName: '엘티씨',
    market: 'KOSDAQ',
    marketCap: 103850750850,
  },
  {
    id: 849,
    code: '058630',
    companyName: '엠게임',
    market: 'KOSDAQ',
    marketCap: 164559444340,
  },
  {
    id: 850,
    code: '019590',
    companyName: '엠벤처투자',
    market: 'KOSDAQ',
    marketCap: 74731314680,
  },
  {
    id: 851,
    code: 169330,
    companyName: '엠브레인',
    market: 'KOSDAQ',
    marketCap: 77756539530,
  },
  {
    id: 852,
    code: '097520',
    companyName: '엠씨넥스',
    market: 'KOSDAQ',
    marketCap: 830651913000,
  },
  {
    id: 853,
    code: 179290,
    companyName: '엠아이텍',
    market: 'KOSDAQ',
    marketCap: 171819145480,
  },
  {
    id: 854,
    code: '009780',
    companyName: '엠에스씨',
    market: 'KOSDAQ',
    marketCap: 105600000000,
  },
  {
    id: 855,
    code: 123040,
    companyName: '엠에스오토텍',
    market: 'KOSDAQ',
    marketCap: 270177647120,
  },
  {
    id: 856,
    code: 323230,
    companyName: '엠에프엠코리아',
    market: 'KOSDAQ',
    marketCap: 68903425800,
  },
  {
    id: 857,
    code: '032790',
    companyName: '엠젠플러스',
    market: 'KOSDAQ',
    marketCap: 102014955875,
  },
  {
    id: 858,
    code: '033160',
    companyName: '엠케이전자',
    market: 'KOSDAQ',
    marketCap: 345651870650,
  },
  {
    id: 859,
    code: 347890,
    companyName: '엠투아이',
    market: 'KOSDAQ',
    marketCap: 171341680800,
  },
  {
    id: 860,
    code: '033310',
    companyName: '엠투엔',
    market: 'KOSDAQ',
    marketCap: 484847545150,
  },
  {
    id: 861,
    code: 259630,
    companyName: '엠플러스',
    market: 'KOSDAQ',
    marketCap: 169326174600,
  },
  {
    id: 862,
    code: 115960,
    companyName: '연우',
    market: 'KOSDAQ',
    marketCap: 366980800000,
  },
  {
    id: 863,
    code: '090740',
    companyName: '연이비앤티',
    market: 'KOSDAQ',
    marketCap: 83963499980,
  },
  {
    id: 864,
    code: '060850',
    companyName: '영림원소프트랩',
    market: 'KOSDAQ',
    marketCap: 100011300000,
  },
  {
    id: 865,
    code: '007530',
    companyName: '영신금속',
    market: 'KOSDAQ',
    marketCap: 43375431840,
  },
  {
    id: 866,
    code: 143540,
    companyName: '영우디에스피',
    market: 'KOSDAQ',
    marketCap: 118456704760,
  },
  {
    id: 867,
    code: '036560',
    companyName: '영풍정밀',
    market: 'KOSDAQ',
    marketCap: 149152500000,
  },
  {
    id: 868,
    code: 265560,
    companyName: '영화테크',
    market: 'KOSDAQ',
    marketCap: 86857712500,
  },
  {
    id: 869,
    code: '036000',
    companyName: '예림당',
    market: 'KOSDAQ',
    marketCap: 83614425510,
  },
  {
    id: 870,
    code: 250930,
    companyName: '예선테크',
    market: 'KOSDAQ',
    marketCap: 87121161600,
  },
  {
    id: 871,
    code: '053280',
    companyName: '예스24',
    market: 'KOSDAQ',
    marketCap: 266600000000,
  },
  {
    id: 872,
    code: 122640,
    companyName: '예스티',
    market: 'KOSDAQ',
    marketCap: 234340739400,
  },
  {
    id: 873,
    code: 900300,
    companyName: '오가닉티코스메틱',
    market: 'KOSDAQ',
    marketCap: 60481985340,
  },
  {
    id: 874,
    code: '045060',
    companyName: '오공',
    market: 'KOSDAQ',
    marketCap: 90639560900,
  },
  {
    id: 875,
    code: '080520',
    companyName: '오디텍',
    market: 'KOSDAQ',
    marketCap: 93150609370,
  },
  {
    id: 876,
    code: '039830',
    companyName: '오로라',
    market: 'KOSDAQ',
    marketCap: 117853645500,
  },
  {
    id: 877,
    code: 322310,
    companyName: '오로스테크놀로지',
    market: 'KOSDAQ',
    marketCap: 332816126500,
  },
  {
    id: 878,
    code: '046120',
    companyName: '오르비텍',
    market: 'KOSDAQ',
    marketCap: 218473762500,
  },
  {
    id: 879,
    code: '014940',
    companyName: '오리엔탈정공',
    market: 'KOSDAQ',
    marketCap: 247464979230,
  },
  {
    id: 880,
    code: '065500',
    companyName: '오리엔트정공',
    market: 'KOSDAQ',
    marketCap: 130108163820,
  },
  {
    id: 881,
    code: '010470',
    companyName: '오리콤',
    market: 'KOSDAQ',
    marketCap: 96537157500,
  },
  {
    id: 882,
    code: '053980',
    companyName: '오상자이엘',
    market: 'KOSDAQ',
    marketCap: 129204580960,
  },
  {
    id: 883,
    code: '052420',
    companyName: '오성첨단소재',
    market: 'KOSDAQ',
    marketCap: 254876035955,
  },
  {
    id: 884,
    code: 241790,
    companyName: '오션브릿지',
    market: 'KOSDAQ',
    marketCap: 188049519200,
  },
  {
    id: 885,
    code: '039200',
    companyName: '오스코텍',
    market: 'KOSDAQ',
    marketCap: 1055994522700,
  },
  {
    id: 886,
    code: 226400,
    companyName: '오스테오닉',
    market: 'KOSDAQ',
    marketCap: 69608174100,
  },
  {
    id: 887,
    code: '031510',
    companyName: '오스템',
    market: 'KOSDAQ',
    marketCap: 105280000000,
  },
  {
    id: 888,
    code: '048260',
    companyName: '오스템임플란트',
    market: 'KOSDAQ',
    marketCap: 1521428860500,
  },
  {
    id: 889,
    code: 138080,
    companyName: '오이솔루션',
    market: 'KOSDAQ',
    marketCap: 490833189000,
  },
  {
    id: 890,
    code: '080580',
    companyName: '오킨스전자',
    market: 'KOSDAQ',
    marketCap: 434910730800,
  },
  {
    id: 891,
    code: '067170',
    companyName: '오텍',
    market: 'KOSDAQ',
    marketCap: 204708346500,
  },
  {
    id: 892,
    code: 173130,
    companyName: '오파스넷',
    market: 'KOSDAQ',
    marketCap: 60369588700,
  },
  {
    id: 893,
    code: '049480',
    companyName: '오픈베이스',
    market: 'KOSDAQ',
    marketCap: 119719279230,
  },
  {
    id: 894,
    code: 309930,
    companyName: '오하임아이엔티',
    market: 'KOSDAQ',
    marketCap: 99725839550,
  },
  {
    id: 895,
    code: 244460,
    companyName: '올리패스',
    market: 'KOSDAQ',
    marketCap: 229625312960,
  },
  {
    id: 896,
    code: 226950,
    companyName: '올릭스',
    market: 'KOSDAQ',
    marketCap: 561829560000,
  },
  {
    id: 897,
    code: '057540',
    companyName: '옴니시스템',
    market: 'KOSDAQ',
    marketCap: 111013958450,
  },
  {
    id: 898,
    code: '082210',
    companyName: '옵트론텍',
    market: 'KOSDAQ',
    marketCap: 195326595750,
  },
  {
    id: 899,
    code: 109080,
    companyName: '옵티시스',
    market: 'KOSDAQ',
    marketCap: 89048800000,
  },
  {
    id: 900,
    code: 153710,
    companyName: '옵티팜',
    market: 'KOSDAQ',
    marketCap: 196228210100,
  },
  {
    id: 901,
    code: 321820,
    companyName: '와이더플래닛',
    market: 'KOSDAQ',
    marketCap: 161745952900,
  },
  {
    id: 902,
    code: 122990,
    companyName: '와이솔',
    market: 'KOSDAQ',
    marketCap: 359383497750,
  },
  {
    id: 903,
    code: 232140,
    companyName: '와이아이케이',
    market: 'KOSDAQ',
    marketCap: 535756135500,
  },
  {
    id: 904,
    code: '065530',
    companyName: '와이어블',
    market: 'KOSDAQ',
    marketCap: 234698400000,
  },
  {
    id: 905,
    code: '067900',
    companyName: '와이엔텍',
    market: 'KOSDAQ',
    marketCap: 293014509900,
  },
  {
    id: 906,
    code: 155650,
    companyName: '와이엠씨',
    market: 'KOSDAQ',
    marketCap: 157597684620,
  },
  {
    id: 907,
    code: 251370,
    companyName: '와이엠티',
    market: 'KOSDAQ',
    marketCap: 352937480800,
  },
  {
    id: 908,
    code: '066430',
    companyName: '와이오엠',
    market: 'KOSDAQ',
    marketCap: 53433830320,
  },
  {
    id: 909,
    code: 193250,
    companyName: '와이제이엠게임즈',
    market: 'KOSDAQ',
    marketCap: 120085473900,
  },
  {
    id: 910,
    code: 273060,
    companyName: '와이즈버즈',
    market: 'KOSDAQ',
    marketCap: 102714419520,
  },
  {
    id: 911,
    code: '019210',
    companyName: '와이지-원',
    market: 'KOSDAQ',
    marketCap: 288922589550,
  },
  {
    id: 912,
    code: 122870,
    companyName: '와이지엔터테인먼트',
    market: 'KOSDAQ',
    marketCap: 989898765300,
  },
  {
    id: 913,
    code: 332570,
    companyName: '와이팜',
    market: 'KOSDAQ',
    marketCap: 368442251520,
  },
  {
    id: 914,
    code: '079000',
    companyName: '와토스코리아',
    market: 'KOSDAQ',
    marketCap: 57600000000,
  },
  {
    id: 915,
    code: '032820',
    companyName: '우리기술',
    market: 'KOSDAQ',
    marketCap: 280457518500,
  },
  {
    id: 916,
    code: '041190',
    companyName: '우리기술투자',
    market: 'KOSDAQ',
    marketCap: 677040000000,
  },
  {
    id: 917,
    code: 115440,
    companyName: '우리넷',
    market: 'KOSDAQ',
    marketCap: 79856890560,
  },
  {
    id: 918,
    code: '046970',
    companyName: '우리로',
    market: 'KOSDAQ',
    marketCap: 53792275450,
  },
  {
    id: 919,
    code: '082850',
    companyName: '우리바이오',
    market: 'KOSDAQ',
    marketCap: 224308164270,
  },
  {
    id: 920,
    code: 215360,
    companyName: '우리산업',
    market: 'KOSDAQ',
    marketCap: 232870156500,
  },
  {
    id: 921,
    code: '072470',
    companyName: '우리산업홀딩스',
    market: 'KOSDAQ',
    marketCap: 113512919410,
  },
  {
    id: 922,
    code: '073560',
    companyName: '우리손에프앤지',
    market: 'KOSDAQ',
    marketCap: 179325495370,
  },
  {
    id: 923,
    code: 153490,
    companyName: '우리이앤엘',
    market: 'KOSDAQ',
    marketCap: 97878900000,
  },
  {
    id: 924,
    code: '037400',
    companyName: '우리조명',
    market: 'KOSDAQ',
    marketCap: 100193732290,
  },
  {
    id: 925,
    code: 101170,
    companyName: '우림기계',
    market: 'KOSDAQ',
    marketCap: 66825000000,
  },
  {
    id: 926,
    code: '066590',
    companyName: '우수AMS',
    market: 'KOSDAQ',
    marketCap: 224037849000,
  },
  {
    id: 927,
    code: 103840,
    companyName: '우양',
    market: 'KOSDAQ',
    marketCap: 85120260000,
  },
  {
    id: 928,
    code: '046940',
    companyName: '우원개발',
    market: 'KOSDAQ',
    marketCap: 134292420500,
  },
  {
    id: 929,
    code: 215380,
    companyName: '우정바이오',
    market: 'KOSDAQ',
    marketCap: 82110967540,
  },
  {
    id: 930,
    code: '065680',
    companyName: '우주일렉트로',
    market: 'KOSDAQ',
    marketCap: 259283142000,
  },
  {
    id: 931,
    code: '018620',
    companyName: '우진비앤지',
    market: 'KOSDAQ',
    marketCap: 78289984030,
  },
  {
    id: 932,
    code: 307280,
    companyName: '원바이오젠',
    market: 'KOSDAQ',
    marketCap: 85801788020,
  },
  {
    id: 933,
    code: '053080',
    companyName: '원방테크',
    market: 'KOSDAQ',
    marketCap: 194954092200,
  },
  {
    id: 934,
    code: '032940',
    companyName: '원익',
    market: 'KOSDAQ',
    marketCap: 99698900400,
  },
  {
    id: 935,
    code: 240810,
    companyName: '원익IPS',
    market: 'KOSDAQ',
    marketCap: 2429653099500,
  },
  {
    id: 936,
    code: '074600',
    companyName: '원익QnC',
    market: 'KOSDAQ',
    marketCap: 761037600000,
  },
  {
    id: 937,
    code: 104830,
    companyName: '원익머트리얼즈',
    market: 'KOSDAQ',
    marketCap: 443171200000,
  },
  {
    id: 938,
    code: '014190',
    companyName: '원익큐브',
    market: 'KOSDAQ',
    marketCap: 189035498040,
  },
  {
    id: 939,
    code: 131390,
    companyName: '원익피앤이',
    market: 'KOSDAQ',
    marketCap: 325275074500,
  },
  {
    id: 940,
    code: '030530',
    companyName: '원익홀딩스',
    market: 'KOSDAQ',
    marketCap: 515177333270,
  },
  {
    id: 941,
    code: '012620',
    companyName: '원일특강',
    market: 'KOSDAQ',
    marketCap: 66440000000,
  },
  {
    id: 942,
    code: '008370',
    companyName: '원풍',
    market: 'KOSDAQ',
    marketCap: 84840000000,
  },
  {
    id: 943,
    code: '008290',
    companyName: '원풍물산',
    market: 'KOSDAQ',
    marketCap: 78131863680,
  },
  {
    id: 944,
    code: 101160,
    companyName: '월덱스',
    market: 'KOSDAQ',
    marketCap: 422681420800,
  },
  {
    id: 945,
    code: '095270',
    companyName: '웨이브일렉트로',
    market: 'KOSDAQ',
    marketCap: 167666799600,
  },
  {
    id: 946,
    code: '065950',
    companyName: '웰크론',
    market: 'KOSDAQ',
    marketCap: 136498345170,
  },
  {
    id: 947,
    code: '076080',
    companyName: '웰크론한텍',
    market: 'KOSDAQ',
    marketCap: 90582468060,
  },
  {
    id: 948,
    code: 196700,
    companyName: '웹스',
    market: 'KOSDAQ',
    marketCap: 125204070640,
  },
  {
    id: 949,
    code: '069080',
    companyName: '웹젠',
    market: 'KOSDAQ',
    marketCap: 1131713832200,
  },
  {
    id: 950,
    code: '053580',
    companyName: '웹케시',
    market: 'KOSDAQ',
    marketCap: 504039763500,
  },
  {
    id: 951,
    code: '071460',
    companyName: '위니아딤채',
    market: 'KOSDAQ',
    marketCap: 193144374150,
  },
  {
    id: 952,
    code: '044340',
    companyName: '위닉스',
    market: 'KOSDAQ',
    marketCap: 420919158750,
  },
  {
    id: 953,
    code: 330350,
    companyName: '위더스제약',
    market: 'KOSDAQ',
    marketCap: 116059878000,
  },
  {
    id: 954,
    code: 348350,
    companyName: '위드텍',
    market: 'KOSDAQ',
    marketCap: 239823780000,
  },
  {
    id: 955,
    code: 112040,
    companyName: '위메이드',
    market: 'KOSDAQ',
    marketCap: 986160000000,
  },
  {
    id: 956,
    code: 101730,
    companyName: '위메이드맥스',
    market: 'KOSDAQ',
    marketCap: 99512500500,
  },
  {
    id: 957,
    code: '065370',
    companyName: '위세아이텍',
    market: 'KOSDAQ',
    marketCap: 87130330500,
  },
  {
    id: 958,
    code: '038620',
    companyName: '위즈코프',
    market: 'KOSDAQ',
    marketCap: 282492723600,
  },
  {
    id: 959,
    code: 299900,
    companyName: '위지윅스튜디오',
    market: 'KOSDAQ',
    marketCap: 444021696200,
  },
  {
    id: 960,
    code: '036090',
    companyName: '위지트',
    market: 'KOSDAQ',
    marketCap: 127677409775,
  },
  {
    id: 961,
    code: 136540,
    companyName: '윈스',
    market: 'KOSDAQ',
    marketCap: 213704102500,
  },
  {
    id: 962,
    code: 320000,
    companyName: '윈텍',
    market: 'KOSDAQ',
    marketCap: 54572486600,
  },
  {
    id: 963,
    code: '097800',
    companyName: '윈팩',
    market: 'KOSDAQ',
    marketCap: 94415611800,
  },
  {
    id: 964,
    code: 192390,
    companyName: '윈하이텍',
    market: 'KOSDAQ',
    marketCap: 52112221440,
  },
  {
    id: 965,
    code: 313760,
    companyName: '윌링스',
    market: 'KOSDAQ',
    marketCap: 67338700000,
  },
  {
    id: 966,
    code: 900340,
    companyName: '윙입푸드',
    market: 'KOSDAQ',
    marketCap: 75078414820,
  },
  {
    id: 967,
    code: '036200',
    companyName: '유니셈',
    market: 'KOSDAQ',
    marketCap: 413967010500,
  },
  {
    id: 968,
    code: '018000',
    companyName: '유니슨',
    market: 'KOSDAQ',
    marketCap: 502760391630,
  },
  {
    id: 969,
    code: 203450,
    companyName: '유니온커뮤니티',
    market: 'KOSDAQ',
    marketCap: 76330836400,
  },
  {
    id: 970,
    code: '011320',
    companyName: '유니크',
    market: 'KOSDAQ',
    marketCap: 169249288200,
  },
  {
    id: 971,
    code: '086390',
    companyName: '유니테스트',
    market: 'KOSDAQ',
    marketCap: 434306289300,
  },
  {
    id: 972,
    code: 241690,
    companyName: '유니테크노',
    market: 'KOSDAQ',
    marketCap: 164294906400,
  },
  {
    id: 973,
    code: 142210,
    companyName: '유니트론텍',
    market: 'KOSDAQ',
    marketCap: 87804464610,
  },
  {
    id: 974,
    code: '048430',
    companyName: '유라테크',
    market: 'KOSDAQ',
    marketCap: 143424000000,
  },
  {
    id: 975,
    code: 206650,
    companyName: '유바이오로직스',
    market: 'KOSDAQ',
    marketCap: 1339979294400,
  },
  {
    id: 976,
    code: '089850',
    companyName: '유비벨록스',
    market: 'KOSDAQ',
    marketCap: 85744148850,
  },
  {
    id: 977,
    code: '032620',
    companyName: '유비케어',
    market: 'KOSDAQ',
    marketCap: 473428050730,
  },
  {
    id: 978,
    code: 264450,
    companyName: '유비쿼스',
    market: 'KOSDAQ',
    marketCap: 241286376300,
  },
  {
    id: 979,
    code: '078070',
    companyName: '유비쿼스홀딩스',
    market: 'KOSDAQ',
    marketCap: 399173007800,
  },
  {
    id: 980,
    code: '024800',
    companyName: '유성티엔에스',
    market: 'KOSDAQ',
    marketCap: 124645292730,
  },
  {
    id: 981,
    code: '054930',
    companyName: '유신',
    market: 'KOSDAQ',
    marketCap: 74400000000,
  },
  {
    id: 982,
    code: '069330',
    companyName: '유아이디',
    market: 'KOSDAQ',
    marketCap: 32930825850,
  },
  {
    id: 983,
    code: '049520',
    companyName: '유아이엘',
    market: 'KOSDAQ',
    marketCap: 90734258440,
  },
  {
    id: 984,
    code: 313750,
    companyName: '유안타제4호스팩',
    market: 'KOSDAQ',
    marketCap: 10462500000,
  },
  {
    id: 985,
    code: 336060,
    companyName: '유안타제5호스팩',
    market: 'KOSDAQ',
    marketCap: 12897750000,
  },
  {
    id: 986,
    code: 340360,
    companyName: '유안타제6호스팩',
    market: 'KOSDAQ',
    marketCap: 22383150000,
  },
  {
    id: 987,
    code: 367460,
    companyName: '유안타제7호스팩',
    market: 'KOSDAQ',
    marketCap: 11715000000,
  },
  {
    id: 988,
    code: 367480,
    companyName: '유안타제8호스팩',
    market: 'KOSDAQ',
    marketCap: 13856150000,
  },
  {
    id: 989,
    code: '056090',
    companyName: '유앤아이',
    market: 'KOSDAQ',
    marketCap: 44774767110,
  },
  {
    id: 990,
    code: 263770,
    companyName: '유에스티',
    market: 'KOSDAQ',
    marketCap: 157606143800,
  },
  {
    id: 991,
    code: 340930,
    companyName: '유일에너테크',
    market: 'KOSDAQ',
    marketCap: 197504320600,
  },
  {
    id: 992,
    code: '023410',
    companyName: '유진기업',
    market: 'KOSDAQ',
    marketCap: 467730721150,
  },
  {
    id: 993,
    code: '056080',
    companyName: '유진로봇',
    market: 'KOSDAQ',
    marketCap: 154174944720,
  },
  {
    id: 994,
    code: 321260,
    companyName: '유진스팩4호',
    market: 'KOSDAQ',
    marketCap: 8061900000,
  },
  {
    id: 995,
    code: 331380,
    companyName: '유진스팩5호',
    market: 'KOSDAQ',
    marketCap: 8701700000,
  },
  {
    id: 996,
    code: 373340,
    companyName: '유진스팩6호',
    market: 'KOSDAQ',
    marketCap: 9467700000,
  },
  {
    id: 997,
    code: '084370',
    companyName: '유진테크',
    market: 'KOSDAQ',
    marketCap: 1055283734100,
  },
  {
    id: 998,
    code: 178780,
    companyName: '유테크',
    market: 'KOSDAQ',
    marketCap: 60240302320,
  },
  {
    id: 999,
    code: 179900,
    companyName: '유티아이',
    market: 'KOSDAQ',
    marketCap: 256068941000,
  },
  {
    id: 1000,
    code: 263050,
    companyName: '유틸렉스',
    market: 'KOSDAQ',
    marketCap: 491490483750,
  },
  {
    id: 1001,
    code: 191410,
    companyName: '육일씨엔에쓰',
    market: 'KOSDAQ',
    marketCap: 36188080030,
  },
  {
    id: 1002,
    code: '072770',
    companyName: '율호',
    market: 'KOSDAQ',
    marketCap: 151581645560,
  },
  {
    id: 1003,
    code: '039020',
    companyName: '이건홀딩스',
    market: 'KOSDAQ',
    marketCap: 117666333890,
  },
  {
    id: 1004,
    code: '060230',
    companyName: '이그잭스',
    market: 'KOSDAQ',
    marketCap: 157743894560,
  },
  {
    id: 1005,
    code: '067920',
    companyName: '이글루시큐리티',
    market: 'KOSDAQ',
    marketCap: 87419146050,
  },
  {
    id: 1006,
    code: '044960',
    companyName: '이글벳',
    market: 'KOSDAQ',
    marketCap: 105306885390,
  },
  {
    id: 1007,
    code: 302430,
    companyName: '이노메트리',
    market: 'KOSDAQ',
    marketCap: 167144565600,
  },
  {
    id: 1008,
    code: '073490',
    companyName: '이노와이어리스',
    market: 'KOSDAQ',
    marketCap: 346983516000,
  },
  {
    id: 1009,
    code: '086250',
    companyName: '이노와이즈',
    market: 'KOSDAQ',
    marketCap: 43288294470,
  },
  {
    id: 1010,
    code: 215790,
    companyName: '이노인스트루먼트',
    market: 'KOSDAQ',
    marketCap: 98290883560,
  },
  {
    id: 1011,
    code: 246960,
    companyName: '이노테라피',
    market: 'KOSDAQ',
    marketCap: 71375203600,
  },
  {
    id: 1012,
    code: '088390',
    companyName: '이녹스',
    market: 'KOSDAQ',
    marketCap: 178601120400,
  },
  {
    id: 1013,
    code: 272290,
    companyName: '이녹스첨단소재',
    market: 'KOSDAQ',
    marketCap: 652047794300,
  },
  {
    id: 1014,
    code: '053350',
    companyName: '이니텍',
    market: 'KOSDAQ',
    marketCap: 132994955520,
  },
  {
    id: 1015,
    code: 258610,
    companyName: '이더블유케이',
    market: 'KOSDAQ',
    marketCap: 104506070500,
  },
  {
    id: 1016,
    code: 215090,
    companyName: '이디티',
    market: 'KOSDAQ',
    marketCap: 93267310730,
  },
  {
    id: 1017,
    code: '041520',
    companyName: '이라이콤',
    market: 'KOSDAQ',
    marketCap: 137123212500,
  },
  {
    id: 1018,
    code: 264850,
    companyName: '이랜시스',
    market: 'KOSDAQ',
    marketCap: 105231631920,
  },
  {
    id: 1019,
    code: '054210',
    companyName: '이랜텍',
    market: 'KOSDAQ',
    marketCap: 179567591000,
  },
  {
    id: 1020,
    code: 164060,
    companyName: '이루다',
    market: 'KOSDAQ',
    marketCap: 109410840000,
  },
  {
    id: 1021,
    code: '065440',
    companyName: '이루온',
    market: 'KOSDAQ',
    marketCap: 104872451900,
  },
  {
    id: 1022,
    code: 115610,
    companyName: '이미지스',
    market: 'KOSDAQ',
    marketCap: 24771283440,
  },
  {
    id: 1023,
    code: 349720,
    companyName: '이베스트스팩5호',
    market: 'KOSDAQ',
    marketCap: 7692500000,
  },
  {
    id: 1024,
    code: 323210,
    companyName: '이베스트이안스팩1호',
    market: 'KOSDAQ',
    marketCap: 6864000000,
  },
  {
    id: 1025,
    code: '078020',
    companyName: '이베스트투자증권',
    market: 'KOSDAQ',
    marketCap: 468816055500,
  },
  {
    id: 1026,
    code: 131400,
    companyName: '이브이첨단소재',
    market: 'KOSDAQ',
    marketCap: 116912777250,
  },
  {
    id: 1027,
    code: 351330,
    companyName: '이삭엔지니어링',
    market: 'KOSDAQ',
    marketCap: 125571078000,
  },
  {
    id: 1028,
    code: '080010',
    companyName: '이상네트웍스',
    market: 'KOSDAQ',
    marketCap: 75816150000,
  },
  {
    id: 1029,
    code: '086890',
    companyName: '이수앱지스',
    market: 'KOSDAQ',
    marketCap: 482807819400,
  },
  {
    id: 1030,
    code: '047560',
    companyName: '이스트소프트',
    market: 'KOSDAQ',
    marketCap: 121127034700,
  },
  {
    id: 1031,
    code: 900110,
    companyName: '이스트아시아홀딩스',
    market: 'KOSDAQ',
    marketCap: 30470170528,
  },
  {
    id: 1032,
    code: '067010',
    companyName: '이씨에스',
    market: 'KOSDAQ',
    marketCap: 75362220000,
  },
  {
    id: 1033,
    code: '052190',
    companyName: '이에스에이',
    market: 'KOSDAQ',
    marketCap: 26981756100,
  },
  {
    id: 1034,
    code: 101360,
    companyName: '이엔드디',
    market: 'KOSDAQ',
    marketCap: 302387221900,
  },
  {
    id: 1035,
    code: 102710,
    companyName: '이엔에프테크놀로지',
    market: 'KOSDAQ',
    marketCap: 514362096000,
  },
  {
    id: 1036,
    code: '066980',
    companyName: '이엔코퍼레이션',
    market: 'KOSDAQ',
    marketCap: 223599746550,
  },
  {
    id: 1037,
    code: '063760',
    companyName: '이엘피',
    market: 'KOSDAQ',
    marketCap: 62851376200,
  },
  {
    id: 1038,
    code: '087730',
    companyName: '이엠네트웍스',
    market: 'KOSDAQ',
    marketCap: 26570510400,
  },
  {
    id: 1039,
    code: 123570,
    companyName: '이엠넷',
    market: 'KOSDAQ',
    marketCap: 155264263660,
  },
  {
    id: 1040,
    code: '083470',
    companyName: '이엠앤아이',
    market: 'KOSDAQ',
    marketCap: 35542856275,
  },
  {
    id: 1041,
    code: '095190',
    companyName: '이엠코리아',
    market: 'KOSDAQ',
    marketCap: 225679496100,
  },
  {
    id: 1042,
    code: '091120',
    companyName: '이엠텍',
    market: 'KOSDAQ',
    marketCap: 301870784000,
  },
  {
    id: 1043,
    code: '039030',
    companyName: '이오테크닉스',
    market: 'KOSDAQ',
    marketCap: 1425371935000,
  },
  {
    id: 1044,
    code: 294090,
    companyName: '이오플로우',
    market: 'KOSDAQ',
    marketCap: 637585438400,
  },
  {
    id: 1045,
    code: '088290',
    companyName: '이원컴포텍',
    market: 'KOSDAQ',
    marketCap: 196483127400,
  },
  {
    id: 1046,
    code: 181340,
    companyName: '이즈미디어',
    market: 'KOSDAQ',
    marketCap: 170522400000,
  },
  {
    id: 1047,
    code: 353810,
    companyName: '이지바이오',
    market: 'KOSDAQ',
    marketCap: 237974371200,
  },
  {
    id: 1048,
    code: '099750',
    companyName: '이지케어텍',
    market: 'KOSDAQ',
    marketCap: 193495800000,
  },
  {
    id: 1049,
    code: '035810',
    companyName: '이지홀딩스',
    market: 'KOSDAQ',
    marketCap: 357650689050,
  },
  {
    id: 1050,
    code: 160600,
    companyName: '이큐셀',
    market: 'KOSDAQ',
    marketCap: 18642222000,
  },
  {
    id: 1051,
    code: '092130',
    companyName: '이크레더블',
    market: 'KOSDAQ',
    marketCap: 263754840000,
  },
  {
    id: 1052,
    code: '096040',
    companyName: '이트론',
    market: 'KOSDAQ',
    marketCap: 413908412172,
  },
  {
    id: 1053,
    code: 134060,
    companyName: '이퓨쳐',
    market: 'KOSDAQ',
    marketCap: 54369450000,
  },
  {
    id: 1054,
    code: '001840',
    companyName: '이화공영',
    market: 'KOSDAQ',
    marketCap: 170527593600,
  },
  {
    id: 1055,
    code: '024810',
    companyName: '이화전기',
    market: 'KOSDAQ',
    marketCap: 193218287245,
  },
  {
    id: 1056,
    code: '041830',
    companyName: '인바디',
    market: 'KOSDAQ',
    marketCap: 415986972800,
  },
  {
    id: 1057,
    code: 352940,
    companyName: '인바이오',
    market: 'KOSDAQ',
    marketCap: 120631803000,
  },
  {
    id: 1058,
    code: '079950',
    companyName: '인베니아',
    market: 'KOSDAQ',
    marketCap: 65192000000,
  },
  {
    id: 1059,
    code: 277410,
    companyName: '인산가',
    market: 'KOSDAQ',
    marketCap: 85055436700,
  },
  {
    id: 1060,
    code: '060150',
    companyName: '인선이엔티',
    market: 'KOSDAQ',
    marketCap: 612311497800,
  },
  {
    id: 1061,
    code: '033230',
    companyName: '인성정보',
    market: 'KOSDAQ',
    marketCap: 92953584320,
  },
  {
    id: 1062,
    code: '037330',
    companyName: '인지디스플레',
    market: 'KOSDAQ',
    marketCap: 148770909360,
  },
  {
    id: 1063,
    code: '083640',
    companyName: '인콘',
    market: 'KOSDAQ',
    marketCap: 107752616205,
  },
  {
    id: 1064,
    code: 216050,
    companyName: '인크로스',
    market: 'KOSDAQ',
    marketCap: 459236289000,
  },
  {
    id: 1065,
    code: '049070',
    companyName: '인탑스',
    market: 'KOSDAQ',
    marketCap: 493640000000,
  },
  {
    id: 1066,
    code: 119610,
    companyName: '인터로조',
    market: 'KOSDAQ',
    marketCap: 334534060750,
  },
  {
    id: 1067,
    code: '017250',
    companyName: '인터엠',
    market: 'KOSDAQ',
    marketCap: 37987067935,
  },
  {
    id: 1068,
    code: '035080',
    companyName: '인터파크',
    market: 'KOSDAQ',
    marketCap: 490382544520,
  },
  {
    id: 1069,
    code: '051370',
    companyName: '인터플렉스',
    market: 'KOSDAQ',
    marketCap: 279929664000,
  },
  {
    id: 1070,
    code: '064290',
    companyName: '인텍플러스',
    market: 'KOSDAQ',
    marketCap: 288821760500,
  },
  {
    id: 1071,
    code: 189300,
    companyName: '인텔리안테크',
    market: 'KOSDAQ',
    marketCap: 482413364400,
  },
  {
    id: 1072,
    code: 150840,
    companyName: '인트로메딕',
    market: 'KOSDAQ',
    marketCap: 93692024930,
  },
  {
    id: 1073,
    code: '048530',
    companyName: '인트론바이오',
    market: 'KOSDAQ',
    marketCap: 769891857000,
  },
  {
    id: 1074,
    code: 175140,
    companyName: '인포마크',
    market: 'KOSDAQ',
    marketCap: 42776247840,
  },
  {
    id: 1075,
    code: 115310,
    companyName: '인포바인',
    market: 'KOSDAQ',
    marketCap: 72638088250,
  },
  {
    id: 1076,
    code: '039290',
    companyName: '인포뱅크',
    market: 'KOSDAQ',
    marketCap: 121715692000,
  },
  {
    id: 1077,
    code: '071200',
    companyName: '인피니트헬스케어',
    market: 'KOSDAQ',
    marketCap: 214444865820,
  },
  {
    id: 1078,
    code: '016670',
    companyName: '인피니티엔티',
    market: 'KOSDAQ',
    marketCap: 84222290362,
  },
  {
    id: 1079,
    code: 101930,
    companyName: '인화정공',
    market: 'KOSDAQ',
    marketCap: 116646312500,
  },
  {
    id: 1080,
    code: 333430,
    companyName: '일승',
    market: 'KOSDAQ',
    marketCap: 108158149440,
  },
  {
    id: 1081,
    code: '068330',
    companyName: '일신바이오',
    market: 'KOSDAQ',
    marketCap: 274582229400,
  },
  {
    id: 1082,
    code: '058450',
    companyName: '일야',
    market: 'KOSDAQ',
    marketCap: 21741900000,
  },
  {
    id: 1083,
    code: '019540',
    companyName: '일지테크',
    market: 'KOSDAQ',
    marketCap: 62905342500,
  },
  {
    id: 1084,
    code: '094820',
    companyName: '일진파워',
    market: 'KOSDAQ',
    marketCap: 161342186300,
  },
  {
    id: 1085,
    code: 950140,
    companyName: '잉글우드랩',
    market: 'KOSDAQ',
    marketCap: 160134999960,
  },
  {
    id: 1086,
    code: '049550',
    companyName: '잉크테크',
    market: 'KOSDAQ',
    marketCap: 170278942460,
  },
  {
    id: 1087,
    code: 254120,
    companyName: '자비스',
    market: 'KOSDAQ',
    marketCap: 54457342735,
  },
  {
    id: 1088,
    code: 221610,
    companyName: '자안바이오',
    market: 'KOSDAQ',
    marketCap: 82812907500,
  },
  {
    id: 1089,
    code: 219550,
    companyName: '자안코스메틱',
    market: 'KOSDAQ',
    marketCap: 63542976560,
  },
  {
    id: 1090,
    code: '043910',
    companyName: '자연과환경',
    market: 'KOSDAQ',
    marketCap: 166991996080,
  },
  {
    id: 1091,
    code: 234920,
    companyName: '자이글',
    market: 'KOSDAQ',
    marketCap: 67925168200,
  },
  {
    id: 1092,
    code: 289220,
    companyName: '자이언트스텝',
    market: 'KOSDAQ',
    marketCap: 469127214500,
  },
  {
    id: 1093,
    code: 174880,
    companyName: '장원테크',
    market: 'KOSDAQ',
    marketCap: 62798818005,
  },
  {
    id: 1094,
    code: '049630',
    companyName: '재영솔루텍',
    market: 'KOSDAQ',
    marketCap: 80543296590,
  },
  {
    id: 1095,
    code: 110020,
    companyName: '전진바이오팜',
    market: 'KOSDAQ',
    marketCap: 56734689870,
  },
  {
    id: 1096,
    code: 208140,
    companyName: '정다운',
    market: 'KOSDAQ',
    marketCap: 136787909920,
  },
  {
    id: 1097,
    code: '022220',
    companyName: '정산애강',
    market: 'KOSDAQ',
    marketCap: 128968501710,
  },
  {
    id: 1098,
    code: '040420',
    companyName: '정상제이엘에스',
    market: 'KOSDAQ',
    marketCap: 117738415520,
  },
  {
    id: 1099,
    code: '045510',
    companyName: '정원엔시스',
    market: 'KOSDAQ',
    marketCap: 114020893680,
  },
  {
    id: 1100,
    code: '065620',
    companyName: '제낙스',
    market: 'KOSDAQ',
    marketCap: 78982709300,
  },
  {
    id: 1101,
    code: 217190,
    companyName: '제너셈',
    market: 'KOSDAQ',
    marketCap: 83833303440,
  },
  {
    id: 1102,
    code: '095700',
    companyName: '제넥신',
    market: 'KOSDAQ',
    marketCap: 2089603112400,
  },
  {
    id: 1103,
    code: '072520',
    companyName: '제넨바이오',
    market: 'KOSDAQ',
    marketCap: 327512585400,
  },
  {
    id: 1104,
    code: 122310,
    companyName: '제노레이',
    market: 'KOSDAQ',
    marketCap: 152815362000,
  },
  {
    id: 1105,
    code: 361390,
    companyName: '제노코',
    market: 'KOSDAQ',
    marketCap: 113071082000,
  },
  {
    id: 1106,
    code: 187420,
    companyName: '제노포커스',
    market: 'KOSDAQ',
    marketCap: 162618264600,
  },
  {
    id: 1107,
    code: 225220,
    companyName: '제놀루션',
    market: 'KOSDAQ',
    marketCap: 151161444000,
  },
  {
    id: 1108,
    code: 123330,
    companyName: '제닉',
    market: 'KOSDAQ',
    marketCap: 57400000000,
  },
  {
    id: 1109,
    code: 159580,
    companyName: '제로투세븐',
    market: 'KOSDAQ',
    marketCap: 263429163400,
  },
  {
    id: 1110,
    code: 147830,
    companyName: '제룡산업',
    market: 'KOSDAQ',
    marketCap: 88000000000,
  },
  {
    id: 1111,
    code: '033100',
    companyName: '제룡전기',
    market: 'KOSDAQ',
    marketCap: 118379954330,
  },
  {
    id: 1112,
    code: '079370',
    companyName: '제우스',
    market: 'KOSDAQ',
    marketCap: 254927200000,
  },
  {
    id: 1113,
    code: '054950',
    companyName: '제이브이엠',
    market: 'KOSDAQ',
    marketCap: 209759626200,
  },
  {
    id: 1114,
    code: '090470',
    companyName: '제이스텍',
    market: 'KOSDAQ',
    marketCap: 110943340800,
  },
  {
    id: 1115,
    code: 287410,
    companyName: '제이시스메디칼',
    market: 'KOSDAQ',
    marketCap: 457711378560,
  },
  {
    id: 1116,
    code: 137950,
    companyName: '제이씨케미칼',
    market: 'KOSDAQ',
    marketCap: 260533423800,
  },
  {
    id: 1117,
    code: '033320',
    companyName: '제이씨현시스템',
    market: 'KOSDAQ',
    marketCap: 154635754880,
  },
  {
    id: 1118,
    code: 204270,
    companyName: '제이앤티씨',
    market: 'KOSDAQ',
    marketCap: 553031334960,
  },
  {
    id: 1119,
    code: '026040',
    companyName: '제이에스티나',
    market: 'KOSDAQ',
    marketCap: 58918530300,
  },
  {
    id: 1120,
    code: 126880,
    companyName: '제이엔케이히터',
    market: 'KOSDAQ',
    marketCap: 165218909360,
  },
  {
    id: 1121,
    code: 322510,
    companyName: '제이엘케이',
    market: 'KOSDAQ',
    marketCap: 115526736180,
  },
  {
    id: 1122,
    code: '033050',
    companyName: '제이엠아이',
    market: 'KOSDAQ',
    marketCap: 46588459060,
  },
  {
    id: 1123,
    code: '094970',
    companyName: '제이엠티',
    market: 'KOSDAQ',
    marketCap: 65485618400,
  },
  {
    id: 1124,
    code: '058420',
    companyName: '제이웨이',
    market: 'KOSDAQ',
    marketCap: 7799412240,
  },
  {
    id: 1125,
    code: '089790',
    companyName: '제이티',
    market: 'KOSDAQ',
    marketCap: 81389397570,
  },
  {
    id: 1126,
    code: '052670',
    companyName: '제일바이오',
    market: 'KOSDAQ',
    marketCap: 94378167360,
  },
  {
    id: 1127,
    code: 199820,
    companyName: '제일전기공업',
    market: 'KOSDAQ',
    marketCap: 247753000000,
  },
  {
    id: 1128,
    code: '023440',
    companyName: '제일제강',
    market: 'KOSDAQ',
    marketCap: 115509742675,
  },
  {
    id: 1129,
    code: '038010',
    companyName: '제일테크노스',
    market: 'KOSDAQ',
    marketCap: 73890000000,
  },
  {
    id: 1130,
    code: 276730,
    companyName: '제주맥주',
    market: 'KOSDAQ',
    marketCap: 250861587200,
  },
  {
    id: 1131,
    code: '080220',
    companyName: '제주반도체',
    market: 'KOSDAQ',
    marketCap: 210896913920,
  },
  {
    id: 1132,
    code: 216080,
    companyName: '제테마',
    market: 'KOSDAQ',
    marketCap: 625806744500,
  },
  {
    id: 1133,
    code: 229000,
    companyName: '젠큐릭스',
    market: 'KOSDAQ',
    marketCap: 145831706900,
  },
  {
    id: 1134,
    code: '082270',
    companyName: '젬백스',
    market: 'KOSDAQ',
    marketCap: 843718850800,
  },
  {
    id: 1135,
    code: '064800',
    companyName: '젬백스링크',
    market: 'KOSDAQ',
    marketCap: 170346297750,
  },
  {
    id: 1136,
    code: '041590',
    companyName: '젬백스지오',
    market: 'KOSDAQ',
    marketCap: 81152272725,
  },
  {
    id: 1137,
    code: '044060',
    companyName: '조광ILI',
    market: 'KOSDAQ',
    marketCap: 130255129620,
  },
  {
    id: 1138,
    code: '034940',
    companyName: '조아제약',
    market: 'KOSDAQ',
    marketCap: 130734869940,
  },
  {
    id: 1139,
    code: '067000',
    companyName: '조이시티',
    market: 'KOSDAQ',
    marketCap: 467786873400,
  },
  {
    id: 1140,
    code: '033340',
    companyName: '좋은사람들',
    market: 'KOSDAQ',
    marketCap: 52653176320,
  },
  {
    id: 1141,
    code: '036930',
    companyName: '주성엔지니어링',
    market: 'KOSDAQ',
    marketCap: 699613574000,
  },
  {
    id: 1142,
    code: 239340,
    companyName: '줌인터넷',
    market: 'KOSDAQ',
    marketCap: 168892508840,
  },
  {
    id: 1143,
    code: '072020',
    companyName: '중앙백신',
    market: 'KOSDAQ',
    marketCap: 185754000000,
  },
  {
    id: 1144,
    code: '000440',
    companyName: '중앙에너비스',
    market: 'KOSDAQ',
    marketCap: 128590234500,
  },
  {
    id: 1145,
    code: 228760,
    companyName: '지노믹트리',
    market: 'KOSDAQ',
    marketCap: 260820105750,
  },
  {
    id: 1146,
    code: 314130,
    companyName: '지놈앤컴퍼니',
    market: 'KOSDAQ',
    marketCap: 495539085250,
  },
  {
    id: 1147,
    code: '043610',
    companyName: '지니뮤직',
    market: 'KOSDAQ',
    marketCap: 402158830960,
  },
  {
    id: 1148,
    code: 263860,
    companyName: '지니언스',
    market: 'KOSDAQ',
    marketCap: 72817866000,
  },
  {
    id: 1149,
    code: 303030,
    companyName: '지니틱스',
    market: 'KOSDAQ',
    marketCap: 127592189865,
  },
  {
    id: 1150,
    code: '036180',
    companyName: '지더블유바이텍',
    market: 'KOSDAQ',
    marketCap: 120999704625,
  },
  {
    id: 1151,
    code: 208350,
    companyName: '지란지교시큐리티',
    market: 'KOSDAQ',
    marketCap: 67116851280,
  },
  {
    id: 1152,
    code: 114570,
    companyName: '지스마트글로벌',
    market: 'KOSDAQ',
    marketCap: 7030424280,
  },
  {
    id: 1153,
    code: '051160',
    companyName: '지어소프트',
    market: 'KOSDAQ',
    marketCap: 327198759750,
  },
  {
    id: 1154,
    code: '053050',
    companyName: '지에스이',
    market: 'KOSDAQ',
    marketCap: 64773209520,
  },
  {
    id: 1155,
    code: 119850,
    companyName: '지엔씨에너지',
    market: 'KOSDAQ',
    marketCap: 92771846760,
  },
  {
    id: 1156,
    code: 270520,
    companyName: '지엔원에너지',
    market: 'KOSDAQ',
    marketCap: 155264317300,
  },
  {
    id: 1157,
    code: '065060',
    companyName: '지엔코',
    market: 'KOSDAQ',
    marketCap: 91637521140,
  },
  {
    id: 1158,
    code: 204840,
    companyName: '지엘팜텍',
    market: 'KOSDAQ',
    marketCap: 72012908800,
  },
  {
    id: 1159,
    code: 111820,
    companyName: '지유온',
    market: 'KOSDAQ',
    marketCap: 72576466950,
  },
  {
    id: 1160,
    code: 115450,
    companyName: '지트리비앤티',
    market: 'KOSDAQ',
    marketCap: 299322609950,
  },
  {
    id: 1161,
    code: 219750,
    companyName: '지티지웰니스',
    market: 'KOSDAQ',
    marketCap: 77598046015,
  },
  {
    id: 1162,
    code: '018120',
    companyName: '진로발효',
    market: 'KOSDAQ',
    marketCap: 221145408000,
  },
  {
    id: 1163,
    code: 109820,
    companyName: '진매트릭스',
    market: 'KOSDAQ',
    marketCap: 227389086000,
  },
  {
    id: 1164,
    code: '086060',
    companyName: '진바이오텍',
    market: 'KOSDAQ',
    marketCap: 65009931850,
  },
  {
    id: 1165,
    code: '036890',
    companyName: '진성티이씨',
    market: 'KOSDAQ',
    marketCap: 286648917000,
  },
  {
    id: 1166,
    code: 363250,
    companyName: '진시스템',
    market: 'KOSDAQ',
    marketCap: 101144538000,
  },
  {
    id: 1167,
    code: '007370',
    companyName: '진양제약',
    market: 'KOSDAQ',
    marketCap: 80040000000,
  },
  {
    id: 1168,
    code: '085660',
    companyName: '차바이오텍',
    market: 'KOSDAQ',
    marketCap: 1312237860200,
  },
  {
    id: 1169,
    code: '094850',
    companyName: '참좋은여행',
    market: 'KOSDAQ',
    marketCap: 225400000000,
  },
  {
    id: 1170,
    code: '004650',
    companyName: '창해에탄올',
    market: 'KOSDAQ',
    marketCap: 132348744000,
  },
  {
    id: 1171,
    code: 311690,
    companyName: '천랩',
    market: 'KOSDAQ',
    marketCap: 123160212000,
  },
  {
    id: 1172,
    code: 278280,
    companyName: '천보',
    market: 'KOSDAQ',
    marketCap: 1856000000000,
  },
  {
    id: 1173,
    code: '096240',
    companyName: '청담러닝',
    market: 'KOSDAQ',
    marketCap: 257473305700,
  },
  {
    id: 1174,
    code: '013720',
    companyName: '청보산업',
    market: 'KOSDAQ',
    marketCap: 200788828050,
  },
  {
    id: 1175,
    code: '066360',
    companyName: '체리부로',
    market: 'KOSDAQ',
    marketCap: 70238382480,
  },
  {
    id: 1176,
    code: '047820',
    companyName: '초록뱀미디어',
    market: 'KOSDAQ',
    marketCap: 410372337500,
  },
  {
    id: 1177,
    code: '052300',
    companyName: '초록뱀컴퍼니',
    market: 'KOSDAQ',
    marketCap: 114929321215,
  },
  {
    id: 1178,
    code: '094360',
    companyName: '칩스앤미디어',
    market: 'KOSDAQ',
    marketCap: 148942866750,
  },
  {
    id: 1179,
    code: '016920',
    companyName: '카스',
    market: 'KOSDAQ',
    marketCap: 161147817540,
  },
  {
    id: 1180,
    code: 284620,
    companyName: '카이노스메드',
    market: 'KOSDAQ',
    marketCap: 394660530000,
  },
  {
    id: 1181,
    code: 293490,
    companyName: '카카오게임즈',
    market: 'KOSDAQ',
    marketCap: 4171256882000,
  },
  {
    id: 1182,
    code: '042000',
    companyName: '카페24',
    market: 'KOSDAQ',
    marketCap: 579168039750,
  },
  {
    id: 1183,
    code: 317530,
    companyName: '캐리소프트',
    market: 'KOSDAQ',
    marketCap: 136914219000,
  },
  {
    id: 1184,
    code: '071850',
    companyName: '캐스텍코리아',
    market: 'KOSDAQ',
    marketCap: 77709491720,
  },
  {
    id: 1185,
    code: 180400,
    companyName: '캔서롭',
    market: 'KOSDAQ',
    marketCap: 178624199880,
  },
  {
    id: 1186,
    code: '050110',
    companyName: '캠시스',
    market: 'KOSDAQ',
    marketCap: 157992101630,
  },
  {
    id: 1187,
    code: 900310,
    companyName: '컬러레이',
    market: 'KOSDAQ',
    marketCap: 120690000000,
  },
  {
    id: 1188,
    code: '078340',
    companyName: '컴투스',
    market: 'KOSDAQ',
    marketCap: 1698367440000,
  },
  {
    id: 1189,
    code: 307930,
    companyName: '컴퍼니케이',
    market: 'KOSDAQ',
    marketCap: 164685500000,
  },
  {
    id: 1190,
    code: 263700,
    companyName: '케어랩스',
    market: 'KOSDAQ',
    marketCap: 186149145600,
  },
  {
    id: 1191,
    code: 214370,
    companyName: '케어젠',
    market: 'KOSDAQ',
    marketCap: 747712800000,
  },
  {
    id: 1192,
    code: 221980,
    companyName: '케이디켐',
    market: 'KOSDAQ',
    marketCap: 69398560000,
  },
  {
    id: 1193,
    code: '043290',
    companyName: '케이맥',
    market: 'KOSDAQ',
    marketCap: 129179480705,
  },
  {
    id: 1194,
    code: 317030,
    companyName: '케이비17호스팩',
    market: 'KOSDAQ',
    marketCap: 11604625000,
  },
  {
    id: 1195,
    code: 323940,
    companyName: '케이비제18호스팩',
    market: 'KOSDAQ',
    marketCap: 16582640000,
  },
  {
    id: 1196,
    code: 330990,
    companyName: '케이비제19호스팩',
    market: 'KOSDAQ',
    marketCap: 9118120000,
  },
  {
    id: 1197,
    code: 342550,
    companyName: '케이비제20호스팩',
    market: 'KOSDAQ',
    marketCap: 13847130000,
  },
  {
    id: 1198,
    code: 192250,
    companyName: '케이사인',
    market: 'KOSDAQ',
    marketCap: 221201034410,
  },
  {
    id: 1199,
    code: 115500,
    companyName: '케이씨에스',
    market: 'KOSDAQ',
    marketCap: 112920000000,
  },
  {
    id: 1200,
    code: '089150',
    companyName: '케이씨티',
    market: 'KOSDAQ',
    marketCap: 163096500000,
  },
  {
    id: 1201,
    code: '025880',
    companyName: '케이씨피드',
    market: 'KOSDAQ',
    marketCap: 66779852710,
  },
  {
    id: 1202,
    code: '093320',
    companyName: '케이아이엔엑스',
    market: 'KOSDAQ',
    marketCap: 308416000000,
  },
  {
    id: 1203,
    code: '073010',
    companyName: '케이에스피',
    market: 'KOSDAQ',
    marketCap: 86137571660,
  },
  {
    id: 1204,
    code: 105330,
    companyName: '케이엔더블유',
    market: 'KOSDAQ',
    marketCap: 120747050900,
  },
  {
    id: 1205,
    code: 272110,
    companyName: '케이엔제이',
    market: 'KOSDAQ',
    marketCap: 128831744600,
  },
  {
    id: 1206,
    code: '039420',
    companyName: '케이엘넷',
    market: 'KOSDAQ',
    marketCap: 115942704000,
  },
  {
    id: 1207,
    code: '083550',
    companyName: '케이엠',
    market: 'KOSDAQ',
    marketCap: 110239918500,
  },
  {
    id: 1208,
    code: '032500',
    companyName: '케이엠더블유',
    market: 'KOSDAQ',
    marketCap: 2162273946900,
  },
  {
    id: 1209,
    code: 225430,
    companyName: '케이엠제약',
    market: 'KOSDAQ',
    marketCap: 85055502500,
  },
  {
    id: 1210,
    code: '064820',
    companyName: '케이프',
    market: 'KOSDAQ',
    marketCap: 169650486720,
  },
  {
    id: 1211,
    code: 347140,
    companyName: '케이프이에스제4호',
    market: 'KOSDAQ',
    marketCap: 6905655000,
  },
  {
    id: 1212,
    code: 256940,
    companyName: '케이피에스',
    market: 'KOSDAQ',
    marketCap: 311447002200,
  },
  {
    id: 1213,
    code: '024880',
    companyName: '케이피에프',
    market: 'KOSDAQ',
    marketCap: 91248987500,
  },
  {
    id: 1214,
    code: '042040',
    companyName: '케이피엠테크',
    market: 'KOSDAQ',
    marketCap: 406708316300,
  },
  {
    id: 1215,
    code: '054410',
    companyName: '케이피티유',
    market: 'KOSDAQ',
    marketCap: 59455000000,
  },
  {
    id: 1216,
    code: 274090,
    companyName: '켄코아에어로스페이스',
    market: 'KOSDAQ',
    marketCap: 192080634400,
  },
  {
    id: 1217,
    code: 217600,
    companyName: '켐온',
    market: 'KOSDAQ',
    marketCap: 252252293000,
  },
  {
    id: 1218,
    code: '089010',
    companyName: '켐트로닉스',
    market: 'KOSDAQ',
    marketCap: 367739385750,
  },
  {
    id: 1219,
    code: 220260,
    companyName: '켐트로스',
    market: 'KOSDAQ',
    marketCap: 164395920330,
  },
  {
    id: 1220,
    code: '052400',
    companyName: '코나아이',
    market: 'KOSDAQ',
    marketCap: 677599588900,
  },
  {
    id: 1221,
    code: '033110',
    companyName: '코너스톤네트웍스',
    market: 'KOSDAQ',
    marketCap: 26473567764,
  },
  {
    id: 1222,
    code: '046070',
    companyName: '코다코',
    market: 'KOSDAQ',
    marketCap: 58010420160,
  },
  {
    id: 1223,
    code: '047770',
    companyName: '코데즈컴바인',
    market: 'KOSDAQ',
    marketCap: 114663084060,
  },
  {
    id: 1224,
    code: '078940',
    companyName: '코드네이처',
    market: 'KOSDAQ',
    marketCap: 67540139950,
  },
  {
    id: 1225,
    code: '080530',
    companyName: '코디',
    market: 'KOSDAQ',
    marketCap: 58956976250,
  },
  {
    id: 1226,
    code: 224060,
    companyName: '코디엠',
    market: 'KOSDAQ',
    marketCap: 70546651432,
  },
  {
    id: 1227,
    code: '078650',
    companyName: '코렌',
    market: 'KOSDAQ',
    marketCap: 168299910120,
  },
  {
    id: 1228,
    code: 104540,
    companyName: '코렌텍',
    market: 'KOSDAQ',
    marketCap: 197412954900,
  },
  {
    id: 1229,
    code: '027050',
    companyName: '코리아나',
    market: 'KOSDAQ',
    marketCap: 206000000000,
  },
  {
    id: 1230,
    code: 290510,
    companyName: '코리아센터',
    market: 'KOSDAQ',
    marketCap: 622559204290,
  },
  {
    id: 1231,
    code: 190650,
    companyName: '코리아에셋투자증권',
    market: 'KOSDAQ',
    marketCap: 76656000000,
  },
  {
    id: 1232,
    code: 101670,
    companyName: '코리아에스이',
    market: 'KOSDAQ',
    marketCap: 47628000000,
  },
  {
    id: 1233,
    code: 123410,
    companyName: '코리아에프티',
    market: 'KOSDAQ',
    marketCap: 119855780520,
  },
  {
    id: 1234,
    code: '036690',
    companyName: '코맥스',
    market: 'KOSDAQ',
    marketCap: 140337698580,
  },
  {
    id: 1235,
    code: '049430',
    companyName: '코메론',
    market: 'KOSDAQ',
    marketCap: 149744400000,
  },
  {
    id: 1236,
    code: 183300,
    companyName: '코미코',
    market: 'KOSDAQ',
    marketCap: 667361770200,
  },
  {
    id: 1237,
    code: '041960',
    companyName: '코미팜',
    market: 'KOSDAQ',
    marketCap: 712463223600,
  },
  {
    id: 1238,
    code: '089890',
    companyName: '코세스',
    market: 'KOSDAQ',
    marketCap: 144289169400,
  },
  {
    id: 1239,
    code: '009730',
    companyName: '코센',
    market: 'KOSDAQ',
    marketCap: 26398999325,
  },
  {
    id: 1240,
    code: '082660',
    companyName: '코스나인',
    market: 'KOSDAQ',
    marketCap: 51025852240,
  },
  {
    id: 1241,
    code: 222040,
    companyName: '코스맥스엔비티',
    market: 'KOSDAQ',
    marketCap: 240316200000,
  },
  {
    id: 1242,
    code: 241710,
    companyName: '코스메카코리아',
    market: 'KOSDAQ',
    marketCap: 201852000000,
  },
  {
    id: 1243,
    code: '069110',
    companyName: '코스온',
    market: 'KOSDAQ',
    marketCap: 62963935800,
  },
  {
    id: 1244,
    code: 166480,
    companyName: '코아스템',
    market: 'KOSDAQ',
    marketCap: 296273744000,
  },
  {
    id: 1245,
    code: '045970',
    companyName: '코아시아',
    market: 'KOSDAQ',
    marketCap: 274990454200,
  },
  {
    id: 1246,
    code: 196450,
    companyName: '코아시아옵틱스',
    market: 'KOSDAQ',
    marketCap: 99782954920,
  },
  {
    id: 1247,
    code: '029960',
    companyName: '코엔텍',
    market: 'KOSDAQ',
    marketCap: 477000000000,
  },
  {
    id: 1248,
    code: 102940,
    companyName: '코오롱생명과학',
    market: 'KOSDAQ',
    marketCap: 286450135600,
  },
  {
    id: 1249,
    code: 950160,
    companyName: '코오롱티슈진',
    market: 'KOSDAQ',
    marketCap: 489551575500,
  },
  {
    id: 1250,
    code: '033290',
    companyName: '코웰패션',
    market: 'KOSDAQ',
    marketCap: 563746490580,
  },
  {
    id: 1251,
    code: '056360',
    companyName: '코위버',
    market: 'KOSDAQ',
    marketCap: 90424464000,
  },
  {
    id: 1252,
    code: 282880,
    companyName: '코윈테크',
    market: 'KOSDAQ',
    marketCap: 223611132800,
  },
  {
    id: 1253,
    code: 121850,
    companyName: '코이즈',
    market: 'KOSDAQ',
    marketCap: 77031391780,
  },
  {
    id: 1254,
    code: '015710',
    companyName: '코콤',
    market: 'KOSDAQ',
    marketCap: 164260785000,
  },
  {
    id: 1255,
    code: '052330',
    companyName: '코텍',
    market: 'KOSDAQ',
    marketCap: 179886075600,
  },
  {
    id: 1256,
    code: 322780,
    companyName: '코퍼스코리아',
    market: 'KOSDAQ',
    marketCap: 106690646140,
  },
  {
    id: 1257,
    code: 126600,
    companyName: '코프라',
    market: 'KOSDAQ',
    marketCap: 135984682960,
  },
  {
    id: 1258,
    code: 200130,
    companyName: '콜마비앤에이치',
    market: 'KOSDAQ',
    marketCap: 1506721458000,
  },
  {
    id: 1259,
    code: 294570,
    companyName: '쿠콘',
    market: 'KOSDAQ',
    marketCap: 488669037000,
  },
  {
    id: 1260,
    code: 317690,
    companyName: '퀀타매트릭스',
    market: 'KOSDAQ',
    marketCap: 208218136000,
  },
  {
    id: 1261,
    code: '060280',
    companyName: '큐렉소',
    market: 'KOSDAQ',
    marketCap: 331030428300,
  },
  {
    id: 1262,
    code: '040350',
    companyName: '큐로컴',
    market: 'KOSDAQ',
    marketCap: 173843391360,
  },
  {
    id: 1263,
    code: '051780',
    companyName: '큐로홀딩스',
    market: 'KOSDAQ',
    marketCap: 78993520725,
  },
  {
    id: 1264,
    code: 115180,
    companyName: '큐리언트',
    market: 'KOSDAQ',
    marketCap: 269168898600,
  },
  {
    id: 1265,
    code: '043090',
    companyName: '큐브앤컴퍼니',
    market: 'KOSDAQ',
    marketCap: 81638734065,
  },
  {
    id: 1266,
    code: 182360,
    companyName: '큐브엔터',
    market: 'KOSDAQ',
    marketCap: 159649625750,
  },
  {
    id: 1267,
    code: '066310',
    companyName: '큐에스아이',
    market: 'KOSDAQ',
    marketCap: 136590795000,
  },
  {
    id: 1268,
    code: '016600',
    companyName: '큐캐피탈',
    market: 'KOSDAQ',
    marketCap: 114341209563,
  },
  {
    id: 1269,
    code: '043590',
    companyName: '크로바하이텍',
    market: 'KOSDAQ',
    marketCap: 73965137855,
  },
  {
    id: 1270,
    code: 114120,
    companyName: '크루셜텍',
    market: 'KOSDAQ',
    marketCap: 28507388400,
  },
  {
    id: 1271,
    code: 110790,
    companyName: '크리스에프앤씨',
    market: 'KOSDAQ',
    marketCap: 383681970000,
  },
  {
    id: 1272,
    code: 900250,
    companyName: '크리스탈신소재',
    market: 'KOSDAQ',
    marketCap: 112860113580,
  },
  {
    id: 1273,
    code: '083790',
    companyName: '크리스탈지노믹스',
    market: 'KOSDAQ',
    marketCap: 556049282580,
  },
  {
    id: 1274,
    code: '045520',
    companyName: '크린앤사이언스',
    market: 'KOSDAQ',
    marketCap: 149825000000,
  },
  {
    id: 1275,
    code: '036170',
    companyName: '클라우드에어',
    market: 'KOSDAQ',
    marketCap: 146524446195,
  },
  {
    id: 1276,
    code: 214150,
    companyName: '클래시스',
    market: 'KOSDAQ',
    marketCap: 1197261984000,
  },
  {
    id: 1277,
    code: 352770,
    companyName: '클리노믹스',
    market: 'KOSDAQ',
    marketCap: 201058629100,
  },
  {
    id: 1278,
    code: 237880,
    companyName: '클리오',
    market: 'KOSDAQ',
    marketCap: 477504341550,
  },
  {
    id: 1279,
    code: 139670,
    companyName: '키네마스터',
    market: 'KOSDAQ',
    marketCap: 249252048800,
  },
  {
    id: 1280,
    code: 311270,
    companyName: '키움제5호스팩',
    market: 'KOSDAQ',
    marketCap: 7542000000,
  },
  {
    id: 1281,
    code: '054780',
    companyName: '키이스트',
    market: 'KOSDAQ',
    marketCap: 256314967000,
  },
  {
    id: 1282,
    code: 219130,
    companyName: '타이거일렉',
    market: 'KOSDAQ',
    marketCap: 95345779000,
  },
  {
    id: 1283,
    code: '065130',
    companyName: '탑엔지니어링',
    market: 'KOSDAQ',
    marketCap: 146662039260,
  },
  {
    id: 1284,
    code: '023160',
    companyName: '태광',
    market: 'KOSDAQ',
    marketCap: 278250000000,
  },
  {
    id: 1285,
    code: '053620',
    companyName: '태양',
    market: 'KOSDAQ',
    marketCap: 93310000000,
  },
  {
    id: 1286,
    code: '044490',
    companyName: '태웅',
    market: 'KOSDAQ',
    marketCap: 250092262500,
  },
  {
    id: 1287,
    code: 124560,
    companyName: '태웅로직스',
    market: 'KOSDAQ',
    marketCap: 185404156080,
  },
  {
    id: 1288,
    code: 191420,
    companyName: '테고사이언스',
    market: 'KOSDAQ',
    marketCap: 217722192900,
  },
  {
    id: 1289,
    code: '073640',
    companyName: '테라사이언스',
    market: 'KOSDAQ',
    marketCap: 145017051655,
  },
  {
    id: 1290,
    code: 182690,
    companyName: '테라셈',
    market: 'KOSDAQ',
    marketCap: 30474933120,
  },
  {
    id: 1291,
    code: '066700',
    companyName: '테라젠이텍스',
    market: 'KOSDAQ',
    marketCap: 269454810500,
  },
  {
    id: 1292,
    code: '095610',
    companyName: '테스',
    market: 'KOSDAQ',
    marketCap: 614791828600,
  },
  {
    id: 1293,
    code: 131970,
    companyName: '테스나',
    market: 'KOSDAQ',
    marketCap: 657539155350,
  },
  {
    id: 1294,
    code: '089030',
    companyName: '테크윙',
    market: 'KOSDAQ',
    marketCap: 498281057600,
  },
  {
    id: 1295,
    code: '054450',
    companyName: '텔레칩스',
    market: 'KOSDAQ',
    marketCap: 259313491200,
  },
  {
    id: 1296,
    code: '091440',
    companyName: '텔레필드',
    market: 'KOSDAQ',
    marketCap: 38492795625,
  },
  {
    id: 1297,
    code: 200230,
    companyName: '텔콘RF제약',
    market: 'KOSDAQ',
    marketCap: 400791274960,
  },
  {
    id: 1298,
    code: 215480,
    companyName: '토박스코리아',
    market: 'KOSDAQ',
    marketCap: 90140229375,
  },
  {
    id: 1299,
    code: '051360',
    companyName: '토비스',
    market: 'KOSDAQ',
    marketCap: 144435381120,
  },
  {
    id: 1300,
    code: '045340',
    companyName: '토탈소프트',
    market: 'KOSDAQ',
    marketCap: 166453878000,
  },
  {
    id: 1301,
    code: 108230,
    companyName: '톱텍',
    market: 'KOSDAQ',
    marketCap: 471488238000,
  },
  {
    id: 1302,
    code: '079970',
    companyName: '투비소프트',
    market: 'KOSDAQ',
    marketCap: 100206520200,
  },
  {
    id: 1303,
    code: 105550,
    companyName: '트루윈',
    market: 'KOSDAQ',
    marketCap: 138889639900,
  },
  {
    id: 1304,
    code: '026150',
    companyName: '특수건설',
    market: 'KOSDAQ',
    marketCap: 221885309200,
  },
  {
    id: 1305,
    code: 322180,
    companyName: '티라유텍',
    market: 'KOSDAQ',
    marketCap: 82049625000,
  },
  {
    id: 1306,
    code: 117730,
    companyName: '티로보틱스',
    market: 'KOSDAQ',
    marketCap: 118515926720,
  },
  {
    id: 1307,
    code: '033830',
    companyName: '티비씨',
    market: 'KOSDAQ',
    marketCap: 167000000000,
  },
  {
    id: 1308,
    code: '057680',
    companyName: '티사이언티픽',
    market: 'KOSDAQ',
    marketCap: 153703789590,
  },
  {
    id: 1309,
    code: '064760',
    companyName: '티씨케이',
    market: 'KOSDAQ',
    marketCap: 2394542500000,
  },
  {
    id: 1310,
    code: 246710,
    companyName: '티앤알바이오팹',
    market: 'KOSDAQ',
    marketCap: 333372216250,
  },
  {
    id: 1311,
    code: 340570,
    companyName: '티앤엘',
    market: 'KOSDAQ',
    marketCap: 342595200000,
  },
  {
    id: 1312,
    code: 277880,
    companyName: '티에스아이',
    market: 'KOSDAQ',
    marketCap: 185156957700,
  },
  {
    id: 1313,
    code: 131290,
    companyName: '티에스이',
    market: 'KOSDAQ',
    marketCap: 647093596500,
  },
  {
    id: 1314,
    code: 356860,
    companyName: '티엘비',
    market: 'KOSDAQ',
    marketCap: 179445497500,
  },
  {
    id: 1315,
    code: '062860',
    companyName: '티엘아이',
    market: 'KOSDAQ',
    marketCap: 93297204000,
  },
  {
    id: 1316,
    code: 321550,
    companyName: '티움바이오',
    market: 'KOSDAQ',
    marketCap: 467354145900,
  },
  {
    id: 1317,
    code: 104480,
    companyName: '티케이케미칼',
    market: 'KOSDAQ',
    marketCap: 581730777600,
  },
  {
    id: 1318,
    code: '081150',
    companyName: '티플랙스',
    market: 'KOSDAQ',
    marketCap: 114182831410,
  },
  {
    id: 1319,
    code: 130740,
    companyName: '티피씨글로벌',
    market: 'KOSDAQ',
    marketCap: 59991932280,
  },
  {
    id: 1320,
    code: '084730',
    companyName: '팅크웨어',
    market: 'KOSDAQ',
    marketCap: 163683207400,
  },
  {
    id: 1321,
    code: '046210',
    companyName: '파나진',
    market: 'KOSDAQ',
    marketCap: 179965296000,
  },
  {
    id: 1322,
    code: '034230',
    companyName: '파라다이스',
    market: 'KOSDAQ',
    marketCap: 1773382104000,
  },
  {
    id: 1323,
    code: '033540',
    companyName: '파라텍',
    market: 'KOSDAQ',
    marketCap: 120159606750,
  },
  {
    id: 1324,
    code: '043200',
    companyName: '파루',
    market: 'KOSDAQ',
    marketCap: 50421081100,
  },
  {
    id: 1325,
    code: 214450,
    companyName: '파마리서치',
    market: 'KOSDAQ',
    marketCap: 942431876000,
  },
  {
    id: 1326,
    code: 208340,
    companyName: '파멥신',
    market: 'KOSDAQ',
    marketCap: 175189256700,
  },
  {
    id: 1327,
    code: 177830,
    companyName: '파버나인',
    market: 'KOSDAQ',
    marketCap: 90019783360,
  },
  {
    id: 1328,
    code: '037070',
    companyName: '파세코',
    market: 'KOSDAQ',
    marketCap: 289800000000,
  },
  {
    id: 1329,
    code: 150900,
    companyName: '파수',
    market: 'KOSDAQ',
    marketCap: 84205852150,
  },
  {
    id: 1330,
    code: '037030',
    companyName: '파워넷',
    market: 'KOSDAQ',
    marketCap: 93063914160,
  },
  {
    id: 1331,
    code: '047310',
    companyName: '파워로직스',
    market: 'KOSDAQ',
    marketCap: 316673034400,
  },
  {
    id: 1332,
    code: 170790,
    companyName: '파이오링크',
    market: 'KOSDAQ',
    marketCap: 95988620000,
  },
  {
    id: 1333,
    code: '049120',
    companyName: '파인디앤씨',
    market: 'KOSDAQ',
    marketCap: 72621645720,
  },
  {
    id: 1334,
    code: '038950',
    companyName: '파인디지털',
    market: 'KOSDAQ',
    marketCap: 82707196500,
  },
  {
    id: 1335,
    code: 106240,
    companyName: '파인테크닉스',
    market: 'KOSDAQ',
    marketCap: 327226116550,
  },
  {
    id: 1336,
    code: 131760,
    companyName: '파인텍',
    market: 'KOSDAQ',
    marketCap: 67180431045,
  },
  {
    id: 1337,
    code: '065690',
    companyName: '파커스',
    market: 'KOSDAQ',
    marketCap: 45521039340,
  },
  {
    id: 1338,
    code: 140860,
    companyName: '파크시스템스',
    market: 'KOSDAQ',
    marketCap: 869213150000,
  },
  {
    id: 1339,
    code: '091700',
    companyName: '파트론',
    market: 'KOSDAQ',
    marketCap: 555103202500,
  },
  {
    id: 1340,
    code: '038160',
    companyName: '팍스넷',
    market: 'KOSDAQ',
    marketCap: 10551204112,
  },
  {
    id: 1341,
    code: '032800',
    companyName: '판타지오',
    market: 'KOSDAQ',
    marketCap: 54759595080,
  },
  {
    id: 1342,
    code: 318010,
    companyName: '팜스빌',
    market: 'KOSDAQ',
    marketCap: 82861582100,
  },
  {
    id: 1343,
    code: '027710',
    companyName: '팜스토리',
    market: 'KOSDAQ',
    marketCap: 245116520000,
  },
  {
    id: 1344,
    code: 225590,
    companyName: '패션플랫폼',
    market: 'KOSDAQ',
    marketCap: 47280165575,
  },
  {
    id: 1345,
    code: '054300',
    companyName: '팬스타엔터프라이즈',
    market: 'KOSDAQ',
    marketCap: 58086381960,
  },
  {
    id: 1346,
    code: '068050',
    companyName: '팬엔터테인먼트',
    market: 'KOSDAQ',
    marketCap: 189918434540,
  },
  {
    id: 1347,
    code: 222110,
    companyName: '팬젠',
    market: 'KOSDAQ',
    marketCap: 97445618100,
  },
  {
    id: 1348,
    code: 263750,
    companyName: '펄어비스',
    market: 'KOSDAQ',
    marketCap: 4231553850000,
  },
  {
    id: 1349,
    code: 251970,
    companyName: '펌텍코리아',
    market: 'KOSDAQ',
    marketCap: 281480000000,
  },
  {
    id: 1350,
    code: '087010',
    companyName: '펩트론',
    market: 'KOSDAQ',
    marketCap: 258867005150,
  },
  {
    id: 1351,
    code: 119500,
    companyName: '포메탈',
    market: 'KOSDAQ',
    marketCap: 65159776000,
  },
  {
    id: 1352,
    code: '022100',
    companyName: '포스코 ICT',
    market: 'KOSDAQ',
    marketCap: 1304457974820,
  },
  {
    id: 1353,
    code: '009520',
    companyName: '포스코엠텍',
    market: 'KOSDAQ',
    marketCap: 379365024330,
  },
  {
    id: 1354,
    code: 189690,
    companyName: '포시에스',
    market: 'KOSDAQ',
    marketCap: 77236712850,
  },
  {
    id: 1355,
    code: 318020,
    companyName: '포인트모바일',
    market: 'KOSDAQ',
    marketCap: 291865369600,
  },
  {
    id: 1356,
    code: 256630,
    companyName: '포인트엔지니어링',
    market: 'KOSDAQ',
    marketCap: 235989488560,
  },
  {
    id: 1357,
    code: 141020,
    companyName: '포티스',
    market: 'KOSDAQ',
    marketCap: 18839533608,
  },
  {
    id: 1358,
    code: '041020',
    companyName: '폴라리스오피스',
    market: 'KOSDAQ',
    marketCap: 80358478200,
  },
  {
    id: 1359,
    code: 114630,
    companyName: '폴라리스우노',
    market: 'KOSDAQ',
    marketCap: 79136215950,
  },
  {
    id: 1360,
    code: 123010,
    companyName: '폴라리스웍스',
    market: 'KOSDAQ',
    marketCap: 95205966720,
  },
  {
    id: 1361,
    code: 290720,
    companyName: '푸드나무',
    market: 'KOSDAQ',
    marketCap: 176616582300,
  },
  {
    id: 1362,
    code: '005670',
    companyName: '푸드웰',
    market: 'KOSDAQ',
    marketCap: 83800000000,
  },
  {
    id: 1363,
    code: '094940',
    companyName: '푸른기술',
    market: 'KOSDAQ',
    marketCap: 114969057500,
  },
  {
    id: 1364,
    code: '007330',
    companyName: '푸른저축은행',
    market: 'KOSDAQ',
    marketCap: 281294220000,
  },
  {
    id: 1365,
    code: '093380',
    companyName: '풍강',
    market: 'KOSDAQ',
    marketCap: 52656738290,
  },
  {
    id: 1366,
    code: '023900',
    companyName: '풍국주정',
    market: 'KOSDAQ',
    marketCap: 261450000000,
  },
  {
    id: 1367,
    code: 214270,
    companyName: '퓨쳐스트림네트웍스',
    market: 'KOSDAQ',
    marketCap: 162730516770,
  },
  {
    id: 1368,
    code: 220100,
    companyName: '퓨쳐켐',
    market: 'KOSDAQ',
    marketCap: 195137385300,
  },
  {
    id: 1369,
    code: '035200',
    companyName: '프럼파스트',
    market: 'KOSDAQ',
    marketCap: 93997499400,
  },
  {
    id: 1370,
    code: 334970,
    companyName: '프레스티지바이오로직스',
    market: 'KOSDAQ',
    marketCap: 638353900950,
  },
  {
    id: 1371,
    code: 203690,
    companyName: '프로스테믹스',
    market: 'KOSDAQ',
    marketCap: 147794942040,
  },
  {
    id: 1372,
    code: '053610',
    companyName: '프로텍',
    market: 'KOSDAQ',
    marketCap: 295900000000,
  },
  {
    id: 1373,
    code: 335810,
    companyName: '프리시젼바이오',
    market: 'KOSDAQ',
    marketCap: 179901536000,
  },
  {
    id: 1374,
    code: '053160',
    companyName: '프리엠스',
    market: 'KOSDAQ',
    marketCap: 156600000000,
  },
  {
    id: 1375,
    code: '075130',
    companyName: '플랜티넷',
    market: 'KOSDAQ',
    marketCap: 74278400000,
  },
  {
    id: 1376,
    code: 237820,
    companyName: '플레이디',
    market: 'KOSDAQ',
    marketCap: 132119542000,
  },
  {
    id: 1377,
    code: '023770',
    companyName: '플레이위드',
    market: 'KOSDAQ',
    marketCap: 100090583500,
  },
  {
    id: 1378,
    code: 300080,
    companyName: '플리토',
    market: 'KOSDAQ',
    marketCap: 112792408150,
  },
  {
    id: 1379,
    code: '032580',
    companyName: '피델릭스',
    market: 'KOSDAQ',
    marketCap: 53342623040,
  },
  {
    id: 1380,
    code: '051380',
    companyName: '피씨디렉트',
    market: 'KOSDAQ',
    marketCap: 89721871200,
  },
  {
    id: 1381,
    code: 241820,
    companyName: '피씨엘',
    market: 'KOSDAQ',
    marketCap: 587202905400,
  },
  {
    id: 1382,
    code: 237750,
    companyName: '피앤씨테크',
    market: 'KOSDAQ',
    marketCap: 54766338000,
  },
  {
    id: 1383,
    code: '024850',
    companyName: '피에스엠씨',
    market: 'KOSDAQ',
    marketCap: 89667713460,
  },
  {
    id: 1384,
    code: 319660,
    companyName: '피에스케이',
    market: 'KOSDAQ',
    marketCap: 656305139000,
  },
  {
    id: 1385,
    code: '031980',
    companyName: '피에스케이홀딩스',
    market: 'KOSDAQ',
    marketCap: 247967542500,
  },
  {
    id: 1386,
    code: '002230',
    companyName: '피에스텍',
    market: 'KOSDAQ',
    marketCap: 117228688280,
  },
  {
    id: 1387,
    code: '057880',
    companyName: '피에이치씨',
    market: 'KOSDAQ',
    marketCap: 116076883200,
  },
  {
    id: 1388,
    code: '043370',
    companyName: '피에이치에이',
    market: 'KOSDAQ',
    marketCap: 257250000000,
  },
  {
    id: 1389,
    code: 239890,
    companyName: '피엔에이치테크',
    market: 'KOSDAQ',
    marketCap: 165963588000,
  },
  {
    id: 1390,
    code: 347740,
    companyName: '피엔케이피부임상연구센타',
    market: 'KOSDAQ',
    marketCap: 186440703400,
  },
  {
    id: 1391,
    code: 137400,
    companyName: '피엔티',
    market: 'KOSDAQ',
    marketCap: 507128715400,
  },
  {
    id: 1392,
    code: 128660,
    companyName: '피제이메탈',
    market: 'KOSDAQ',
    marketCap: 99585526535,
  },
  {
    id: 1393,
    code: '006140',
    companyName: '피제이전자',
    market: 'KOSDAQ',
    marketCap: 130500000000,
  },
  {
    id: 1394,
    code: 304840,
    companyName: '피플바이오',
    market: 'KOSDAQ',
    marketCap: 283372488000,
  },
  {
    id: 1395,
    code: '062970',
    companyName: '피피아이',
    market: 'KOSDAQ',
    marketCap: 74930889250,
  },
  {
    id: 1396,
    code: '087600',
    companyName: '픽셀플러스',
    market: 'KOSDAQ',
    marketCap: 59452542240,
  },
  {
    id: 1397,
    code: 161580,
    companyName: '필옵틱스',
    market: 'KOSDAQ',
    marketCap: 232655681250,
  },
  {
    id: 1398,
    code: 347770,
    companyName: '핌스',
    market: 'KOSDAQ',
    marketCap: 146778878050,
  },
  {
    id: 1399,
    code: 163730,
    companyName: '핑거',
    market: 'KOSDAQ',
    marketCap: 175079315300,
  },
  {
    id: 1400,
    code: 332710,
    companyName: '하나금융14호스팩',
    market: 'KOSDAQ',
    marketCap: 9459450000,
  },
  {
    id: 1401,
    code: 341160,
    companyName: '하나금융15호스팩',
    market: 'KOSDAQ',
    marketCap: 10079400000,
  },
  {
    id: 1402,
    code: 343510,
    companyName: '하나금융16호스팩',
    market: 'KOSDAQ',
    marketCap: 8601450000,
  },
  {
    id: 1403,
    code: 363260,
    companyName: '하나금융17호스팩',
    market: 'KOSDAQ',
    marketCap: 14539350000,
  },
  {
    id: 1404,
    code: 299030,
    companyName: '하나기술',
    market: 'KOSDAQ',
    marketCap: 453525123100,
  },
  {
    id: 1405,
    code: '067310',
    companyName: '하나마이크론',
    market: 'KOSDAQ',
    marketCap: 515748295850,
  },
  {
    id: 1406,
    code: 372290,
    companyName: '하나머스트7호스팩',
    market: 'KOSDAQ',
    marketCap: 15107040000,
  },
  {
    id: 1407,
    code: 307160,
    companyName: '하나머스트제6호스팩',
    market: 'KOSDAQ',
    marketCap: 10075050000,
  },
  {
    id: 1408,
    code: 166090,
    companyName: '하나머티리얼즈',
    market: 'KOSDAQ',
    marketCap: 922641678000,
  },
  {
    id: 1409,
    code: 136480,
    companyName: '하림',
    market: 'KOSDAQ',
    marketCap: 365361374880,
  },
  {
    id: 1410,
    code: '003380',
    companyName: '하림지주',
    market: 'KOSDAQ',
    marketCap: 992674565000,
  },
  {
    id: 1411,
    code: 149980,
    companyName: '하이로닉',
    market: 'KOSDAQ',
    marketCap: 97994965250,
  },
  {
    id: 1412,
    code: '013030',
    companyName: '하이록코리아',
    market: 'KOSDAQ',
    marketCap: 238231560000,
  },
  {
    id: 1413,
    code: 126700,
    companyName: '하이비젼시스템',
    market: 'KOSDAQ',
    marketCap: 250280376000,
  },
  {
    id: 1414,
    code: 106080,
    companyName: '하이소닉',
    market: 'KOSDAQ',
    marketCap: 67129875590,
  },
  {
    id: 1415,
    code: 340120,
    companyName: '하이제5호스팩',
    market: 'KOSDAQ',
    marketCap: 10818100000,
  },
  {
    id: 1416,
    code: 377400,
    companyName: '하이제6호스팩',
    market: 'KOSDAQ',
    marketCap: 14735437500,
  },
  {
    id: 1417,
    code: 221840,
    companyName: '하이즈항공',
    market: 'KOSDAQ',
    marketCap: 109893197070,
  },
  {
    id: 1418,
    code: 106190,
    companyName: '하이텍팜',
    market: 'KOSDAQ',
    marketCap: 104913973600,
  },
  {
    id: 1419,
    code: '066130',
    companyName: '하츠',
    market: 'KOSDAQ',
    marketCap: 172160000000,
  },
  {
    id: 1420,
    code: 368770,
    companyName: '한국9호스팩',
    market: 'KOSDAQ',
    marketCap: 10798450000,
  },
  {
    id: 1421,
    code: '004590',
    companyName: '한국가구',
    market: 'KOSDAQ',
    marketCap: 115500000000,
  },
  {
    id: 1422,
    code: '039340',
    companyName: '한국경제TV',
    market: 'KOSDAQ',
    marketCap: 199870000000,
  },
  {
    id: 1423,
    code: '034950',
    companyName: '한국기업평가',
    market: 'KOSDAQ',
    marketCap: 402743591800,
  },
  {
    id: 1424,
    code: 222980,
    companyName: '한국맥널티',
    market: 'KOSDAQ',
    marketCap: 104715862300,
  },
  {
    id: 1425,
    code: 318000,
    companyName: '한국바이오젠',
    market: 'KOSDAQ',
    marketCap: 71628987000,
  },
  {
    id: 1426,
    code: 256840,
    companyName: '한국비엔씨',
    market: 'KOSDAQ',
    marketCap: 391125117240,
  },
  {
    id: 1427,
    code: '025550',
    companyName: '한국선재',
    market: 'KOSDAQ',
    marketCap: 149340526860,
  },
  {
    id: 1428,
    code: '017890',
    companyName: '한국알콜',
    market: 'KOSDAQ',
    marketCap: 323006112000,
  },
  {
    id: 1429,
    code: '080720',
    companyName: '한국유니온제약',
    market: 'KOSDAQ',
    marketCap: 116579988000,
  },
  {
    id: 1430,
    code: '063570',
    companyName: '한국전자금융',
    market: 'KOSDAQ',
    marketCap: 263278982880,
  },
  {
    id: 1431,
    code: '041460',
    companyName: '한국전자인증',
    market: 'KOSDAQ',
    marketCap: 208583900280,
  },
  {
    id: 1432,
    code: 101680,
    companyName: '한국정밀기계',
    market: 'KOSDAQ',
    marketCap: 12101760000,
  },
  {
    id: 1433,
    code: '039740',
    companyName: '한국정보공학',
    market: 'KOSDAQ',
    marketCap: 45704862900,
  },
  {
    id: 1434,
    code: '053300',
    companyName: '한국정보인증',
    market: 'KOSDAQ',
    marketCap: 258981150000,
  },
  {
    id: 1435,
    code: '025770',
    companyName: '한국정보통신',
    market: 'KOSDAQ',
    marketCap: 354597246370,
  },
  {
    id: 1436,
    code: 310870,
    companyName: '한국제8호스팩',
    market: 'KOSDAQ',
    marketCap: 7558200000,
  },
  {
    id: 1437,
    code: '023760',
    companyName: '한국캐피탈',
    market: 'KOSDAQ',
    marketCap: 336124198440,
  },
  {
    id: 1438,
    code: '054040',
    companyName: '한국컴퓨터',
    market: 'KOSDAQ',
    marketCap: 93856333600,
  },
  {
    id: 1439,
    code: '050540',
    companyName: '한국코퍼레이션',
    market: 'KOSDAQ',
    marketCap: 14321537226,
  },
  {
    id: 1440,
    code: '021650',
    companyName: '한국큐빅',
    market: 'KOSDAQ',
    marketCap: 88620051460,
  },
  {
    id: 1441,
    code: '053590',
    companyName: '한국테크놀로지',
    market: 'KOSDAQ',
    marketCap: 206389773630,
  },
  {
    id: 1442,
    code: '032300',
    companyName: '한국파마',
    market: 'KOSDAQ',
    marketCap: 482621519250,
  },
  {
    id: 1443,
    code: '037230',
    companyName: '한국팩키지',
    market: 'KOSDAQ',
    marketCap: 137750000000,
  },
  {
    id: 1444,
    code: '030520',
    companyName: '한글과컴퓨터',
    market: 'KOSDAQ',
    marketCap: 578569942600,
  },
  {
    id: 1445,
    code: '052600',
    companyName: '한네트',
    market: 'KOSDAQ',
    marketCap: 115637000000,
  },
  {
    id: 1446,
    code: 256150,
    companyName: '한독크린텍',
    market: 'KOSDAQ',
    marketCap: 132548400000,
  },
  {
    id: 1447,
    code: '092460',
    companyName: '한라IMS',
    market: 'KOSDAQ',
    marketCap: 106996749580,
  },
  {
    id: 1448,
    code: '047080',
    companyName: '한빛소프트',
    market: 'KOSDAQ',
    marketCap: 161068934000,
  },
  {
    id: 1449,
    code: '070590',
    companyName: '한솔인티큐브',
    market: 'KOSDAQ',
    marketCap: 51146034885,
  },
  {
    id: 1450,
    code: 226440,
    companyName: '한송네오텍',
    market: 'KOSDAQ',
    marketCap: 89855251800,
  },
  {
    id: 1451,
    code: '042520',
    companyName: '한스바이오메드',
    market: 'KOSDAQ',
    marketCap: 165688888400,
  },
  {
    id: 1452,
    code: '078350',
    companyName: '한양디지텍',
    market: 'KOSDAQ',
    marketCap: 98638484460,
  },
  {
    id: 1453,
    code: '045100',
    companyName: '한양이엔지',
    market: 'KOSDAQ',
    marketCap: 328500000000,
  },
  {
    id: 1454,
    code: '046110',
    companyName: '한일네트웍스',
    market: 'KOSDAQ',
    marketCap: 85592265320,
  },
  {
    id: 1455,
    code: '024740',
    companyName: '한일단조',
    market: 'KOSDAQ',
    marketCap: 90421267200,
  },
  {
    id: 1456,
    code: '005860',
    companyName: '한일사료',
    market: 'KOSDAQ',
    marketCap: 99888341475,
  },
  {
    id: 1457,
    code: 123840,
    companyName: '한일진공',
    market: 'KOSDAQ',
    marketCap: 94750232170,
  },
  {
    id: 1458,
    code: '007770',
    companyName: '한일화학',
    market: 'KOSDAQ',
    marketCap: 61074000000,
  },
  {
    id: 1459,
    code: '079170',
    companyName: '한창산업',
    market: 'KOSDAQ',
    marketCap: 46800000000,
  },
  {
    id: 1460,
    code: '086960',
    companyName: '한컴MDS',
    market: 'KOSDAQ',
    marketCap: 143906310400,
  },
  {
    id: 1461,
    code: '054920',
    companyName: '한컴위드',
    market: 'KOSDAQ',
    marketCap: 311798745050,
  },
  {
    id: 1462,
    code: '002680',
    companyName: '한탑',
    market: 'KOSDAQ',
    marketCap: 52864877560,
  },
  {
    id: 1463,
    code: '066110',
    companyName: '한프',
    market: 'KOSDAQ',
    marketCap: 86834027360,
  },
  {
    id: 1464,
    code: 317320,
    companyName: '한화에스비아이스팩',
    market: 'KOSDAQ',
    marketCap: 18791600000,
  },
  {
    id: 1465,
    code: 340440,
    companyName: '한화플러스제1호스팩',
    market: 'KOSDAQ',
    marketCap: 10102950000,
  },
  {
    id: 1466,
    code: '034810',
    companyName: '해성산업',
    market: 'KOSDAQ',
    marketCap: 428289842500,
  },
  {
    id: 1467,
    code: '076610',
    companyName: '해성옵틱스',
    market: 'KOSDAQ',
    marketCap: 42660242725,
  },
  {
    id: 1468,
    code: '059270',
    companyName: '해성티피씨',
    market: 'KOSDAQ',
    marketCap: 85655000000,
  },
  {
    id: 1469,
    code: 220180,
    companyName: '핸디소프트',
    market: 'KOSDAQ',
    marketCap: 91217600000,
  },
  {
    id: 1470,
    code: 900270,
    companyName: '헝셩그룹',
    market: 'KOSDAQ',
    marketCap: 52160000000,
  },
  {
    id: 1471,
    code: '084990',
    companyName: '헬릭스미스',
    market: 'KOSDAQ',
    marketCap: 1224999275500,
  },
  {
    id: 1472,
    code: 170030,
    companyName: '현대공업',
    market: 'KOSDAQ',
    marketCap: 146036800000,
  },
  {
    id: 1473,
    code: 319400,
    companyName: '현대무벡스',
    market: 'KOSDAQ',
    marketCap: 528956084800,
  },
  {
    id: 1474,
    code: '048410',
    companyName: '현대바이오',
    market: 'KOSDAQ',
    marketCap: 1404243100650,
  },
  {
    id: 1475,
    code: '052260',
    companyName: '현대바이오랜드',
    market: 'KOSDAQ',
    marketCap: 356250000000,
  },
  {
    id: 1476,
    code: '016790',
    companyName: '현대사료',
    market: 'KOSDAQ',
    marketCap: 115014088200,
  },
  {
    id: 1477,
    code: '041440',
    companyName: '현대에버다임',
    market: 'KOSDAQ',
    marketCap: 126844883520,
  },
  {
    id: 1478,
    code: '039010',
    companyName: '현대에이치티',
    market: 'KOSDAQ',
    marketCap: 87112500000,
  },
  {
    id: 1479,
    code: '090850',
    companyName: '현대이지웰',
    market: 'KOSDAQ',
    marketCap: 275457787600,
  },
  {
    id: 1480,
    code: '092300',
    companyName: '현우산업',
    market: 'KOSDAQ',
    marketCap: 103217436260,
  },
  {
    id: 1481,
    code: '053660',
    companyName: '현진소재',
    market: 'KOSDAQ',
    marketCap: 21280401200,
  },
  {
    id: 1482,
    code: '011080',
    companyName: '형지I&C',
    market: 'KOSDAQ',
    marketCap: 70509759080,
  },
  {
    id: 1483,
    code: '060560',
    companyName: '홈센타홀딩스',
    market: 'KOSDAQ',
    marketCap: 167247077750,
  },
  {
    id: 1484,
    code: '064240',
    companyName: '홈캐스트',
    market: 'KOSDAQ',
    marketCap: 114342381980,
  },
  {
    id: 1485,
    code: '039610',
    companyName: '화성밸브',
    market: 'KOSDAQ',
    marketCap: 90674584000,
  },
  {
    id: 1486,
    code: 126640,
    companyName: '화신정공',
    market: 'KOSDAQ',
    marketCap: 81841245750,
  },
  {
    id: 1487,
    code: '061250',
    companyName: '화일약품',
    market: 'KOSDAQ',
    marketCap: 263518715295,
  },
  {
    id: 1488,
    code: '097870',
    companyName: '효성오앤비',
    market: 'KOSDAQ',
    marketCap: 99757500000,
  },
  {
    id: 1489,
    code: '050090',
    companyName: '휘닉스소재',
    market: 'KOSDAQ',
    marketCap: 104067708730,
  },
  {
    id: 1490,
    code: 290270,
    companyName: '휴네시온',
    market: 'KOSDAQ',
    marketCap: 66677243680,
  },
  {
    id: 1491,
    code: '090710',
    companyName: '휴림로봇',
    market: 'KOSDAQ',
    marketCap: 126692539515,
  },
  {
    id: 1492,
    code: 205470,
    companyName: '휴마시스',
    market: 'KOSDAQ',
    marketCap: 403841017000,
  },
  {
    id: 1493,
    code: 115160,
    companyName: '휴맥스',
    market: 'KOSDAQ',
    marketCap: 138369077550,
  },
  {
    id: 1494,
    code: '028080',
    companyName: '휴맥스홀딩스',
    market: 'KOSDAQ',
    marketCap: 57227652300,
  },
  {
    id: 1495,
    code: '032860',
    companyName: '휴먼엔',
    market: 'KOSDAQ',
    marketCap: 128424387720,
  },
  {
    id: 1496,
    code: 200670,
    companyName: '휴메딕스',
    market: 'KOSDAQ',
    marketCap: 341014834800,
  },
  {
    id: 1497,
    code: '065510',
    companyName: '휴비츠',
    market: 'KOSDAQ',
    marketCap: 121205284200,
  },
  {
    id: 1498,
    code: 243070,
    companyName: '휴온스',
    market: 'KOSDAQ',
    marketCap: 651580260000,
  },
  {
    id: 1499,
    code: '084110',
    companyName: '휴온스글로벌',
    market: 'KOSDAQ',
    marketCap: 744674889100,
  },
  {
    id: 1500,
    code: 263920,
    companyName: '휴온스블러썸',
    market: 'KOSDAQ',
    marketCap: 223400585370,
  },
  {
    id: 1501,
    code: 145020,
    companyName: '휴젤',
    market: 'KOSDAQ',
    marketCap: 2966544108000,
  },
  {
    id: 1502,
    code: '024060',
    companyName: '흥구석유',
    market: 'KOSDAQ',
    marketCap: 129450000000,
  },
  {
    id: 1503,
    code: '010240',
    companyName: '흥국',
    market: 'KOSDAQ',
    marketCap: 114601072800,
  },
  {
    id: 1504,
    code: 189980,
    companyName: '흥국에프엔비',
    market: 'KOSDAQ',
    marketCap: 157195285065,
  },
  {
    id: 1505,
    code: '037440',
    companyName: '희림',
    market: 'KOSDAQ',
    marketCap: 128086770000,
  },
  {
    id: 1506,
    code: 238490,
    companyName: '힘스',
    market: 'KOSDAQ',
    marketCap: 127828266800,
  },
  {
    id: 0,
    code: '095570',
    companyName: 'AJ네트웍스',
    market: 'KOSPI',
    marketCap: 268291750350,
  },
  {
    id: 1,
    code: '006840',
    companyName: 'AK홀딩스',
    market: 'KOSPI',
    marketCap: 451079452050,
  },
  {
    id: 2,
    code: '027410',
    companyName: 'BGF',
    market: 'KOSPI',
    marketCap: 641302499700,
  },
  {
    id: 3,
    code: 282330,
    companyName: 'BGF리테일',
    market: 'KOSPI',
    marketCap: 3128386986000,
  },
  {
    id: 4,
    code: 138930,
    companyName: 'BNK금융지주',
    market: 'KOSPI',
    marketCap: 2529257508960,
  },
  {
    id: 5,
    code: '001460',
    companyName: 'BYC',
    market: 'KOSPI',
    marketCap: 276392137500,
  },
  {
    id: 6,
    code: '001465',
    companyName: 'BYC우',
    market: 'KOSPI',
    marketCap: 41030842500,
  },
  {
    id: 7,
    code: '001040',
    companyName: 'CJ',
    market: 'KOSPI',
    marketCap: 3180292782000,
  },
  {
    id: 8,
    code: '079160',
    companyName: 'CJ CGV',
    market: 'KOSPI',
    marketCap: 1142505000000,
  },
  {
    id: 9,
    code: '00104K',
    companyName: 'CJ4우(전환)',
    market: 'KOSPI',
    marketCap: 379963428800,
  },
  {
    id: 10,
    code: '000120',
    companyName: 'CJ대한통운',
    market: 'KOSPI',
    marketCap: 4049191060000,
  },
  {
    id: 11,
    code: '011150',
    companyName: 'CJ씨푸드',
    market: 'KOSPI',
    marketCap: 175521826105,
  },
  {
    id: 12,
    code: '011155',
    companyName: 'CJ씨푸드1우',
    market: 'KOSPI',
    marketCap: 8550000000,
  },
  {
    id: 13,
    code: '001045',
    companyName: 'CJ우',
    market: 'KOSPI',
    marketCap: 150078807200,
  },
  {
    id: 14,
    code: '097950',
    companyName: 'CJ제일제당',
    market: 'KOSPI',
    marketCap: 6819546258000,
  },
  {
    id: 15,
    code: '097955',
    companyName: 'CJ제일제당 우',
    market: 'KOSPI',
    marketCap: 276769780500,
  },
  {
    id: 16,
    code: '000590',
    companyName: 'CS홀딩스',
    market: 'KOSPI',
    marketCap: 83007255800,
  },
  {
    id: 17,
    code: '012030',
    companyName: 'DB',
    market: 'KOSPI',
    marketCap: 216261977975,
  },
  {
    id: 18,
    code: '016610',
    companyName: 'DB금융투자',
    market: 'KOSPI',
    marketCap: 317923453610,
  },
  {
    id: 19,
    code: '005830',
    companyName: 'DB손해보험',
    market: 'KOSPI',
    marketCap: 3632040000000,
  },
  {
    id: 20,
    code: '000990',
    companyName: 'DB하이텍',
    market: 'KOSPI',
    marketCap: 2468561492800,
  },
  {
    id: 21,
    code: '000995',
    companyName: 'DB하이텍1우',
    market: 'KOSPI',
    marketCap: 13871026000,
  },
  {
    id: 22,
    code: 139130,
    companyName: 'DGB금융지주',
    market: 'KOSPI',
    marketCap: 1578130621890,
  },
  {
    id: 23,
    code: '001530',
    companyName: 'DI동일',
    market: 'KOSPI',
    marketCap: 470747812500,
  },
  {
    id: 24,
    code: '000210',
    companyName: 'DL',
    market: 'KOSPI',
    marketCap: 1691139838800,
  },
  {
    id: 25,
    code: '001880',
    companyName: 'DL건설',
    market: 'KOSPI',
    marketCap: 823690157400,
  },
  {
    id: 26,
    code: '000215',
    companyName: 'DL우',
    market: 'KOSPI',
    marketCap: 77814207250,
  },
  {
    id: 27,
    code: 375500,
    companyName: 'DL이앤씨',
    market: 'KOSPI',
    marketCap: 2632788368000,
  },
  {
    id: 28,
    code: '37550K',
    companyName: 'DL이앤씨우',
    market: 'KOSPI',
    marketCap: 167419692000,
  },
  {
    id: 29,
    code: '004840',
    companyName: 'DRB동일',
    market: 'KOSPI',
    marketCap: 161233700000,
  },
  {
    id: 30,
    code: 155660,
    companyName: 'DSR',
    market: 'KOSPI',
    marketCap: 116960000000,
  },
  {
    id: 31,
    code: '069730',
    companyName: 'DSR제강',
    market: 'KOSPI',
    marketCap: 93888000000,
  },
  {
    id: 32,
    code: '017940',
    companyName: 'E1',
    market: 'KOSPI',
    marketCap: 392392000000,
  },
  {
    id: 33,
    code: 365550,
    companyName: 'ESR켄달스퀘어리츠',
    market: 'KOSPI',
    marketCap: 1115987610000,
  },
  {
    id: 34,
    code: 383220,
    companyName: 'F&F',
    market: 'KOSPI',
    marketCap: 3773246887500,
  },
  {
    id: 35,
    code: '007700',
    companyName: 'F&F홀딩스',
    market: 'KOSPI',
    marketCap: 291357725250,
  },
  {
    id: 36,
    code: 114090,
    companyName: 'GKL',
    market: 'KOSPI',
    marketCap: 1097938142500,
  },
  {
    id: 37,
    code: '078930',
    companyName: 'GS',
    market: 'KOSPI',
    marketCap: 4418126223900,
  },
  {
    id: 38,
    code: '006360',
    companyName: 'GS건설',
    market: 'KOSPI',
    marketCap: 3607259803500,
  },
  {
    id: 39,
    code: '001250',
    companyName: 'GS글로벌',
    market: 'KOSPI',
    marketCap: 254203993120,
  },
  {
    id: 40,
    code: '007070',
    companyName: 'GS리테일',
    market: 'KOSPI',
    marketCap: 2883650000000,
  },
  {
    id: 41,
    code: '078935',
    companyName: 'GS우',
    market: 'KOSPI',
    marketCap: 69340490100,
  },
  {
    id: 42,
    code: '012630',
    companyName: 'HDC',
    market: 'KOSPI',
    marketCap: 785603631150,
  },
  {
    id: 43,
    code: '039570',
    companyName: 'HDC아이콘트롤스',
    market: 'KOSPI',
    marketCap: 239493000000,
  },
  {
    id: 44,
    code: '089470',
    companyName: 'HDC현대EP',
    market: 'KOSPI',
    marketCap: 193314000000,
  },
  {
    id: 45,
    code: 294870,
    companyName: 'HDC현대산업개발',
    market: 'KOSPI',
    marketCap: 1960743067500,
  },
  {
    id: 46,
    code: '011200',
    companyName: 'HMM',
    market: 'KOSPI',
    marketCap: 15421774544550,
  },
  {
    id: 47,
    code: '082740',
    companyName: 'HSD엔진',
    market: 'KOSPI',
    marketCap: 374326670840,
  },
  {
    id: 48,
    code: '003560',
    companyName: 'IHQ',
    market: 'KOSPI',
    marketCap: 285890887340,
  },
  {
    id: 49,
    code: 175330,
    companyName: 'JB금융지주',
    market: 'KOSPI',
    marketCap: 1491160507580,
  },
  {
    id: 50,
    code: 234080,
    companyName: 'JW생명과학',
    market: 'KOSPI',
    marketCap: 301648253700,
  },
  {
    id: 51,
    code: '001060',
    companyName: 'JW중외제약',
    market: 'KOSPI',
    marketCap: 620349868800,
  },
  {
    id: 52,
    code: '001067',
    companyName: 'JW중외제약2우B',
    market: 'KOSPI',
    marketCap: 6939888800,
  },
  {
    id: 53,
    code: '001065',
    companyName: 'JW중외제약우',
    market: 'KOSPI',
    marketCap: 8945294400,
  },
  {
    id: 54,
    code: '096760',
    companyName: 'JW홀딩스',
    market: 'KOSPI',
    marketCap: 318935908120,
  },
  {
    id: 55,
    code: 105560,
    companyName: 'KB금융',
    market: 'KOSPI',
    marketCap: 23659470648000,
  },
  {
    id: 56,
    code: '002380',
    companyName: 'KCC',
    market: 'KOSPI',
    marketCap: 2732589832500,
  },
  {
    id: 57,
    code: '009070',
    companyName: 'KCTC',
    market: 'KOSPI',
    marketCap: 250800000000,
  },
  {
    id: 58,
    code: '009440',
    companyName: 'KC그린홀딩스',
    market: 'KOSPI',
    marketCap: 121821941400,
  },
  {
    id: 59,
    code: 119650,
    companyName: 'KC코트렐',
    market: 'KOSPI',
    marketCap: 100100000000,
  },
  {
    id: 60,
    code: '092220',
    companyName: 'KEC',
    market: 'KOSPI',
    marketCap: 293517954100,
  },
  {
    id: 61,
    code: '016380',
    companyName: 'KG동부제철',
    market: 'KOSPI',
    marketCap: 1970175270900,
  },
  {
    id: 62,
    code: '016385',
    companyName: 'KG동부제철우',
    market: 'KOSPI',
    marketCap: 7767517500,
  },
  {
    id: 63,
    code: '001390',
    companyName: 'KG케미칼',
    market: 'KOSPI',
    marketCap: 467287435200,
  },
  {
    id: 64,
    code: '033180',
    companyName: 'KH 필룩스',
    market: 'KOSPI',
    marketCap: 431205562695,
  },
  {
    id: 65,
    code: '001940',
    companyName: 'KISCO홀딩스',
    market: 'KOSPI',
    marketCap: 402785084000,
  },
  {
    id: 66,
    code: '025000',
    companyName: 'KPX케미칼',
    market: 'KOSPI',
    marketCap: 324764000000,
  },
  {
    id: 67,
    code: '092230',
    companyName: 'KPX홀딩스',
    market: 'KOSPI',
    marketCap: 306286835000,
  },
  {
    id: 68,
    code: '000040',
    companyName: 'KR모터스',
    market: 'KOSPI',
    marketCap: 119691023160,
  },
  {
    id: 69,
    code: '044450',
    companyName: 'KSS해운',
    market: 'KOSPI',
    marketCap: 288664206000,
  },
  {
    id: 70,
    code: '030200',
    companyName: 'KT',
    market: 'KOSPI',
    marketCap: 8577522892800,
  },
  {
    id: 71,
    code: '033780',
    companyName: 'KT&G',
    market: 'KOSPI',
    marketCap: 11628674495900,
  },
  {
    id: 72,
    code: '030210',
    companyName: 'KTB투자증권',
    market: 'KOSPI',
    marketCap: 474671904040,
  },
  {
    id: 73,
    code: '058850',
    companyName: 'KTcs',
    market: 'KOSPI',
    marketCap: 143848450000,
  },
  {
    id: 74,
    code: '058860',
    companyName: 'KTis',
    market: 'KOSPI',
    marketCap: 113976550000,
  },
  {
    id: 75,
    code: '093050',
    companyName: 'LF',
    market: 'KOSPI',
    marketCap: 587724000000,
  },
  {
    id: 76,
    code: '003550',
    companyName: 'LG',
    market: 'KOSPI',
    marketCap: 16359303272000,
  },
  {
    id: 77,
    code: '034220',
    companyName: 'LG디스플레이',
    market: 'KOSPI',
    marketCap: 8516013660000,
  },
  {
    id: 78,
    code: '001120',
    companyName: 'LG상사',
    market: 'KOSPI',
    marketCap: 1166676000000,
  },
  {
    id: 79,
    code: '051900',
    companyName: 'LG생활건강',
    market: 'KOSPI',
    marketCap: 26004298005000,
  },
  {
    id: 80,
    code: '051905',
    companyName: 'LG생활건강우',
    market: 'KOSPI',
    marketCap: 1549576386000,
  },
  {
    id: 81,
    code: '003555',
    companyName: 'LG우',
    market: 'KOSPI',
    marketCap: 223902042000,
  },
  {
    id: 82,
    code: '032640',
    companyName: 'LG유플러스',
    market: 'KOSPI',
    marketCap: 6701984391350,
  },
  {
    id: 83,
    code: '011070',
    companyName: 'LG이노텍',
    market: 'KOSPI',
    marketCap: 5325099075000,
  },
  {
    id: 84,
    code: '066570',
    companyName: 'LG전자',
    market: 'KOSPI',
    marketCap: 25283587263000,
  },
  {
    id: 85,
    code: '066575',
    companyName: 'LG전자우',
    market: 'KOSPI',
    marketCap: 1313009788800,
  },
  {
    id: 86,
    code: 108670,
    companyName: 'LG하우시스',
    market: 'KOSPI',
    marketCap: 842064213000,
  },
  {
    id: 87,
    code: 108675,
    companyName: 'LG하우시스우',
    market: 'KOSPI',
    marketCap: 44390190000,
  },
  {
    id: 88,
    code: '037560',
    companyName: 'LG헬로비전',
    market: 'KOSPI',
    marketCap: 635064293000,
  },
  {
    id: 89,
    code: '051910',
    companyName: 'LG화학',
    market: 'KOSPI',
    marketCap: 58944606405000,
  },
  {
    id: 90,
    code: '051915',
    companyName: 'LG화학우',
    market: 'KOSPI',
    marketCap: 2937121600000,
  },
  {
    id: 91,
    code: '079550',
    companyName: 'LIG넥스원',
    market: 'KOSPI',
    marketCap: 932800000000,
  },
  {
    id: 92,
    code: '006260',
    companyName: 'LS',
    market: 'KOSPI',
    marketCap: 2254000000000,
  },
  {
    id: 93,
    code: '010120',
    companyName: 'LS ELECTRIC',
    market: 'KOSPI',
    marketCap: 1692000000000,
  },
  {
    id: 94,
    code: '000680',
    companyName: 'LS네트웍스',
    market: 'KOSPI',
    marketCap: 297087370320,
  },
  {
    id: 95,
    code: 229640,
    companyName: 'LS전선아시아',
    market: 'KOSPI',
    marketCap: 287567613810,
  },
  {
    id: 96,
    code: 383800,
    companyName: 'LX홀딩스',
    market: 'KOSPI',
    marketCap: 854343728000,
  },
  {
    id: 97,
    code: '38380K',
    companyName: 'LX홀딩스1우',
    market: 'KOSPI',
    marketCap: 30770985000,
  },
  {
    id: 98,
    code: '023150',
    companyName: 'MH에탄올',
    market: 'KOSPI',
    marketCap: 75260965200,
  },
  {
    id: 99,
    code: '035420',
    companyName: 'NAVER',
    market: 'KOSPI',
    marketCap: 63980592352500,
  },
  {
    id: 100,
    code: 181710,
    companyName: 'NHN',
    market: 'KOSPI',
    marketCap: 1543680472500,
  },
  {
    id: 101,
    code: '005940',
    companyName: 'NH투자증권',
    market: 'KOSPI',
    marketCap: 3602033753600,
  },
  {
    id: 102,
    code: '005945',
    companyName: 'NH투자증권우',
    market: 'KOSPI',
    marketCap: 209467744800,
  },
  {
    id: 103,
    code: 338100,
    companyName: 'NH프라임리츠',
    market: 'KOSPI',
    marketCap: 91434000000,
  },
  {
    id: 104,
    code: '034310',
    companyName: 'NICE',
    market: 'KOSPI',
    marketCap: 725446045000,
  },
  {
    id: 105,
    code: '008260',
    companyName: 'NI스틸',
    market: 'KOSPI',
    marketCap: 163878670410,
  },
  {
    id: 106,
    code: '004250',
    companyName: 'NPC',
    market: 'KOSPI',
    marketCap: 157896000000,
  },
  {
    id: 107,
    code: '004255',
    companyName: 'NPC우',
    market: 'KOSPI',
    marketCap: 15285600000,
  },
  {
    id: 108,
    code: '010060',
    companyName: 'OCI',
    market: 'KOSPI',
    marketCap: 2778451721500,
  },
  {
    id: 109,
    code: '005490',
    companyName: 'POSCO',
    market: 'KOSPI',
    marketCap: 29730710735000,
  },
  {
    id: 110,
    code: '010950',
    companyName: 'S-Oil',
    market: 'KOSPI',
    marketCap: 11708610368000,
  },
  {
    id: 111,
    code: '010955',
    companyName: 'S-Oil우',
    market: 'KOSPI',
    marketCap: 248152895900,
  },
  {
    id: 112,
    code: '034120',
    companyName: 'SBS',
    market: 'KOSPI',
    marketCap: 927231165600,
  },
  {
    id: 113,
    code: 101060,
    companyName: 'SBS미디어홀딩스',
    market: 'KOSPI',
    marketCap: 305673175150,
  },
  {
    id: 114,
    code: '005090',
    companyName: 'SGC에너지',
    market: 'KOSPI',
    marketCap: 783752013600,
  },
  {
    id: 115,
    code: '001380',
    companyName: 'SG글로벌',
    market: 'KOSPI',
    marketCap: 171088564115,
  },
  {
    id: 116,
    code: '004060',
    companyName: 'SG세계물산',
    market: 'KOSPI',
    marketCap: 177729114880,
  },
  {
    id: 117,
    code: '002360',
    companyName: 'SH에너지화학',
    market: 'KOSPI',
    marketCap: 135027481950,
  },
  {
    id: 118,
    code: '009160',
    companyName: 'SIMPAC',
    market: 'KOSPI',
    marketCap: 308827315520,
  },
  {
    id: 119,
    code: 123700,
    companyName: 'SJM',
    market: 'KOSPI',
    marketCap: 92224947180,
  },
  {
    id: 120,
    code: '025530',
    companyName: 'SJM홀딩스',
    market: 'KOSPI',
    marketCap: 78403542000,
  },
  {
    id: 121,
    code: '034730',
    companyName: 'SK',
    market: 'KOSPI',
    marketCap: 20052684645000,
  },
  {
    id: 122,
    code: '011790',
    companyName: 'SKC',
    market: 'KOSPI',
    marketCap: 5490903210000,
  },
  {
    id: 123,
    code: '018670',
    companyName: 'SK가스',
    market: 'KOSPI',
    marketCap: 1149165378000,
  },
  {
    id: 124,
    code: '001740',
    companyName: 'SK네트웍스',
    market: 'KOSPI',
    marketCap: 1494089634940,
  },
  {
    id: 125,
    code: '001745',
    companyName: 'SK네트웍스우',
    market: 'KOSPI',
    marketCap: 13865056000,
  },
  {
    id: 126,
    code: '006120',
    companyName: 'SK디스커버리',
    market: 'KOSPI',
    marketCap: 1022332813500,
  },
  {
    id: 127,
    code: '006125',
    companyName: 'SK디스커버리우',
    market: 'KOSPI',
    marketCap: 57666414750,
  },
  {
    id: 128,
    code: 210980,
    companyName: 'SK디앤디',
    market: 'KOSPI',
    marketCap: 679847871200,
  },
  {
    id: 129,
    code: '068400',
    companyName: 'SK렌터카',
    market: 'KOSPI',
    marketCap: 685644680000,
  },
  {
    id: 130,
    code: 302440,
    companyName: 'SK바이오사이언스',
    market: 'KOSPI',
    marketCap: 12240000000000,
  },
  {
    id: 131,
    code: 326030,
    companyName: 'SK바이오팜',
    market: 'KOSPI',
    marketCap: 9906626125000,
  },
  {
    id: 132,
    code: 361610,
    companyName: 'SK아이이테크놀로지',
    market: 'KOSPI',
    marketCap: 11122424352000,
  },
  {
    id: 133,
    code: '03473K',
    companyName: 'SK우',
    market: 'KOSPI',
    marketCap: 145496695000,
  },
  {
    id: 134,
    code: '096770',
    companyName: 'SK이노베이션',
    market: 'KOSPI',
    marketCap: 26029056266000,
  },
  {
    id: 135,
    code: '096775',
    companyName: 'SK이노베이션우',
    market: 'KOSPI',
    marketCap: 236576727000,
  },
  {
    id: 136,
    code: '001510',
    companyName: 'SK증권',
    market: 'KOSPI',
    marketCap: 500945581260,
  },
  {
    id: 137,
    code: '001515',
    companyName: 'SK증권우',
    market: 'KOSPI',
    marketCap: 19719070560,
  },
  {
    id: 138,
    code: 285130,
    companyName: 'SK케미칼',
    market: 'KOSPI',
    marketCap: 3225758202000,
  },
  {
    id: 139,
    code: '28513K',
    companyName: 'SK케미칼우',
    market: 'KOSPI',
    marketCap: 174311065000,
  },
  {
    id: 140,
    code: '017670',
    companyName: 'SK텔레콤',
    market: 'KOSPI',
    marketCap: 23599696832500,
  },
  {
    id: 141,
    code: '000660',
    companyName: 'SK하이닉스',
    market: 'KOSPI',
    marketCap: 92092299172500,
  },
  {
    id: 142,
    code: '064960',
    companyName: 'SNT모티브',
    market: 'KOSPI',
    marketCap: 1003147129600,
  },
  {
    id: 143,
    code: 100840,
    companyName: 'SNT에너지',
    market: 'KOSPI',
    marketCap: 180161064000,
  },
  {
    id: 144,
    code: '003570',
    companyName: 'SNT중공업',
    market: 'KOSPI',
    marketCap: 335852239700,
  },
  {
    id: 145,
    code: '036530',
    companyName: 'SNT홀딩스',
    market: 'KOSPI',
    marketCap: 352163937600,
  },
  {
    id: 146,
    code: '005610',
    companyName: 'SPC삼립',
    market: 'KOSPI',
    marketCap: 760215692900,
  },
  {
    id: 147,
    code: '011810',
    companyName: 'STX',
    market: 'KOSPI',
    marketCap: 177604455000,
  },
  {
    id: 148,
    code: '077970',
    companyName: 'STX엔진',
    market: 'KOSPI',
    marketCap: 311770649200,
  },
  {
    id: 149,
    code: '071970',
    companyName: 'STX중공업',
    market: 'KOSPI',
    marketCap: 200263790560,
  },
  {
    id: 150,
    code: '002820',
    companyName: 'SUN&L',
    market: 'KOSPI',
    marketCap: 79430330700,
  },
  {
    id: 151,
    code: '084870',
    companyName: 'TBH글로벌',
    market: 'KOSPI',
    marketCap: 58086240915,
  },
  {
    id: 152,
    code: '002710',
    companyName: 'TCC스틸',
    market: 'KOSPI',
    marketCap: 111742500000,
  },
  {
    id: 153,
    code: '002900',
    companyName: 'TYM',
    market: 'KOSPI',
    marketCap: 267886189880,
  },
  {
    id: 154,
    code: '024070',
    companyName: 'WISCOM',
    market: 'KOSPI',
    marketCap: 96096766260,
  },
  {
    id: 155,
    code: '037270',
    companyName: 'YG PLUS',
    market: 'KOSPI',
    marketCap: 397702400700,
  },
  {
    id: 156,
    code: '000500',
    companyName: '가온전선',
    market: 'KOSPI',
    marketCap: 157053099250,
  },
  {
    id: 157,
    code: '000860',
    companyName: '강남제비스코',
    market: 'KOSPI',
    marketCap: 193375000000,
  },
  {
    id: 158,
    code: '035250',
    companyName: '강원랜드',
    market: 'KOSPI',
    marketCap: 6022425075000,
  },
  {
    id: 159,
    code: '011420',
    companyName: '갤럭시아에스엠',
    market: 'KOSPI',
    marketCap: 65292656280,
  },
  {
    id: 160,
    code: '002100',
    companyName: '경농',
    market: 'KOSPI',
    marketCap: 332859903750,
  },
  {
    id: 161,
    code: '009450',
    companyName: '경동나비엔',
    market: 'KOSPI',
    marketCap: 912877383000,
  },
  {
    id: 162,
    code: 267290,
    companyName: '경동도시가스',
    market: 'KOSPI',
    marketCap: 145026987600,
  },
  {
    id: 163,
    code: '012320',
    companyName: '경동인베스트',
    market: 'KOSPI',
    marketCap: 98030203350,
  },
  {
    id: 164,
    code: '000050',
    companyName: '경방',
    market: 'KOSPI',
    marketCap: 408487523000,
  },
  {
    id: 165,
    code: 214390,
    companyName: '경보제약',
    market: 'KOSPI',
    marketCap: 297640407000,
  },
  {
    id: 166,
    code: '012610',
    companyName: '경인양행',
    market: 'KOSPI',
    marketCap: 300433682600,
  },
  {
    id: 167,
    code: '009140',
    companyName: '경인전자',
    market: 'KOSPI',
    marketCap: 52150460400,
  },
  {
    id: 168,
    code: '013580',
    companyName: '계룡건설',
    market: 'KOSPI',
    marketCap: 329550468300,
  },
  {
    id: 169,
    code: '012200',
    companyName: '계양전기',
    market: 'KOSPI',
    marketCap: 160881000000,
  },
  {
    id: 170,
    code: '012205',
    companyName: '계양전기우',
    market: 'KOSPI',
    marketCap: 11620000000,
  },
  {
    id: 171,
    code: '002140',
    companyName: '고려산업',
    market: 'KOSPI',
    marketCap: 109858167125,
  },
  {
    id: 172,
    code: '010130',
    companyName: '고려아연',
    market: 'KOSPI',
    marketCap: 8199015000000,
  },
  {
    id: 173,
    code: '002240',
    companyName: '고려제강',
    market: 'KOSPI',
    marketCap: 583050000000,
  },
  {
    id: 174,
    code: '009290',
    companyName: '광동제약',
    market: 'KOSPI',
    marketCap: 475981327080,
  },
  {
    id: 175,
    code: '017040',
    companyName: '광명전기',
    market: 'KOSPI',
    marketCap: 143664193725,
  },
  {
    id: 176,
    code: '017900',
    companyName: '광전자',
    market: 'KOSPI',
    marketCap: 152971534320,
  },
  {
    id: 177,
    code: '037710',
    companyName: '광주신세계',
    market: 'KOSPI',
    marketCap: 309600000000,
  },
  {
    id: 178,
    code: '030610',
    companyName: '교보증권',
    market: 'KOSPI',
    marketCap: 580586598080,
  },
  {
    id: 179,
    code: 339770,
    companyName: '교촌에프앤비',
    market: 'KOSPI',
    marketCap: 495903419000,
  },
  {
    id: 180,
    code: '007690',
    companyName: '국도화학',
    market: 'KOSPI',
    marketCap: 400351442400,
  },
  {
    id: 181,
    code: '005320',
    companyName: '국동',
    market: 'KOSPI',
    marketCap: 174511713080,
  },
  {
    id: 182,
    code: '001140',
    companyName: '국보',
    market: 'KOSPI',
    marketCap: 90865001370,
  },
  {
    id: 183,
    code: '002720',
    companyName: '국제약품',
    market: 'KOSPI',
    marketCap: 139944413400,
  },
  {
    id: 184,
    code: '083420',
    companyName: '그린케미칼',
    market: 'KOSPI',
    marketCap: 290400000000,
  },
  {
    id: 185,
    code: '014530',
    companyName: '극동유화',
    market: 'KOSPI',
    marketCap: 185156620200,
  },
  {
    id: 186,
    code: '014280',
    companyName: '금강공업',
    market: 'KOSPI',
    marketCap: 237196286550,
  },
  {
    id: 187,
    code: '014285',
    companyName: '금강공업우',
    market: 'KOSPI',
    marketCap: 8376225000,
  },
  {
    id: 188,
    code: '008870',
    companyName: '금비',
    market: 'KOSPI',
    marketCap: 93500000000,
  },
  {
    id: 189,
    code: '001570',
    companyName: '금양',
    market: 'KOSPI',
    marketCap: 262504964880,
  },
  {
    id: 190,
    code: '002990',
    companyName: '금호건설',
    market: 'KOSPI',
    marketCap: 485399493000,
  },
  {
    id: 191,
    code: '002995',
    companyName: '금호건설우',
    market: 'KOSPI',
    marketCap: 11193787800,
  },
  {
    id: 192,
    code: '011780',
    companyName: '금호석유',
    market: 'KOSPI',
    marketCap: 6657190483500,
  },
  {
    id: 193,
    code: '011785',
    companyName: '금호석유우',
    market: 'KOSPI',
    marketCap: 347700890000,
  },
  {
    id: 194,
    code: 214330,
    companyName: '금호에이치티',
    market: 'KOSPI',
    marketCap: 287466331995,
  },
  {
    id: 195,
    code: '001210',
    companyName: '금호전기',
    market: 'KOSPI',
    marketCap: 54793933605,
  },
  {
    id: 196,
    code: '073240',
    companyName: '금호타이어',
    market: 'KOSPI',
    marketCap: 2091254889360,
  },
  {
    id: 197,
    code: '092440',
    companyName: '기신정기',
    market: 'KOSPI',
    marketCap: 159140000000,
  },
  {
    id: 198,
    code: '000270',
    companyName: '기아',
    market: 'KOSPI',
    marketCap: 35509829197200,
  },
  {
    id: 199,
    code: '024110',
    companyName: '기업은행',
    market: 'KOSPI',
    marketCap: 7964016280900,
  },
  {
    id: 200,
    code: '013700',
    companyName: '까뮤이앤씨',
    market: 'KOSPI',
    marketCap: 122362678800,
  },
  {
    id: 201,
    code: '004540',
    companyName: '깨끗한나라',
    market: 'KOSPI',
    marketCap: 250629863890,
  },
  {
    id: 202,
    code: '004545',
    companyName: '깨끗한나라우',
    market: 'KOSPI',
    marketCap: 12486056000,
  },
  {
    id: 203,
    code: '001260',
    companyName: '남광토건',
    market: 'KOSPI',
    marketCap: 164142646700,
  },
  {
    id: 204,
    code: '008350',
    companyName: '남선알미늄',
    market: 'KOSPI',
    marketCap: 406009946650,
  },
  {
    id: 205,
    code: '008355',
    companyName: '남선알미우',
    market: 'KOSPI',
    marketCap: 12315600000,
  },
  {
    id: 206,
    code: '004270',
    companyName: '남성',
    market: 'KOSPI',
    marketCap: 138692572800,
  },
  {
    id: 207,
    code: '003920',
    companyName: '남양유업',
    market: 'KOSPI',
    marketCap: 424800000000,
  },
  {
    id: 208,
    code: '003925',
    companyName: '남양유업우',
    market: 'KOSPI',
    marketCap: 47498670000,
  },
  {
    id: 209,
    code: '025860',
    companyName: '남해화학',
    market: 'KOSPI',
    marketCap: 531563620100,
  },
  {
    id: 210,
    code: '005720',
    companyName: '넥센',
    market: 'KOSPI',
    marketCap: 280570439480,
  },
  {
    id: 211,
    code: '005725',
    companyName: '넥센우',
    market: 'KOSPI',
    marketCap: 13952325000,
  },
  {
    id: 212,
    code: '002350',
    companyName: '넥센타이어',
    market: 'KOSPI',
    marketCap: 851663887440,
  },
  {
    id: 213,
    code: '002355',
    companyName: '넥센타이어1우B',
    market: 'KOSPI',
    marketCap: 24505000000,
  },
  {
    id: 214,
    code: '003580',
    companyName: '넥스트사이언스',
    market: 'KOSPI',
    marketCap: 506438452800,
  },
  {
    id: 215,
    code: 251270,
    companyName: '넷마블',
    market: 'KOSPI',
    marketCap: 11560746019000,
  },
  {
    id: 216,
    code: '090350',
    companyName: '노루페인트',
    market: 'KOSPI',
    marketCap: 269000000000,
  },
  {
    id: 217,
    code: '090355',
    companyName: '노루페인트우',
    market: 'KOSPI',
    marketCap: 17784038400,
  },
  {
    id: 218,
    code: '000320',
    companyName: '노루홀딩스',
    market: 'KOSPI',
    marketCap: 192057131950,
  },
  {
    id: 219,
    code: '000325',
    companyName: '노루홀딩스우',
    market: 'KOSPI',
    marketCap: 18302700000,
  },
  {
    id: 220,
    code: '006280',
    companyName: '녹십자',
    market: 'KOSPI',
    marketCap: 3733848891000,
  },
  {
    id: 221,
    code: '005250',
    companyName: '녹십자홀딩스',
    market: 'KOSPI',
    marketCap: 1568390803500,
  },
  {
    id: 222,
    code: '005257',
    companyName: '녹십자홀딩스2우',
    market: 'KOSPI',
    marketCap: 73262734000,
  },
  {
    id: 223,
    code: '004370',
    companyName: '농심',
    market: 'KOSPI',
    marketCap: 1940362798000,
  },
  {
    id: 224,
    code: '072710',
    companyName: '농심홀딩스',
    market: 'KOSPI',
    marketCap: 390501918000,
  },
  {
    id: 225,
    code: '058730',
    companyName: '다스코',
    market: 'KOSPI',
    marketCap: 93236000000,
  },
  {
    id: 226,
    code: '023590',
    companyName: '다우기술',
    market: 'KOSPI',
    marketCap: 1224858644100,
  },
  {
    id: 227,
    code: '019680',
    companyName: '대교',
    market: 'KOSPI',
    marketCap: 463324589500,
  },
  {
    id: 228,
    code: '019685',
    companyName: '대교우B',
    market: 'KOSPI',
    marketCap: 81301953150,
  },
  {
    id: 229,
    code: '006370',
    companyName: '대구백화점',
    market: 'KOSPI',
    marketCap: 165570648300,
  },
  {
    id: 230,
    code: '008060',
    companyName: '대덕',
    market: 'KOSPI',
    marketCap: 298911123000,
  },
  {
    id: 231,
    code: '00806K',
    companyName: '대덕1우',
    market: 'KOSPI',
    marketCap: 17700412200,
  },
  {
    id: 232,
    code: 353200,
    companyName: '대덕전자',
    market: 'KOSPI',
    marketCap: 807966723750,
  },
  {
    id: 233,
    code: '35320K',
    companyName: '대덕전자1우',
    market: 'KOSPI',
    marketCap: 35204719200,
  },
  {
    id: 234,
    code: '000490',
    companyName: '대동',
    market: 'KOSPI',
    marketCap: 268128773000,
  },
  {
    id: 235,
    code: '008110',
    companyName: '대동전자',
    market: 'KOSPI',
    marketCap: 86755996690,
  },
  {
    id: 236,
    code: '005750',
    companyName: '대림B&Co',
    market: 'KOSPI',
    marketCap: 128376248000,
  },
  {
    id: 237,
    code: '006570',
    companyName: '대림통상',
    market: 'KOSPI',
    marketCap: 70644000000,
  },
  {
    id: 238,
    code: '001680',
    companyName: '대상',
    market: 'KOSPI',
    marketCap: 966679897500,
  },
  {
    id: 239,
    code: '001685',
    companyName: '대상우',
    market: 'KOSPI',
    marketCap: 29391283350,
  },
  {
    id: 240,
    code: '084690',
    companyName: '대상홀딩스',
    market: 'KOSPI',
    marketCap: 421876067700,
  },
  {
    id: 241,
    code: '084695',
    companyName: '대상홀딩스우',
    market: 'KOSPI',
    marketCap: 13702230000,
  },
  {
    id: 242,
    code: 128820,
    companyName: '대성산업',
    market: 'KOSPI',
    marketCap: 238390969060,
  },
  {
    id: 243,
    code: 117580,
    companyName: '대성에너지',
    market: 'KOSPI',
    marketCap: 187550000000,
  },
  {
    id: 244,
    code: '016710',
    companyName: '대성홀딩스',
    market: 'KOSPI',
    marketCap: 580829469900,
  },
  {
    id: 245,
    code: '003540',
    companyName: '대신증권',
    market: 'KOSPI',
    marketCap: 949462580000,
  },
  {
    id: 246,
    code: '003547',
    companyName: '대신증권2우B',
    market: 'KOSPI',
    marketCap: 167000000000,
  },
  {
    id: 247,
    code: '003545',
    companyName: '대신증권우',
    market: 'KOSPI',
    marketCap: 440700000000,
  },
  {
    id: 248,
    code: '009190',
    companyName: '대양금속',
    market: 'KOSPI',
    marketCap: 166386288750,
  },
  {
    id: 249,
    code: '014160',
    companyName: '대영포장',
    market: 'KOSPI',
    marketCap: 294291200535,
  },
  {
    id: 250,
    code: '047040',
    companyName: '대우건설',
    market: 'KOSPI',
    marketCap: 3566042234040,
  },
  {
    id: 251,
    code: '009320',
    companyName: '대우부품',
    market: 'KOSPI',
    marketCap: 128645212500,
  },
  {
    id: 252,
    code: '042660',
    companyName: '대우조선해양',
    market: 'KOSPI',
    marketCap: 3985848353350,
  },
  {
    id: 253,
    code: '003090',
    companyName: '대웅',
    market: 'KOSPI',
    marketCap: 2206488141000,
  },
  {
    id: 254,
    code: '069620',
    companyName: '대웅제약',
    market: 'KOSPI',
    marketCap: 1778539262500,
  },
  {
    id: 255,
    code: '000430',
    companyName: '대원강업',
    market: 'KOSPI',
    marketCap: 274040000000,
  },
  {
    id: 256,
    code: '006340',
    companyName: '대원전선',
    market: 'KOSPI',
    marketCap: 231160227585,
  },
  {
    id: 257,
    code: '006345',
    companyName: '대원전선우',
    market: 'KOSPI',
    marketCap: 16985376000,
  },
  {
    id: 258,
    code: '003220',
    companyName: '대원제약',
    market: 'KOSPI',
    marketCap: 338535926850,
  },
  {
    id: 259,
    code: '024890',
    companyName: '대원화성',
    market: 'KOSPI',
    marketCap: 126634896640,
  },
  {
    id: 260,
    code: '002880',
    companyName: '대유에이텍',
    market: 'KOSPI',
    marketCap: 144817640460,
  },
  {
    id: 261,
    code: '000300',
    companyName: '대유플러스',
    market: 'KOSPI',
    marketCap: 208699930540,
  },
  {
    id: 262,
    code: '012800',
    companyName: '대창',
    market: 'KOSPI',
    marketCap: 234231082430,
  },
  {
    id: 263,
    code: '015230',
    companyName: '대창단조',
    market: 'KOSPI',
    marketCap: 124717783950,
  },
  {
    id: 264,
    code: '001070',
    companyName: '대한방직',
    market: 'KOSPI',
    marketCap: 233200000000,
  },
  {
    id: 265,
    code: '006650',
    companyName: '대한유화',
    market: 'KOSPI',
    marketCap: 1670500000000,
  },
  {
    id: 266,
    code: '001440',
    companyName: '대한전선',
    market: 'KOSPI',
    marketCap: 2689325248260,
  },
  {
    id: 267,
    code: '084010',
    companyName: '대한제강',
    market: 'KOSPI',
    marketCap: 538531137900,
  },
  {
    id: 268,
    code: '001790',
    companyName: '대한제당',
    market: 'KOSPI',
    marketCap: 304968372000,
  },
  {
    id: 269,
    code: '001795',
    companyName: '대한제당우',
    market: 'KOSPI',
    marketCap: 28394488800,
  },
  {
    id: 270,
    code: '001130',
    companyName: '대한제분',
    market: 'KOSPI',
    marketCap: 297440000000,
  },
  {
    id: 271,
    code: '003490',
    companyName: '대한항공',
    market: 'KOSPI',
    marketCap: 11704170761250,
  },
  {
    id: 272,
    code: '003495',
    companyName: '대한항공우',
    market: 'KOSPI',
    marketCap: 49652491800,
  },
  {
    id: 273,
    code: '005880',
    companyName: '대한해운',
    market: 'KOSPI',
    marketCap: 895253544850,
  },
  {
    id: 274,
    code: '003830',
    companyName: '대한화섬',
    market: 'KOSPI',
    marketCap: 222440000000,
  },
  {
    id: 275,
    code: '016090',
    companyName: '대현',
    market: 'KOSPI',
    marketCap: 127533052800,
  },
  {
    id: 276,
    code: '069460',
    companyName: '대호에이엘',
    market: 'KOSPI',
    marketCap: 128785540300,
  },
  {
    id: 277,
    code: 192080,
    companyName: '더블유게임즈',
    market: 'KOSPI',
    marketCap: 1186992829200,
  },
  {
    id: 278,
    code: '012510',
    companyName: '더존비즈온',
    market: 'KOSPI',
    marketCap: 2536479259600,
  },
  {
    id: 279,
    code: '004830',
    companyName: '덕성',
    market: 'KOSPI',
    marketCap: 407680000000,
  },
  {
    id: 280,
    code: '004835',
    companyName: '덕성우',
    market: 'KOSPI',
    marketCap: 53592000000,
  },
  {
    id: 281,
    code: '024900',
    companyName: '덕양산업',
    market: 'KOSPI',
    marketCap: 128631671000,
  },
  {
    id: 282,
    code: 145720,
    companyName: '덴티움',
    market: 'KOSPI',
    marketCap: 733863429000,
  },
  {
    id: 283,
    code: '002150',
    companyName: '도화엔지니어링',
    market: 'KOSPI',
    marketCap: 312921600000,
  },
  {
    id: 284,
    code: '001230',
    companyName: '동국제강',
    market: 'KOSPI',
    marketCap: 2066118756050,
  },
  {
    id: 285,
    code: '023450',
    companyName: '동남합성',
    market: 'KOSPI',
    marketCap: 193857000000,
  },
  {
    id: 286,
    code: '004140',
    companyName: '동방',
    market: 'KOSPI',
    marketCap: 275405467740,
  },
  {
    id: 287,
    code: '007590',
    companyName: '동방아그로',
    market: 'KOSPI',
    marketCap: 117519689510,
  },
  {
    id: 288,
    code: '005960',
    companyName: '동부건설',
    market: 'KOSPI',
    marketCap: 314759227300,
  },
  {
    id: 289,
    code: '005965',
    companyName: '동부건설우',
    market: 'KOSPI',
    marketCap: 14446848000,
  },
  {
    id: 290,
    code: '026960',
    companyName: '동서',
    market: 'KOSPI',
    marketCap: 3065775000000,
  },
  {
    id: 291,
    code: '002210',
    companyName: '동성제약',
    market: 'KOSPI',
    marketCap: 275365233500,
  },
  {
    id: 292,
    code: 102260,
    companyName: '동성케미컬',
    market: 'KOSPI',
    marketCap: 310560800000,
  },
  {
    id: 293,
    code: '000640',
    companyName: '동아쏘시오홀딩스',
    market: 'KOSPI',
    marketCap: 791021625000,
  },
  {
    id: 294,
    code: 170900,
    companyName: '동아에스티',
    market: 'KOSPI',
    marketCap: 738838450000,
  },
  {
    id: 295,
    code: '028100',
    companyName: '동아지질',
    market: 'KOSPI',
    marketCap: 246675000000,
  },
  {
    id: 296,
    code: 282690,
    companyName: '동아타이어',
    market: 'KOSPI',
    marketCap: 178529195000,
  },
  {
    id: 297,
    code: '001520',
    companyName: '동양',
    market: 'KOSPI',
    marketCap: 537039141750,
  },
  {
    id: 298,
    code: '001527',
    companyName: '동양2우B',
    market: 'KOSPI',
    marketCap: 8115364700,
  },
  {
    id: 299,
    code: '001529',
    companyName: '동양3우B',
    market: 'KOSPI',
    marketCap: 7411037200,
  },
  {
    id: 300,
    code: '084670',
    companyName: '동양고속',
    market: 'KOSPI',
    marketCap: 76008686250,
  },
  {
    id: 301,
    code: '082640',
    companyName: '동양생명',
    market: 'KOSPI',
    marketCap: 929425449600,
  },
  {
    id: 302,
    code: '001525',
    companyName: '동양우',
    market: 'KOSPI',
    marketCap: 8123162650,
  },
  {
    id: 303,
    code: '008970',
    companyName: '동양철관',
    market: 'KOSPI',
    marketCap: 220532212950,
  },
  {
    id: 304,
    code: '092780',
    companyName: '동양피스톤',
    market: 'KOSPI',
    marketCap: 84014774800,
  },
  {
    id: 305,
    code: '049770',
    companyName: '동원F&B',
    market: 'KOSPI',
    marketCap: 870232462000,
  },
  {
    id: 306,
    code: '018500',
    companyName: '동원금속',
    market: 'KOSPI',
    marketCap: 58443666250,
  },
  {
    id: 307,
    code: '006040',
    companyName: '동원산업',
    market: 'KOSPI',
    marketCap: 991124249500,
  },
  {
    id: 308,
    code: '030720',
    companyName: '동원수산',
    market: 'KOSPI',
    marketCap: 44676528000,
  },
  {
    id: 309,
    code: '014820',
    companyName: '동원시스템즈',
    market: 'KOSPI',
    marketCap: 1159237622750,
  },
  {
    id: 310,
    code: '014825',
    companyName: '동원시스템즈우',
    market: 'KOSPI',
    marketCap: 10415301500,
  },
  {
    id: 311,
    code: 163560,
    companyName: '동일고무벨트',
    market: 'KOSPI',
    marketCap: 146645000000,
  },
  {
    id: 312,
    code: '004890',
    companyName: '동일산업',
    market: 'KOSPI',
    marketCap: 240581328000,
  },
  {
    id: 313,
    code: '002690',
    companyName: '동일제강',
    market: 'KOSPI',
    marketCap: 73200000000,
  },
  {
    id: 314,
    code: '000020',
    companyName: '동화약품',
    market: 'KOSPI',
    marketCap: 403609741500,
  },
  {
    id: 315,
    code: '000150',
    companyName: '두산',
    market: 'KOSPI',
    marketCap: 1478883232500,
  },
  {
    id: 316,
    code: '000157',
    companyName: '두산2우B',
    market: 'KOSPI',
    marketCap: 116094940000,
  },
  {
    id: 317,
    code: 241560,
    companyName: '두산밥캣',
    market: 'KOSPI',
    marketCap: 4736773093500,
  },
  {
    id: 318,
    code: '000155',
    companyName: '두산우',
    market: 'KOSPI',
    marketCap: 252176752200,
  },
  {
    id: 319,
    code: '042670',
    companyName: '두산인프라코어',
    market: 'KOSPI',
    marketCap: 3514170992000,
  },
  {
    id: 320,
    code: '034020',
    companyName: '두산중공업',
    market: 'KOSPI',
    marketCap: 10181806608200,
  },
  {
    id: 321,
    code: 336260,
    companyName: '두산퓨얼셀',
    market: 'KOSPI',
    marketCap: 3225566005500,
  },
  {
    id: 322,
    code: '33626K',
    companyName: '두산퓨얼셀1우',
    market: 'KOSPI',
    marketCap: 231200660000,
  },
  {
    id: 323,
    code: '33626L',
    companyName: '두산퓨얼셀2우B',
    market: 'KOSPI',
    marketCap: 74956130000,
  },
  {
    id: 324,
    code: '016740',
    companyName: '두올',
    market: 'KOSPI',
    marketCap: 138310734800,
  },
  {
    id: 325,
    code: 192650,
    companyName: '드림텍',
    market: 'KOSPI',
    marketCap: 731284635200,
  },
  {
    id: 326,
    code: '024090',
    companyName: '디씨엠',
    market: 'KOSPI',
    marketCap: 197145000000,
  },
  {
    id: 327,
    code: '003160',
    companyName: '디아이',
    market: 'KOSPI',
    marketCap: 235280983950,
  },
  {
    id: 328,
    code: '092200',
    companyName: '디아이씨',
    market: 'KOSPI',
    marketCap: 242664670560,
  },
  {
    id: 329,
    code: '013570',
    companyName: '디와이',
    market: 'KOSPI',
    marketCap: 170024829180,
  },
  {
    id: 330,
    code: 210540,
    companyName: '디와이파워',
    market: 'KOSPI',
    marketCap: 189365292200,
  },
  {
    id: 331,
    code: '007340',
    companyName: '디티알오토모티브',
    market: 'KOSPI',
    marketCap: 408754804500,
  },
  {
    id: 332,
    code: '026890',
    companyName: '디피씨',
    market: 'KOSPI',
    marketCap: 583494450000,
  },
  {
    id: 333,
    code: 115390,
    companyName: '락앤락',
    market: 'KOSPI',
    marketCap: 734155786300,
  },
  {
    id: 334,
    code: '032350',
    companyName: '롯데관광개발',
    market: 'KOSPI',
    marketCap: 1472107817500,
  },
  {
    id: 335,
    code: 330590,
    companyName: '롯데리츠',
    market: 'KOSPI',
    marketCap: 1484539881240,
  },
  {
    id: 336,
    code: '000400',
    companyName: '롯데손해보험',
    market: 'KOSPI',
    marketCap: 650154590400,
  },
  {
    id: 337,
    code: '023530',
    companyName: '롯데쇼핑',
    market: 'KOSPI',
    marketCap: 3323928712500,
  },
  {
    id: 338,
    code: '004000',
    companyName: '롯데정밀화학',
    market: 'KOSPI',
    marketCap: 1669260000000,
  },
  {
    id: 339,
    code: 286940,
    companyName: '롯데정보통신',
    market: 'KOSPI',
    marketCap: 626630671700,
  },
  {
    id: 340,
    code: 280360,
    companyName: '롯데제과',
    market: 'KOSPI',
    marketCap: 940049040500,
  },
  {
    id: 341,
    code: '004990',
    companyName: '롯데지주',
    market: 'KOSPI',
    marketCap: 4233087712950,
  },
  {
    id: 342,
    code: '00499K',
    companyName: '롯데지주우',
    market: 'KOSPI',
    marketCap: 63602921200,
  },
  {
    id: 343,
    code: '005300',
    companyName: '롯데칠성',
    market: 'KOSPI',
    marketCap: 1364218696000,
  },
  {
    id: 344,
    code: '005305',
    companyName: '롯데칠성우',
    market: 'KOSPI',
    marketCap: 65126040000,
  },
  {
    id: 345,
    code: '011170',
    companyName: '롯데케미칼',
    market: 'KOSPI',
    marketCap: 9494291063000,
  },
  {
    id: 346,
    code: '002270',
    companyName: '롯데푸드',
    market: 'KOSPI',
    marketCap: 538770120000,
  },
  {
    id: 347,
    code: '071840',
    companyName: '롯데하이마트',
    market: 'KOSPI',
    marketCap: 908896912000,
  },
  {
    id: 348,
    code: '027740',
    companyName: '마니커',
    market: 'KOSPI',
    marketCap: 157388767042,
  },
  {
    id: 349,
    code: 204320,
    companyName: '만도',
    market: 'KOSPI',
    marketCap: 3052212800000,
  },
  {
    id: 350,
    code: '001080',
    companyName: '만호제강',
    market: 'KOSPI',
    marketCap: 95865000000,
  },
  {
    id: 351,
    code: '088980',
    companyName: '맥쿼리인프라',
    market: 'KOSPI',
    marketCap: 4708234935250,
  },
  {
    id: 352,
    code: '094800',
    companyName: '맵스리얼티1',
    market: 'KOSPI',
    marketCap: 456280736865,
  },
  {
    id: 353,
    code: 138040,
    companyName: '메리츠금융지주',
    market: 'KOSPI',
    marketCap: 2358598774400,
  },
  {
    id: 354,
    code: '008560',
    companyName: '메리츠증권',
    market: 'KOSPI',
    marketCap: 2982571283125,
  },
  {
    id: 355,
    code: '000060',
    companyName: '메리츠화재',
    market: 'KOSPI',
    marketCap: 2159187500000,
  },
  {
    id: 356,
    code: '090370',
    companyName: '메타랩스',
    market: 'KOSPI',
    marketCap: 76235454060,
  },
  {
    id: 357,
    code: '017180',
    companyName: '명문제약',
    market: 'KOSPI',
    marketCap: 191157946020,
  },
  {
    id: 358,
    code: '009900',
    companyName: '명신산업',
    market: 'KOSPI',
    marketCap: 1109192064000,
  },
  {
    id: 359,
    code: '012690',
    companyName: '모나리자',
    market: 'KOSPI',
    marketCap: 201141902500,
  },
  {
    id: 360,
    code: '005360',
    companyName: '모나미',
    market: 'KOSPI',
    marketCap: 113383842000,
  },
  {
    id: 361,
    code: 204210,
    companyName: '모두투어리츠',
    market: 'KOSPI',
    marketCap: 39368879450,
  },
  {
    id: 362,
    code: '009680',
    companyName: '모토닉',
    market: 'KOSPI',
    marketCap: 404250000000,
  },
  {
    id: 363,
    code: '009580',
    companyName: '무림P&P',
    market: 'KOSPI',
    marketCap: 344273148480,
  },
  {
    id: 364,
    code: '009200',
    companyName: '무림페이퍼',
    market: 'KOSPI',
    marketCap: 158739517650,
  },
  {
    id: 365,
    code: '033920',
    companyName: '무학',
    market: 'KOSPI',
    marketCap: 274170000000,
  },
  {
    id: 366,
    code: '008420',
    companyName: '문배철강',
    market: 'KOSPI',
    marketCap: 109898786800,
  },
  {
    id: 367,
    code: '025560',
    companyName: '미래산업',
    market: 'KOSPI',
    marketCap: 79690689750,
  },
  {
    id: 368,
    code: '007120',
    companyName: '미래아이앤지',
    market: 'KOSPI',
    marketCap: 85459537680,
  },
  {
    id: 369,
    code: 357250,
    companyName: '미래에셋맵스리츠',
    market: 'KOSPI',
    marketCap: 104319000000,
  },
  {
    id: 370,
    code: '085620',
    companyName: '미래에셋생명',
    market: 'KOSPI',
    marketCap: 757629288920,
  },
  {
    id: 371,
    code: '006800',
    companyName: '미래에셋증권',
    market: 'KOSPI',
    marketCap: 6048212204160,
  },
  {
    id: 372,
    code: '00680K',
    companyName: '미래에셋증권2우B',
    market: 'KOSPI',
    marketCap: 675500000000,
  },
  {
    id: 373,
    code: '006805',
    companyName: '미래에셋증권우',
    market: 'KOSPI',
    marketCap: 88958740000,
  },
  {
    id: 374,
    code: '002840',
    companyName: '미원상사',
    market: 'KOSPI',
    marketCap: 1078490000000,
  },
  {
    id: 375,
    code: 268280,
    companyName: '미원에스씨',
    market: 'KOSPI',
    marketCap: 910350000000,
  },
  {
    id: 376,
    code: 107590,
    companyName: '미원홀딩스',
    market: 'KOSPI',
    marketCap: 295800000000,
  },
  {
    id: 377,
    code: 134380,
    companyName: '미원화학',
    market: 'KOSPI',
    marketCap: 180339976000,
  },
  {
    id: 378,
    code: '003650',
    companyName: '미창석유',
    market: 'KOSPI',
    marketCap: 149959726400,
  },
  {
    id: 379,
    code: 155900,
    companyName: '바다로19호',
    market: 'KOSPI',
    marketCap: 41268010860,
  },
  {
    id: 380,
    code: '003610',
    companyName: '방림',
    market: 'KOSPI',
    marketCap: 112978620300,
  },
  {
    id: 381,
    code: '001340',
    companyName: '백광산업',
    market: 'KOSPI',
    marketCap: 281189227820,
  },
  {
    id: 382,
    code: '035150',
    companyName: '백산',
    market: 'KOSPI',
    marketCap: 232078000000,
  },
  {
    id: 383,
    code: '002410',
    companyName: '범양건영',
    market: 'KOSPI',
    marketCap: 104542644220,
  },
  {
    id: 384,
    code: '096300',
    companyName: '베트남개발1',
    market: 'KOSPI',
    marketCap: 32079489163,
  },
  {
    id: 385,
    code: '007210',
    companyName: '벽산',
    market: 'KOSPI',
    marketCap: 300292800000,
  },
  {
    id: 386,
    code: '002760',
    companyName: '보락',
    market: 'KOSPI',
    marketCap: 143161000000,
  },
  {
    id: 387,
    code: '003850',
    companyName: '보령제약',
    market: 'KOSPI',
    marketCap: 1227426000000,
  },
  {
    id: 388,
    code: '000890',
    companyName: '보해양조',
    market: 'KOSPI',
    marketCap: 163459244905,
  },
  {
    id: 389,
    code: '003000',
    companyName: '부광약품',
    market: 'KOSPI',
    marketCap: 1453239352050,
  },
  {
    id: 390,
    code: '001270',
    companyName: '부국증권',
    market: 'KOSPI',
    marketCap: 263395104400,
  },
  {
    id: 391,
    code: '001275',
    companyName: '부국증권우',
    market: 'KOSPI',
    marketCap: 67650000000,
  },
  {
    id: 392,
    code: '026940',
    companyName: '부국철강',
    market: 'KOSPI',
    marketCap: 96700000000,
  },
  {
    id: 393,
    code: '015350',
    companyName: '부산가스',
    market: 'KOSPI',
    marketCap: 705100000000,
  },
  {
    id: 394,
    code: '011390',
    companyName: '부산산업',
    market: 'KOSPI',
    marketCap: 166320000000,
  },
  {
    id: 395,
    code: '005030',
    companyName: '부산주공',
    market: 'KOSPI',
    marketCap: 37804928805,
  },
  {
    id: 396,
    code: '002070',
    companyName: '비비안',
    market: 'KOSPI',
    marketCap: 105246561625,
  },
  {
    id: 397,
    code: 100220,
    companyName: '비상교육',
    market: 'KOSPI',
    marketCap: 146213336250,
  },
  {
    id: 398,
    code: '030790',
    companyName: '비케이탑스',
    market: 'KOSPI',
    marketCap: 118019362510,
  },
  {
    id: 399,
    code: '005180',
    companyName: '빙그레',
    market: 'KOSPI',
    marketCap: 640330665000,
  },
  {
    id: 400,
    code: '003960',
    companyName: '사조대림',
    market: 'KOSPI',
    marketCap: 238276142000,
  },
  {
    id: 401,
    code: '008040',
    companyName: '사조동아원',
    market: 'KOSPI',
    marketCap: 202542501000,
  },
  {
    id: 402,
    code: '007160',
    companyName: '사조산업',
    market: 'KOSPI',
    marketCap: 311500000000,
  },
  {
    id: 403,
    code: '014710',
    companyName: '사조씨푸드',
    market: 'KOSPI',
    marketCap: 116741721540,
  },
  {
    id: 404,
    code: '006090',
    companyName: '사조오양',
    market: 'KOSPI',
    marketCap: 111188320200,
  },
  {
    id: 405,
    code: '001470',
    companyName: '삼부토건',
    market: 'KOSPI',
    marketCap: 412639104000,
  },
  {
    id: 406,
    code: '006400',
    companyName: '삼성SDI',
    market: 'KOSPI',
    marketCap: 44696944500000,
  },
  {
    id: 407,
    code: '006405',
    companyName: '삼성SDI우',
    market: 'KOSPI',
    marketCap: 686796852000,
  },
  {
    id: 408,
    code: '006660',
    companyName: '삼성공조',
    market: 'KOSPI',
    marketCap: 125145235600,
  },
  {
    id: 409,
    code: '028260',
    companyName: '삼성물산',
    market: 'KOSPI',
    marketCap: 25510086556500,
  },
  {
    id: 410,
    code: '02826K',
    companyName: '삼성물산우B',
    market: 'KOSPI',
    marketCap: 216447525000,
  },
  {
    id: 411,
    code: 207940,
    companyName: '삼성바이오로직스',
    market: 'KOSPI',
    marketCap: 55115445000000,
  },
  {
    id: 412,
    code: '032830',
    companyName: '삼성생명',
    market: 'KOSPI',
    marketCap: 16240000000000,
  },
  {
    id: 413,
    code: '018260',
    companyName: '삼성에스디에스',
    market: 'KOSPI',
    marketCap: 14508337500000,
  },
  {
    id: 414,
    code: '028050',
    companyName: '삼성엔지니어링',
    market: 'KOSPI',
    marketCap: 4566800000000,
  },
  {
    id: 415,
    code: '009150',
    companyName: '삼성전기',
    market: 'KOSPI',
    marketCap: 13146090496000,
  },
  {
    id: 416,
    code: '009155',
    companyName: '삼성전기우',
    market: 'KOSPI',
    marketCap: 313954272000,
  },
  {
    id: 417,
    code: '005930',
    companyName: '삼성전자',
    market: 'KOSPI',
    marketCap: 482955408295000,
  },
  {
    id: 418,
    code: '005935',
    companyName: '삼성전자우',
    market: 'KOSPI',
    marketCap: 61140481810000,
  },
  {
    id: 419,
    code: '001360',
    companyName: '삼성제약',
    market: 'KOSPI',
    marketCap: 589683053620,
  },
  {
    id: 420,
    code: '010140',
    companyName: '삼성중공업',
    market: 'KOSPI',
    marketCap: 4347000000000,
  },
  {
    id: 421,
    code: '010145',
    companyName: '삼성중공우',
    market: 'KOSPI',
    marketCap: 35314837500,
  },
  {
    id: 422,
    code: '016360',
    companyName: '삼성증권',
    market: 'KOSPI',
    marketCap: 4031895000000,
  },
  {
    id: 423,
    code: '068290',
    companyName: '삼성출판사',
    market: 'KOSPI',
    marketCap: 433000000000,
  },
  {
    id: 424,
    code: '029780',
    companyName: '삼성카드',
    market: 'KOSPI',
    marketCap: 3973959961300,
  },
  {
    id: 425,
    code: '000810',
    companyName: '삼성화재',
    market: 'KOSPI',
    marketCap: 10043465444000,
  },
  {
    id: 426,
    code: '000815',
    companyName: '삼성화재우',
    market: 'KOSPI',
    marketCap: 521892000000,
  },
  {
    id: 427,
    code: '006110',
    companyName: '삼아알미늄',
    market: 'KOSPI',
    marketCap: 132550000000,
  },
  {
    id: 428,
    code: 145990,
    companyName: '삼양사',
    market: 'KOSPI',
    marketCap: 671405529900,
  },
  {
    id: 429,
    code: 145995,
    companyName: '삼양사우',
    market: 'KOSPI',
    marketCap: 18392479000,
  },
  {
    id: 430,
    code: '003230',
    companyName: '삼양식품',
    market: 'KOSPI',
    marketCap: 678724651500,
  },
  {
    id: 431,
    code: '002170',
    companyName: '삼양통상',
    market: 'KOSPI',
    marketCap: 236700000000,
  },
  {
    id: 432,
    code: 272550,
    companyName: '삼양패키징',
    market: 'KOSPI',
    marketCap: 402847943400,
  },
  {
    id: 433,
    code: '000070',
    companyName: '삼양홀딩스',
    market: 'KOSPI',
    marketCap: 907812726000,
  },
  {
    id: 434,
    code: '000075',
    companyName: '삼양홀딩스우',
    market: 'KOSPI',
    marketCap: 22591509400,
  },
  {
    id: 435,
    code: '002810',
    companyName: '삼영무역',
    market: 'KOSPI',
    marketCap: 311168073800,
  },
  {
    id: 436,
    code: '005680',
    companyName: '삼영전자',
    market: 'KOSPI',
    marketCap: 263000000000,
  },
  {
    id: 437,
    code: '003720',
    companyName: '삼영화학',
    market: 'KOSPI',
    marketCap: 102680000000,
  },
  {
    id: 438,
    code: '023000',
    companyName: '삼원강재',
    market: 'KOSPI',
    marketCap: 153600000000,
  },
  {
    id: 439,
    code: '004380',
    companyName: '삼익THK',
    market: 'KOSPI',
    marketCap: 285600000000,
  },
  {
    id: 440,
    code: '002450',
    companyName: '삼익악기',
    market: 'KOSPI',
    marketCap: 184230412025,
  },
  {
    id: 441,
    code: '004440',
    companyName: '삼일씨엔에스',
    market: 'KOSPI',
    marketCap: 171881284500,
  },
  {
    id: 442,
    code: '000520',
    companyName: '삼일제약',
    market: 'KOSPI',
    marketCap: 125659776940,
  },
  {
    id: 443,
    code: '009770',
    companyName: '삼정펄프',
    market: 'KOSPI',
    marketCap: 90498950200,
  },
  {
    id: 444,
    code: '005500',
    companyName: '삼진제약',
    market: 'KOSPI',
    marketCap: 379470000000,
  },
  {
    id: 445,
    code: '004690',
    companyName: '삼천리',
    market: 'KOSPI',
    marketCap: 415640062500,
  },
  {
    id: 446,
    code: '010960',
    companyName: '삼호개발',
    market: 'KOSPI',
    marketCap: 159500000000,
  },
  {
    id: 447,
    code: '004450',
    companyName: '삼화왕관',
    market: 'KOSPI',
    marketCap: 99101434000,
  },
  {
    id: 448,
    code: '009470',
    companyName: '삼화전기',
    market: 'KOSPI',
    marketCap: 222885734000,
  },
  {
    id: 449,
    code: '011230',
    companyName: '삼화전자',
    market: 'KOSPI',
    marketCap: 44237086200,
  },
  {
    id: 450,
    code: '001820',
    companyName: '삼화콘덴서',
    market: 'KOSPI',
    marketCap: 628897500000,
  },
  {
    id: 451,
    code: '000390',
    companyName: '삼화페인트',
    market: 'KOSPI',
    marketCap: 357725617350,
  },
  {
    id: 452,
    code: '001290',
    companyName: '상상인증권',
    market: 'KOSPI',
    marketCap: 139043552970,
  },
  {
    id: 453,
    code: '041650',
    companyName: '상신브레이크',
    market: 'KOSPI',
    marketCap: 108645537000,
  },
  {
    id: 454,
    code: '075180',
    companyName: '새론오토모티브',
    market: 'KOSPI',
    marketCap: 122496000000,
  },
  {
    id: 455,
    code: '007540',
    companyName: '샘표',
    market: 'KOSPI',
    marketCap: 168234300000,
  },
  {
    id: 456,
    code: 248170,
    companyName: '샘표식품',
    market: 'KOSPI',
    marketCap: 272726674200,
  },
  {
    id: 457,
    code: '007860',
    companyName: '서연',
    market: 'KOSPI',
    marketCap: 469596880000,
  },
  {
    id: 458,
    code: 200880,
    companyName: '서연이화',
    market: 'KOSPI',
    marketCap: 257581004610,
  },
  {
    id: 459,
    code: '017390',
    companyName: '서울가스',
    market: 'KOSPI',
    marketCap: 715000000000,
  },
  {
    id: 460,
    code: '004410',
    companyName: '서울식품',
    market: 'KOSPI',
    marketCap: 153073809408,
  },
  {
    id: 461,
    code: '004415',
    companyName: '서울식품우',
    market: 'KOSPI',
    marketCap: 12546180000,
  },
  {
    id: 462,
    code: '021050',
    companyName: '서원',
    market: 'KOSPI',
    marketCap: 129843003650,
  },
  {
    id: 463,
    code: '008490',
    companyName: '서흥',
    market: 'KOSPI',
    marketCap: 647870328000,
  },
  {
    id: 464,
    code: '007610',
    companyName: '선도전기',
    market: 'KOSPI',
    marketCap: 76590000000,
  },
  {
    id: 465,
    code: 136490,
    companyName: '선진',
    market: 'KOSPI',
    marketCap: 410198169000,
  },
  {
    id: 466,
    code: '014910',
    companyName: '성문전자',
    market: 'KOSPI',
    marketCap: 56024713875,
  },
  {
    id: 467,
    code: '014915',
    companyName: '성문전자우',
    market: 'KOSPI',
    marketCap: 7170000000,
  },
  {
    id: 468,
    code: '003080',
    companyName: '성보화학',
    market: 'KOSPI',
    marketCap: 109909800000,
  },
  {
    id: 469,
    code: '004980',
    companyName: '성신양회',
    market: 'KOSPI',
    marketCap: 300321894250,
  },
  {
    id: 470,
    code: '004985',
    companyName: '성신양회우',
    market: 'KOSPI',
    marketCap: 15632456250,
  },
  {
    id: 471,
    code: '011300',
    companyName: '성안',
    market: 'KOSPI',
    marketCap: 44747135820,
  },
  {
    id: 472,
    code: '000180',
    companyName: '성창기업지주',
    market: 'KOSPI',
    marketCap: 201233366000,
  },
  {
    id: 473,
    code: '002420',
    companyName: '세기상사',
    market: 'KOSPI',
    marketCap: 25107667200,
  },
  {
    id: 474,
    code: '004360',
    companyName: '세방',
    market: 'KOSPI',
    marketCap: 285768612000,
  },
  {
    id: 475,
    code: '004365',
    companyName: '세방우',
    market: 'KOSPI',
    marketCap: 32612970000,
  },
  {
    id: 476,
    code: '004490',
    companyName: '세방전지',
    market: 'KOSPI',
    marketCap: 1449000000000,
  },
  {
    id: 477,
    code: '001430',
    companyName: '세아베스틸',
    market: 'KOSPI',
    marketCap: 1104553265200,
  },
  {
    id: 478,
    code: 306200,
    companyName: '세아제강',
    market: 'KOSPI',
    marketCap: 310574850000,
  },
  {
    id: 479,
    code: '003030',
    companyName: '세아제강지주',
    market: 'KOSPI',
    marketCap: 295714309800,
  },
  {
    id: 480,
    code: '019440',
    companyName: '세아특수강',
    market: 'KOSPI',
    marketCap: 146547000000,
  },
  {
    id: 481,
    code: '058650',
    companyName: '세아홀딩스',
    market: 'KOSPI',
    marketCap: 410000000000,
  },
  {
    id: 482,
    code: '013000',
    companyName: '세우글로벌',
    market: 'KOSPI',
    marketCap: 70901426570,
  },
  {
    id: 483,
    code: '091090',
    companyName: '세원이앤씨',
    market: 'KOSPI',
    marketCap: 145671566385,
  },
  {
    id: 484,
    code: '021820',
    companyName: '세원정공',
    market: 'KOSPI',
    marketCap: 80900000000,
  },
  {
    id: 485,
    code: '067830',
    companyName: '세이브존I&C',
    market: 'KOSPI',
    marketCap: 161290717350,
  },
  {
    id: 486,
    code: '033530',
    companyName: '세종공업',
    market: 'KOSPI',
    marketCap: 246493714460,
  },
  {
    id: 487,
    code: '075580',
    companyName: '세진중공업',
    market: 'KOSPI',
    marketCap: 415001028800,
  },
  {
    id: 488,
    code: '027970',
    companyName: '세하',
    market: 'KOSPI',
    marketCap: 126926408385,
  },
  {
    id: 489,
    code: 145210,
    companyName: '세화아이엠씨',
    market: 'KOSPI',
    marketCap: 145787228875,
  },
  {
    id: 490,
    code: 308170,
    companyName: '센트랄모텍',
    market: 'KOSPI',
    marketCap: 234192000000,
  },
  {
    id: 491,
    code: '012600',
    companyName: '센트럴인사이트',
    market: 'KOSPI',
    marketCap: 33838831080,
  },
  {
    id: 492,
    code: '068270',
    companyName: '셀트리온',
    market: 'KOSPI',
    marketCap: 37099547646000,
  },
  {
    id: 493,
    code: 336370,
    companyName: '솔루스첨단소재',
    market: 'KOSPI',
    marketCap: 1529487900000,
  },
  {
    id: 494,
    code: '33637K',
    companyName: '솔루스첨단소재1우',
    market: 'KOSPI',
    marketCap: 125597120000,
  },
  {
    id: 495,
    code: '33637L',
    companyName: '솔루스첨단소재2우B',
    market: 'KOSPI',
    marketCap: 48393870000,
  },
  {
    id: 496,
    code: 248070,
    companyName: '솔루엠',
    market: 'KOSPI',
    marketCap: 1453030163250,
  },
  {
    id: 497,
    code: '004430',
    companyName: '송원산업',
    market: 'KOSPI',
    marketCap: 488400000000,
  },
  {
    id: 498,
    code: '017550',
    companyName: '수산중공업',
    market: 'KOSPI',
    marketCap: 253730266100,
  },
  {
    id: 499,
    code: '053210',
    companyName: '스카이라이프',
    market: 'KOSPI',
    marketCap: 511695036200,
  },
  {
    id: 500,
    code: 134790,
    companyName: '시디즈',
    market: 'KOSPI',
    marketCap: 152000000000,
  },
  {
    id: 501,
    code: '016590',
    companyName: '신대양제지',
    market: 'KOSPI',
    marketCap: 365501227400,
  },
  {
    id: 502,
    code: '029530',
    companyName: '신도리코',
    market: 'KOSPI',
    marketCap: 352297013550,
  },
  {
    id: 503,
    code: '004970',
    companyName: '신라교역',
    market: 'KOSPI',
    marketCap: 228800000000,
  },
  {
    id: 504,
    code: '011930',
    companyName: '신성이엔지',
    market: 'KOSPI',
    marketCap: 597067271640,
  },
  {
    id: 505,
    code: '005390',
    companyName: '신성통상',
    market: 'KOSPI',
    marketCap: 454837054350,
  },
  {
    id: 506,
    code: '004170',
    companyName: '신세계',
    market: 'KOSPI',
    marketCap: 2869870261500,
  },
  {
    id: 507,
    code: '035510',
    companyName: '신세계 I&C',
    market: 'KOSPI',
    marketCap: 344000000000,
  },
  {
    id: 508,
    code: '034300',
    companyName: '신세계건설',
    market: 'KOSPI',
    marketCap: 181600000000,
  },
  {
    id: 509,
    code: '031430',
    companyName: '신세계인터내셔날',
    market: 'KOSPI',
    marketCap: 1542240000000,
  },
  {
    id: 510,
    code: '031440',
    companyName: '신세계푸드',
    market: 'KOSPI',
    marketCap: 396929200000,
  },
  {
    id: 511,
    code: '006880',
    companyName: '신송홀딩스',
    market: 'KOSPI',
    marketCap: 64230698940,
  },
  {
    id: 512,
    code: '005800',
    companyName: '신영와코루',
    market: 'KOSPI',
    marketCap: 114750000000,
  },
  {
    id: 513,
    code: '001720',
    companyName: '신영증권',
    market: 'KOSPI',
    marketCap: 606350910200,
  },
  {
    id: 514,
    code: '001725',
    companyName: '신영증권우',
    market: 'KOSPI',
    marketCap: 457083842400,
  },
  {
    id: 515,
    code: '009270',
    companyName: '신원',
    market: 'KOSPI',
    marketCap: 180290097880,
  },
  {
    id: 516,
    code: '009275',
    companyName: '신원우',
    market: 'KOSPI',
    marketCap: 4754776000,
  },
  {
    id: 517,
    code: '002700',
    companyName: '신일전자',
    market: 'KOSPI',
    marketCap: 144581705235,
  },
  {
    id: 518,
    code: '019170',
    companyName: '신풍제약',
    market: 'KOSPI',
    marketCap: 4339470681000,
  },
  {
    id: 519,
    code: '019175',
    companyName: '신풍제약우',
    market: 'KOSPI',
    marketCap: 287100000000,
  },
  {
    id: 520,
    code: '002870',
    companyName: '신풍제지',
    market: 'KOSPI',
    marketCap: 126725287500,
  },
  {
    id: 521,
    code: 293940,
    companyName: '신한알파리츠',
    market: 'KOSPI',
    marketCap: 430153611060,
  },
  {
    id: 522,
    code: '055550',
    companyName: '신한지주',
    market: 'KOSPI',
    marketCap: 21516371424100,
  },
  {
    id: 523,
    code: '001770',
    companyName: '신화실업',
    market: 'KOSPI',
    marketCap: 36750059500,
  },
  {
    id: 524,
    code: '004080',
    companyName: '신흥',
    market: 'KOSPI',
    marketCap: 146775000000,
  },
  {
    id: 525,
    code: 102280,
    companyName: '쌍방울',
    market: 'KOSPI',
    marketCap: 234328872008,
  },
  {
    id: 526,
    code: '003410',
    companyName: '쌍용C&E',
    market: 'KOSPI',
    marketCap: 4257613577750,
  },
  {
    id: 527,
    code: '003620',
    companyName: '쌍용차',
    market: 'KOSPI',
    marketCap: 415056805540,
  },
  {
    id: 528,
    code: '004770',
    companyName: '써니전자',
    market: 'KOSPI',
    marketCap: 130668791250,
  },
  {
    id: 529,
    code: '015540',
    companyName: '쎌마테라퓨틱스',
    market: 'KOSPI',
    marketCap: 86229499400,
  },
  {
    id: 530,
    code: '004920',
    companyName: '씨아이테크',
    market: 'KOSPI',
    marketCap: 59328302715,
  },
  {
    id: 531,
    code: 112610,
    companyName: '씨에스윈드',
    market: 'KOSPI',
    marketCap: 3339975117600,
  },
  {
    id: 532,
    code: '008700',
    companyName: '아남전자',
    market: 'KOSPI',
    marketCap: 230988835900,
  },
  {
    id: 533,
    code: '002790',
    companyName: '아모레G',
    market: 'KOSPI',
    marketCap: 6225592590000,
  },
  {
    id: 534,
    code: '00279K',
    companyName: '아모레G3우(전환)',
    market: 'KOSPI',
    marketCap: 358865320000,
  },
  {
    id: 535,
    code: '002795',
    companyName: '아모레G우',
    market: 'KOSPI',
    marketCap: 173981790000,
  },
  {
    id: 536,
    code: '090430',
    companyName: '아모레퍼시픽',
    market: 'KOSPI',
    marketCap: 16631440405000,
  },
  {
    id: 537,
    code: '090435',
    companyName: '아모레퍼시픽우',
    market: 'KOSPI',
    marketCap: 1055783000000,
  },
  {
    id: 538,
    code: '002030',
    companyName: '아세아',
    market: 'KOSPI',
    marketCap: 281546584000,
  },
  {
    id: 539,
    code: 183190,
    companyName: '아세아시멘트',
    market: 'KOSPI',
    marketCap: 500611456500,
  },
  {
    id: 540,
    code: '002310',
    companyName: '아세아제지',
    market: 'KOSPI',
    marketCap: 487233708800,
  },
  {
    id: 541,
    code: '012170',
    companyName: '아센디오',
    market: 'KOSPI',
    marketCap: 184811760360,
  },
  {
    id: 542,
    code: 267850,
    companyName: '아시아나IDT',
    market: 'KOSPI',
    marketCap: 270285000000,
  },
  {
    id: 543,
    code: '020560',
    companyName: '아시아나항공',
    market: 'KOSPI',
    marketCap: 1279882340800,
  },
  {
    id: 544,
    code: 122900,
    companyName: '아이마켓코리아',
    market: 'KOSPI',
    marketCap: 384431660000,
  },
  {
    id: 545,
    code: '010780',
    companyName: '아이에스동서',
    market: 'KOSPI',
    marketCap: 1807217451000,
  },
  {
    id: 546,
    code: '001780',
    companyName: '알루코',
    market: 'KOSPI',
    marketCap: 433422142400,
  },
  {
    id: 547,
    code: '018250',
    companyName: '애경산업',
    market: 'KOSPI',
    marketCap: 754003644250,
  },
  {
    id: 548,
    code: 161000,
    companyName: '애경유화',
    market: 'KOSPI',
    marketCap: 392490000000,
  },
  {
    id: 549,
    code: '011090',
    companyName: '에넥스',
    market: 'KOSPI',
    marketCap: 197372498890,
  },
  {
    id: 550,
    code: '005850',
    companyName: '에스엘',
    market: 'KOSPI',
    marketCap: 1670243548050,
  },
  {
    id: 551,
    code: '012750',
    companyName: '에스원',
    market: 'KOSPI',
    marketCap: 3096933007000,
  },
  {
    id: 552,
    code: '023960',
    companyName: '에쓰씨엔지니어링',
    market: 'KOSPI',
    marketCap: 128296112150,
  },
  {
    id: 553,
    code: 298690,
    companyName: '에어부산',
    market: 'KOSPI',
    marketCap: 293400250000,
  },
  {
    id: 554,
    code: 140910,
    companyName: '에이리츠',
    market: 'KOSPI',
    marketCap: 63682589000,
  },
  {
    id: 555,
    code: '078520',
    companyName: '에이블씨엔씨',
    market: 'KOSPI',
    marketCap: 298719721950,
  },
  {
    id: 556,
    code: '015260',
    companyName: '에이엔피',
    market: 'KOSPI',
    marketCap: 38851008020,
  },
  {
    id: 557,
    code: '007460',
    companyName: '에이프로젠 KIC',
    market: 'KOSPI',
    marketCap: 454088387040,
  },
  {
    id: 558,
    code: '003060',
    companyName: '에이프로젠제약',
    market: 'KOSPI',
    marketCap: 490248193410,
  },
  {
    id: 559,
    code: 244920,
    companyName: '에이플러스에셋',
    market: 'KOSPI',
    marketCap: 188322082690,
  },
  {
    id: 560,
    code: '036570',
    companyName: '엔씨소프트',
    market: 'KOSPI',
    marketCap: 18090114128000,
  },
  {
    id: 561,
    code: 138250,
    companyName: '엔에스쇼핑',
    market: 'KOSPI',
    marketCap: 471744000000,
  },
  {
    id: 562,
    code: 380440,
    companyName: '엔에이치스팩19호',
    market: 'KOSPI',
    marketCap: 112581550000,
  },
  {
    id: 563,
    code: '085310',
    companyName: '엔케이',
    market: 'KOSPI',
    marketCap: 111576776040,
  },
  {
    id: 564,
    code: '009810',
    companyName: '엔케이물산',
    market: 'KOSPI',
    marketCap: 73163512410,
  },
  {
    id: 565,
    code: 900140,
    companyName: '엘브이엠씨홀딩스',
    market: 'KOSPI',
    marketCap: 378924681570,
  },
  {
    id: 566,
    code: '014440',
    companyName: '영보화학',
    market: 'KOSPI',
    marketCap: 107000000000,
  },
  {
    id: 567,
    code: 111770,
    companyName: '영원무역',
    market: 'KOSPI',
    marketCap: 1954135738800,
  },
  {
    id: 568,
    code: '009970',
    companyName: '영원무역홀딩스',
    market: 'KOSPI',
    marketCap: 657917314000,
  },
  {
    id: 569,
    code: '003520',
    companyName: '영진약품',
    market: 'KOSPI',
    marketCap: 1099185313310,
  },
  {
    id: 570,
    code: '000670',
    companyName: '영풍',
    market: 'KOSPI',
    marketCap: 1197326000000,
  },
  {
    id: 571,
    code: '006740',
    companyName: '영풍제지',
    market: 'KOSPI',
    marketCap: 163836000000,
  },
  {
    id: 572,
    code: '012280',
    companyName: '영화금속',
    market: 'KOSPI',
    marketCap: 111060570400,
  },
  {
    id: 573,
    code: '012160',
    companyName: '영흥',
    market: 'KOSPI',
    marketCap: 122772375000,
  },
  {
    id: 574,
    code: '015360',
    companyName: '예스코홀딩스',
    market: 'KOSPI',
    marketCap: 236400000000,
  },
  {
    id: 575,
    code: '007310',
    companyName: '오뚜기',
    market: 'KOSPI',
    marketCap: 2019589550000,
  },
  {
    id: 576,
    code: '002630',
    companyName: '오리엔트바이오',
    market: 'KOSPI',
    marketCap: 202184023525,
  },
  {
    id: 577,
    code: 271560,
    companyName: '오리온',
    market: 'KOSPI',
    marketCap: 4665263576000,
  },
  {
    id: 578,
    code: '001800',
    companyName: '오리온홀딩스',
    market: 'KOSPI',
    marketCap: 1083765800600,
  },
  {
    id: 579,
    code: '070960',
    companyName: '용평리조트',
    market: 'KOSPI',
    marketCap: 293131997970,
  },
  {
    id: 580,
    code: 316140,
    companyName: '우리금융지주',
    market: 'KOSPI',
    marketCap: 8522758659400,
  },
  {
    id: 581,
    code: '033660',
    companyName: '우리금융캐피탈',
    market: 'KOSPI',
    marketCap: 710691741500,
  },
  {
    id: 582,
    code: 118000,
    companyName: '우리들휴브레인',
    market: 'KOSPI',
    marketCap: 101228702400,
  },
  {
    id: 583,
    code: '010050',
    companyName: '우리종금',
    market: 'KOSPI',
    marketCap: 961623746600,
  },
  {
    id: 584,
    code: '006980',
    companyName: '우성사료',
    market: 'KOSPI',
    marketCap: 115257000000,
  },
  {
    id: 585,
    code: '017370',
    companyName: '우신시스템',
    market: 'KOSPI',
    marketCap: 107869778060,
  },
  {
    id: 586,
    code: 105840,
    companyName: '우진',
    market: 'KOSPI',
    marketCap: 174783080400,
  },
  {
    id: 587,
    code: '010400',
    companyName: '우진아이엔에스',
    market: 'KOSPI',
    marketCap: 68902880000,
  },
  {
    id: 588,
    code: '049800',
    companyName: '우진플라임',
    market: 'KOSPI',
    marketCap: 85300000000,
  },
  {
    id: 589,
    code: '016880',
    companyName: '웅진',
    market: 'KOSPI',
    marketCap: 269886322000,
  },
  {
    id: 590,
    code: '095720',
    companyName: '웅진씽크빅',
    market: 'KOSPI',
    marketCap: 506493744225,
  },
  {
    id: 591,
    code: '005820',
    companyName: '원림',
    market: 'KOSPI',
    marketCap: 62504784000,
  },
  {
    id: 592,
    code: '010600',
    companyName: '웰바이오텍',
    market: 'KOSPI',
    marketCap: 193470338600,
  },
  {
    id: 593,
    code: '008600',
    companyName: '윌비스',
    market: 'KOSPI',
    marketCap: 176268724160,
  },
  {
    id: 594,
    code: '033270',
    companyName: '유나이티드제약',
    market: 'KOSPI',
    marketCap: 823612475400,
  },
  {
    id: 595,
    code: '014830',
    companyName: '유니드',
    market: 'KOSPI',
    marketCap: 717410330100,
  },
  {
    id: 596,
    code: '000910',
    companyName: '유니온',
    market: 'KOSPI',
    marketCap: 130513134840,
  },
  {
    id: 597,
    code: '047400',
    companyName: '유니온머티리얼',
    market: 'KOSPI',
    marketCap: 176190000000,
  },
  {
    id: 598,
    code: '011330',
    companyName: '유니켐',
    market: 'KOSPI',
    marketCap: 127114134550,
  },
  {
    id: 599,
    code: '077500',
    companyName: '유니퀘스트',
    market: 'KOSPI',
    marketCap: 311538915000,
  },
  {
    id: 600,
    code: '002920',
    companyName: '유성기업',
    market: 'KOSPI',
    marketCap: 98859975000,
  },
  {
    id: 601,
    code: '000700',
    companyName: '유수홀딩스',
    market: 'KOSPI',
    marketCap: 190626063840,
  },
  {
    id: 602,
    code: '003470',
    companyName: '유안타증권',
    market: 'KOSPI',
    marketCap: 977025239520,
  },
  {
    id: 603,
    code: '003475',
    companyName: '유안타증권우',
    market: 'KOSPI',
    marketCap: 63850888340,
  },
  {
    id: 604,
    code: '011690',
    companyName: '유양디앤유',
    market: 'KOSPI',
    marketCap: 359254954280,
  },
  {
    id: 605,
    code: '072130',
    companyName: '유엔젤',
    market: 'KOSPI',
    marketCap: 55552861340,
  },
  {
    id: 606,
    code: '000220',
    companyName: '유유제약',
    market: 'KOSPI',
    marketCap: 151290402560,
  },
  {
    id: 607,
    code: '000225',
    companyName: '유유제약1우',
    market: 'KOSPI',
    marketCap: 30716901500,
  },
  {
    id: 608,
    code: '000227',
    companyName: '유유제약2우B',
    market: 'KOSPI',
    marketCap: 9115680000,
  },
  {
    id: 609,
    code: '001200',
    companyName: '유진투자증권',
    market: 'KOSPI',
    marketCap: 447522851160,
  },
  {
    id: 610,
    code: '000100',
    companyName: '유한양행',
    market: 'KOSPI',
    marketCap: 4415293712900,
  },
  {
    id: 611,
    code: '000105',
    companyName: '유한양행우',
    market: 'KOSPI',
    marketCap: 71801152000,
  },
  {
    id: 612,
    code: '003460',
    companyName: '유화증권',
    market: 'KOSPI',
    marketCap: 158483249925,
  },
  {
    id: 613,
    code: '003465',
    companyName: '유화증권우',
    market: 'KOSPI',
    marketCap: 49633047000,
  },
  {
    id: 614,
    code: '008730',
    companyName: '율촌화학',
    market: 'KOSPI',
    marketCap: 546840000000,
  },
  {
    id: 615,
    code: '008250',
    companyName: '이건산업',
    market: 'KOSPI',
    marketCap: 155527417000,
  },
  {
    id: 616,
    code: '025820',
    companyName: '이구산업',
    market: 'KOSPI',
    marketCap: 148148060000,
  },
  {
    id: 617,
    code: 214320,
    companyName: '이노션',
    market: 'KOSPI',
    marketCap: 1286000000000,
  },
  {
    id: 618,
    code: '088260',
    companyName: '이리츠코크렙',
    market: 'KOSPI',
    marketCap: 407919839600,
  },
  {
    id: 619,
    code: 139480,
    companyName: '이마트',
    market: 'KOSPI',
    marketCap: 4627385954000,
  },
  {
    id: 620,
    code: '007660',
    companyName: '이수페타시스',
    market: 'KOSPI',
    marketCap: 176835085430,
  },
  {
    id: 621,
    code: '005950',
    companyName: '이수화학',
    market: 'KOSPI',
    marketCap: 334293016200,
  },
  {
    id: 622,
    code: '015020',
    companyName: '이스타코',
    market: 'KOSPI',
    marketCap: 178691172000,
  },
  {
    id: 623,
    code: '093230',
    companyName: '이아이디',
    market: 'KOSPI',
    marketCap: 411418232344,
  },
  {
    id: 624,
    code: '074610',
    companyName: '이엔플러스',
    market: 'KOSPI',
    marketCap: 206051379330,
  },
  {
    id: 625,
    code: 102460,
    companyName: '이연제약',
    market: 'KOSPI',
    marketCap: 783838028350,
  },
  {
    id: 626,
    code: '084680',
    companyName: '이월드',
    market: 'KOSPI',
    marketCap: 400678111175,
  },
  {
    id: 627,
    code: 350520,
    companyName: '이지스레지던스리츠',
    market: 'KOSPI',
    marketCap: 106296000000,
  },
  {
    id: 628,
    code: 334890,
    companyName: '이지스밸류리츠',
    market: 'KOSPI',
    marketCap: 132720000000,
  },
  {
    id: 629,
    code: '000760',
    companyName: '이화산업',
    market: 'KOSPI',
    marketCap: 60340000000,
  },
  {
    id: 630,
    code: '014990',
    companyName: '인디에프',
    market: 'KOSPI',
    marketCap: 97239441750,
  },
  {
    id: 631,
    code: 101140,
    companyName: '인바이오젠',
    market: 'KOSPI',
    marketCap: 160355944670,
  },
  {
    id: 632,
    code: '006490',
    companyName: '인스코비',
    market: 'KOSPI',
    marketCap: 325392476060,
  },
  {
    id: 633,
    code: '023800',
    companyName: '인지컨트롤스',
    market: 'KOSPI',
    marketCap: 260754201600,
  },
  {
    id: 634,
    code: '034590',
    companyName: '인천도시가스',
    market: 'KOSPI',
    marketCap: 126211652900,
  },
  {
    id: 635,
    code: 129260,
    companyName: '인터지스',
    market: 'KOSPI',
    marketCap: 130444427490,
  },
  {
    id: 636,
    code: '023810',
    companyName: '인팩',
    market: 'KOSPI',
    marketCap: 174000000000,
  },
  {
    id: 637,
    code: 249420,
    companyName: '일동제약',
    market: 'KOSPI',
    marketCap: 389183709750,
  },
  {
    id: 638,
    code: '000230',
    companyName: '일동홀딩스',
    market: 'KOSPI',
    marketCap: 167912820000,
  },
  {
    id: 639,
    code: '013360',
    companyName: '일성건설',
    market: 'KOSPI',
    marketCap: 151809912800,
  },
  {
    id: 640,
    code: '003120',
    companyName: '일성신약',
    market: 'KOSPI',
    marketCap: 228494000000,
  },
  {
    id: 641,
    code: '003200',
    companyName: '일신방직',
    market: 'KOSPI',
    marketCap: 270000000000,
  },
  {
    id: 642,
    code: '007110',
    companyName: '일신석재',
    market: 'KOSPI',
    marketCap: 178537486050,
  },
  {
    id: 643,
    code: '007570',
    companyName: '일양약품',
    market: 'KOSPI',
    marketCap: 706169568000,
  },
  {
    id: 644,
    code: '007575',
    companyName: '일양약품우',
    market: 'KOSPI',
    marketCap: 16913040000,
  },
  {
    id: 645,
    code: '008500',
    companyName: '일정실업',
    market: 'KOSPI',
    marketCap: 22320000000,
  },
  {
    id: 646,
    code: '081000',
    companyName: '일진다이아',
    market: 'KOSPI',
    marketCap: 633452685000,
  },
  {
    id: 647,
    code: '020760',
    companyName: '일진디스플',
    market: 'KOSPI',
    marketCap: 152866848410,
  },
  {
    id: 648,
    code: '020150',
    companyName: '일진머티리얼즈',
    market: 'KOSPI',
    marketCap: 3209314116000,
  },
  {
    id: 649,
    code: 103590,
    companyName: '일진전기',
    market: 'KOSPI',
    marketCap: 209875007400,
  },
  {
    id: 650,
    code: '015860',
    companyName: '일진홀딩스',
    market: 'KOSPI',
    marketCap: 292137099360,
  },
  {
    id: 651,
    code: 226320,
    companyName: '잇츠한불',
    market: 'KOSPI',
    marketCap: 660072381500,
  },
  {
    id: 652,
    code: 317400,
    companyName: '자이에스앤디',
    market: 'KOSPI',
    marketCap: 432425098000,
  },
  {
    id: 653,
    code: '033240',
    companyName: '자화전자',
    market: 'KOSPI',
    marketCap: 370530000000,
  },
  {
    id: 654,
    code: '000950',
    companyName: '전방',
    market: 'KOSPI',
    marketCap: 69468000000,
  },
  {
    id: 655,
    code: 348950,
    companyName: '제이알글로벌리츠',
    market: 'KOSPI',
    marketCap: 857808000000,
  },
  {
    id: 656,
    code: 194370,
    companyName: '제이에스코퍼레이션',
    market: 'KOSPI',
    marketCap: 247375398550,
  },
  {
    id: 657,
    code: '025620',
    companyName: '제이준코스메틱',
    market: 'KOSPI',
    marketCap: 78657012000,
  },
  {
    id: 658,
    code: '036420',
    companyName: '제이콘텐트리',
    market: 'KOSPI',
    marketCap: 878239805600,
  },
  {
    id: 659,
    code: '030000',
    companyName: '제일기획',
    market: 'KOSPI',
    marketCap: 2870278563750,
  },
  {
    id: 660,
    code: 271980,
    companyName: '제일약품',
    market: 'KOSPI',
    marketCap: 625692303600,
  },
  {
    id: 661,
    code: '001560',
    companyName: '제일연마',
    market: 'KOSPI',
    marketCap: 85800000000,
  },
  {
    id: 662,
    code: '002620',
    companyName: '제일파마홀딩스',
    market: 'KOSPI',
    marketCap: 397736539500,
  },
  {
    id: 663,
    code: '006220',
    companyName: '제주은행',
    market: 'KOSPI',
    marketCap: 241608380480,
  },
  {
    id: 664,
    code: '089590',
    companyName: '제주항공',
    market: 'KOSPI',
    marketCap: 1089539104500,
  },
  {
    id: 665,
    code: '004910',
    companyName: '조광페인트',
    market: 'KOSPI',
    marketCap: 115584000000,
  },
  {
    id: 666,
    code: '004700',
    companyName: '조광피혁',
    market: 'KOSPI',
    marketCap: 369692072800,
  },
  {
    id: 667,
    code: '001550',
    companyName: '조비',
    market: 'KOSPI',
    marketCap: 129027139150,
  },
  {
    id: 668,
    code: '000480',
    companyName: '조선내화',
    market: 'KOSPI',
    marketCap: 370000000000,
  },
  {
    id: 669,
    code: 120030,
    companyName: '조선선재',
    market: 'KOSPI',
    marketCap: 161608153500,
  },
  {
    id: 670,
    code: '018470',
    companyName: '조일알미늄',
    market: 'KOSPI',
    marketCap: 102044531260,
  },
  {
    id: 671,
    code: '002600',
    companyName: '조흥',
    market: 'KOSPI',
    marketCap: 120900000000,
  },
  {
    id: 672,
    code: 185750,
    companyName: '종근당',
    market: 'KOSPI',
    marketCap: 1496818969000,
  },
  {
    id: 673,
    code: '063160',
    companyName: '종근당바이오',
    market: 'KOSPI',
    marketCap: 311602641600,
  },
  {
    id: 674,
    code: '001630',
    companyName: '종근당홀딩스',
    market: 'KOSPI',
    marketCap: 541064988000,
  },
  {
    id: 675,
    code: '044380',
    companyName: '주연테크',
    market: 'KOSPI',
    marketCap: 71541836590,
  },
  {
    id: 676,
    code: '013890',
    companyName: '지누스',
    market: 'KOSPI',
    marketCap: 1524743425000,
  },
  {
    id: 677,
    code: '013870',
    companyName: '지엠비코리아',
    market: 'KOSPI',
    marketCap: 141707040400,
  },
  {
    id: 678,
    code: '071320',
    companyName: '지역난방공사',
    market: 'KOSPI',
    marketCap: 534359035600,
  },
  {
    id: 679,
    code: '010580',
    companyName: '지코',
    market: 'KOSPI',
    marketCap: 19761980616,
  },
  {
    id: 680,
    code: '035000',
    companyName: '지투알',
    market: 'KOSPI',
    marketCap: 113155403470,
  },
  {
    id: 681,
    code: '088790',
    companyName: '진도',
    market: 'KOSPI',
    marketCap: 76553625600,
  },
  {
    id: 682,
    code: '003780',
    companyName: '진양산업',
    market: 'KOSPI',
    marketCap: 75790000000,
  },
  {
    id: 683,
    code: '010640',
    companyName: '진양폴리',
    market: 'KOSPI',
    marketCap: 40800000000,
  },
  {
    id: 684,
    code: 100250,
    companyName: '진양홀딩스',
    market: 'KOSPI',
    marketCap: 183336557760,
  },
  {
    id: 685,
    code: '051630',
    companyName: '진양화학',
    market: 'KOSPI',
    marketCap: 50280000000,
  },
  {
    id: 686,
    code: 272450,
    companyName: '진에어',
    market: 'KOSPI',
    marketCap: 1046250000000,
  },
  {
    id: 687,
    code: '011000',
    companyName: '진원생명과학',
    market: 'KOSPI',
    marketCap: 1041606634400,
  },
  {
    id: 688,
    code: '002780',
    companyName: '진흥기업',
    market: 'KOSPI',
    marketCap: 452417126950,
  },
  {
    id: 689,
    code: '002787',
    companyName: '진흥기업2우B',
    market: 'KOSPI',
    marketCap: 5689794400,
  },
  {
    id: 690,
    code: '002785',
    companyName: '진흥기업우B',
    market: 'KOSPI',
    marketCap: 7029187600,
  },
  {
    id: 691,
    code: '009310',
    companyName: '참엔지니어링',
    market: 'KOSPI',
    marketCap: 85791631380,
  },
  {
    id: 692,
    code: '000650',
    companyName: '천일고속',
    market: 'KOSPI',
    marketCap: 112765458000,
  },
  {
    id: 693,
    code: '033250',
    companyName: '체시스',
    market: 'KOSPI',
    marketCap: 54400000000,
  },
  {
    id: 694,
    code: '035720',
    companyName: '카카오',
    market: 'KOSPI',
    marketCap: 65701578580000,
  },
  {
    id: 695,
    code: '006380',
    companyName: '카프로',
    market: 'KOSPI',
    marketCap: 198200000000,
  },
  {
    id: 696,
    code: 109070,
    companyName: '컨버즈',
    market: 'KOSPI',
    marketCap: 58401720200,
  },
  {
    id: 697,
    code: '001620',
    companyName: '케이비아이동국실업',
    market: 'KOSPI',
    marketCap: 98120224125,
  },
  {
    id: 698,
    code: '029460',
    companyName: '케이씨',
    market: 'KOSPI',
    marketCap: 364603783600,
  },
  {
    id: 699,
    code: 344820,
    companyName: '케이씨씨글라스',
    market: 'KOSPI',
    marketCap: 988574692800,
  },
  {
    id: 700,
    code: 281820,
    companyName: '케이씨텍',
    market: 'KOSPI',
    marketCap: 575778945600,
  },
  {
    id: 701,
    code: 145270,
    companyName: '케이탑리츠',
    market: 'KOSPI',
    marketCap: 85976876220,
  },
  {
    id: 702,
    code: 357120,
    companyName: '코람코에너지리츠',
    market: 'KOSPI',
    marketCap: 419112400000,
  },
  {
    id: 703,
    code: '007815',
    companyName: '코리아써우',
    market: 'KOSPI',
    marketCap: 23154228900,
  },
  {
    id: 704,
    code: '007810',
    companyName: '코리아써키트',
    market: 'KOSPI',
    marketCap: 312974950750,
  },
  {
    id: 705,
    code: '00781K',
    companyName: '코리아써키트2우B',
    market: 'KOSPI',
    marketCap: 7499999430,
  },
  {
    id: 706,
    code: '003690',
    companyName: '코리안리',
    market: 'KOSPI',
    marketCap: 1143506602000,
  },
  {
    id: 707,
    code: 192820,
    companyName: '코스맥스',
    market: 'KOSPI',
    marketCap: 1331559942500,
  },
  {
    id: 708,
    code: '044820',
    companyName: '코스맥스비티아이',
    market: 'KOSPI',
    marketCap: 199281360750,
  },
  {
    id: 709,
    code: '005070',
    companyName: '코스모신소재',
    market: 'KOSPI',
    marketCap: 792124878600,
  },
  {
    id: 710,
    code: '005420',
    companyName: '코스모화학',
    market: 'KOSPI',
    marketCap: 320776087600,
  },
  {
    id: 711,
    code: '071950',
    companyName: '코아스',
    market: 'KOSPI',
    marketCap: 50996896500,
  },
  {
    id: 712,
    code: '002020',
    companyName: '코오롱',
    market: 'KOSPI',
    marketCap: 402151668100,
  },
  {
    id: 713,
    code: '003070',
    companyName: '코오롱글로벌',
    market: 'KOSPI',
    marketCap: 613874165300,
  },
  {
    id: 714,
    code: '003075',
    companyName: '코오롱글로벌우',
    market: 'KOSPI',
    marketCap: 10572741400,
  },
  {
    id: 715,
    code: 144620,
    companyName: '코오롱머티리얼',
    market: 'KOSPI',
    marketCap: 203229559600,
  },
  {
    id: 716,
    code: '002025',
    companyName: '코오롱우',
    market: 'KOSPI',
    marketCap: 23357663000,
  },
  {
    id: 717,
    code: 120110,
    companyName: '코오롱인더',
    market: 'KOSPI',
    marketCap: 1823769584000,
  },
  {
    id: 718,
    code: 120115,
    companyName: '코오롱인더우',
    market: 'KOSPI',
    marketCap: 88433127000,
  },
  {
    id: 719,
    code: 138490,
    companyName: '코오롱플라스틱',
    market: 'KOSPI',
    marketCap: 258020000000,
  },
  {
    id: 720,
    code: '021240',
    companyName: '코웨이',
    market: 'KOSPI',
    marketCap: 5837549862900,
  },
  {
    id: 721,
    code: '031820',
    companyName: '콤텍시스템',
    market: 'KOSPI',
    marketCap: 89224677790,
  },
  {
    id: 722,
    code: 192400,
    companyName: '쿠쿠홀딩스',
    market: 'KOSPI',
    marketCap: 913948154500,
  },
  {
    id: 723,
    code: 284740,
    companyName: '쿠쿠홈시스',
    market: 'KOSPI',
    marketCap: 1064651308500,
  },
  {
    id: 724,
    code: '015590',
    companyName: '큐로',
    market: 'KOSPI',
    marketCap: 147513025044,
  },
  {
    id: 725,
    code: 264900,
    companyName: '크라운제과',
    market: 'KOSPI',
    marketCap: 176586285000,
  },
  {
    id: 726,
    code: '26490K',
    companyName: '크라운제과우',
    market: 'KOSPI',
    marketCap: 16940964000,
  },
  {
    id: 727,
    code: '005740',
    companyName: '크라운해태홀딩스',
    market: 'KOSPI',
    marketCap: 177425796650,
  },
  {
    id: 728,
    code: '005745',
    companyName: '크라운해태홀딩스우',
    market: 'KOSPI',
    marketCap: 12675318600,
  },
  {
    id: 729,
    code: '020120',
    companyName: '키다리스튜디오',
    market: 'KOSPI',
    marketCap: 485527838800,
  },
  {
    id: 730,
    code: '039490',
    companyName: '키움증권',
    market: 'KOSPI',
    marketCap: 3169112089000,
  },
  {
    id: 731,
    code: '014580',
    companyName: '태경비케이',
    market: 'KOSPI',
    marketCap: 133778035000,
  },
  {
    id: 732,
    code: '015890',
    companyName: '태경산업',
    market: 'KOSPI',
    marketCap: 197878637500,
  },
  {
    id: 733,
    code: '006890',
    companyName: '태경케미컬',
    market: 'KOSPI',
    marketCap: 226200000000,
  },
  {
    id: 734,
    code: '003240',
    companyName: '태광산업',
    market: 'KOSPI',
    marketCap: 1362801600000,
  },
  {
    id: 735,
    code: '011280',
    companyName: '태림포장',
    market: 'KOSPI',
    marketCap: 344824927800,
  },
  {
    id: 736,
    code: '004100',
    companyName: '태양금속',
    market: 'KOSPI',
    marketCap: 69179500000,
  },
  {
    id: 737,
    code: '004105',
    companyName: '태양금속우',
    market: 'KOSPI',
    marketCap: 12292500000,
  },
  {
    id: 738,
    code: '009410',
    companyName: '태영건설',
    market: 'KOSPI',
    marketCap: 494018544600,
  },
  {
    id: 739,
    code: '009415',
    companyName: '태영건설우',
    market: 'KOSPI',
    marketCap: 23047913400,
  },
  {
    id: 740,
    code: '001420',
    companyName: '태원물산',
    market: 'KOSPI',
    marketCap: 39748000000,
  },
  {
    id: 741,
    code: '007980',
    companyName: '태평양물산',
    market: 'KOSPI',
    marketCap: 127355657615,
  },
  {
    id: 742,
    code: '055490',
    companyName: '테이팩스',
    market: 'KOSPI',
    marketCap: 246261330000,
  },
  {
    id: 743,
    code: '078000',
    companyName: '텔코웨어',
    market: 'KOSPI',
    marketCap: 125164907400,
  },
  {
    id: 744,
    code: 214420,
    companyName: '토니모리',
    market: 'KOSPI',
    marketCap: 173754000000,
  },
  {
    id: 745,
    code: '019180',
    companyName: '티에이치엔',
    market: 'KOSPI',
    marketCap: 66600000000,
  },
  {
    id: 746,
    code: 363280,
    companyName: '티와이홀딩스',
    market: 'KOSPI',
    marketCap: 1179036900250,
  },
  {
    id: 747,
    code: '36328K',
    companyName: '티와이홀딩스우',
    market: 'KOSPI',
    marketCap: 35902666800,
  },
  {
    id: 748,
    code: '091810',
    companyName: '티웨이항공',
    market: 'KOSPI',
    marketCap: 506415980610,
  },
  {
    id: 749,
    code: '004870',
    companyName: '티웨이홀딩스',
    market: 'KOSPI',
    marketCap: 128187450715,
  },
  {
    id: 750,
    code: '005690',
    companyName: '파미셀',
    market: 'KOSPI',
    marketCap: 1010308307500,
  },
  {
    id: 751,
    code: '036580',
    companyName: '팜스코',
    market: 'KOSPI',
    marketCap: 262972071880,
  },
  {
    id: 752,
    code: '004720',
    companyName: '팜젠사이언스',
    market: 'KOSPI',
    marketCap: 150807563250,
  },
  {
    id: 753,
    code: '028670',
    companyName: '팬오션',
    market: 'KOSPI',
    marketCap: 3982542864400,
  },
  {
    id: 754,
    code: '010820',
    companyName: '퍼스텍',
    market: 'KOSPI',
    marketCap: 139378234000,
  },
  {
    id: 755,
    code: '016800',
    companyName: '퍼시스',
    market: 'KOSPI',
    marketCap: 494500000000,
  },
  {
    id: 756,
    code: '001020',
    companyName: '페이퍼코리아',
    market: 'KOSPI',
    marketCap: 91762469190,
  },
  {
    id: 757,
    code: '090080',
    companyName: '평화산업',
    market: 'KOSPI',
    marketCap: 98549554905,
  },
  {
    id: 758,
    code: '010770',
    companyName: '평화홀딩스',
    market: 'KOSPI',
    marketCap: 73127330000,
  },
  {
    id: 759,
    code: '058430',
    companyName: '포스코강판',
    market: 'KOSPI',
    marketCap: 378000000000,
  },
  {
    id: 760,
    code: '047050',
    companyName: '포스코인터내셔널',
    market: 'KOSPI',
    marketCap: 2726590792900,
  },
  {
    id: 761,
    code: '003670',
    companyName: '포스코케미칼',
    market: 'KOSPI',
    marketCap: 11387093340000,
  },
  {
    id: 762,
    code: '007630',
    companyName: '폴루스바이오팜',
    market: 'KOSPI',
    marketCap: 55134426375,
  },
  {
    id: 763,
    code: '017810',
    companyName: '풀무원',
    market: 'KOSPI',
    marketCap: 773846941700,
  },
  {
    id: 764,
    code: 103140,
    companyName: '풍산',
    market: 'KOSPI',
    marketCap: 1080335916900,
  },
  {
    id: 765,
    code: '005810',
    companyName: '풍산홀딩스',
    market: 'KOSPI',
    marketCap: 295110118800,
  },
  {
    id: 766,
    code: 950210,
    companyName: '프레스티지바이오파마',
    market: 'KOSPI',
    marketCap: 1688701955500,
  },
  {
    id: 767,
    code: '086790',
    companyName: '하나금융지주',
    market: 'KOSPI',
    marketCap: 13811134852000,
  },
  {
    id: 768,
    code: 293480,
    companyName: '하나제약',
    market: 'KOSPI',
    marketCap: 404334521500,
  },
  {
    id: 769,
    code: '039130',
    companyName: '하나투어',
    market: 'KOSPI',
    marketCap: 1223860443000,
  },
  {
    id: 770,
    code: 172580,
    companyName: '하이골드12호',
    market: 'KOSPI',
    marketCap: 32937362500,
  },
  {
    id: 771,
    code: 153360,
    companyName: '하이골드3호',
    market: 'KOSPI',
    marketCap: 29715613600,
  },
  {
    id: 772,
    code: 352820,
    companyName: '하이브',
    market: 'KOSPI',
    marketCap: 10580256720000,
  },
  {
    id: 773,
    code: '071090',
    companyName: '하이스틸',
    market: 'KOSPI',
    marketCap: 81097729200,
  },
  {
    id: 774,
    code: '019490',
    companyName: '하이트론',
    market: 'KOSPI',
    marketCap: 78722023600,
  },
  {
    id: 775,
    code: '000080',
    companyName: '하이트진로',
    market: 'KOSPI',
    marketCap: 2801837759450,
  },
  {
    id: 776,
    code: '000087',
    companyName: '하이트진로2우B',
    market: 'KOSPI',
    marketCap: 27518860300,
  },
  {
    id: 777,
    code: '000140',
    companyName: '하이트진로홀딩스',
    market: 'KOSPI',
    marketCap: 413080417000,
  },
  {
    id: 778,
    code: '000145',
    companyName: '하이트진로홀딩스우',
    market: 'KOSPI',
    marketCap: 9651605000,
  },
  {
    id: 779,
    code: 152550,
    companyName: '한국ANKOR유전',
    market: 'KOSPI',
    marketCap: 112732200000,
  },
  {
    id: 780,
    code: '036460',
    companyName: '한국가스공사',
    market: 'KOSPI',
    marketCap: 3650979150000,
  },
  {
    id: 781,
    code: '005430',
    companyName: '한국공항',
    market: 'KOSPI',
    marketCap: 161800740500,
  },
  {
    id: 782,
    code: '071050',
    companyName: '한국금융지주',
    market: 'KOSPI',
    marketCap: 5962681144000,
  },
  {
    id: 783,
    code: '071055',
    companyName: '한국금융지주우',
    market: 'KOSPI',
    marketCap: 535444141400,
  },
  {
    id: 784,
    code: '010040',
    companyName: '한국내화',
    market: 'KOSPI',
    marketCap: 197327232910,
  },
  {
    id: 785,
    code: '025540',
    companyName: '한국단자',
    market: 'KOSPI',
    marketCap: 1001923000000,
  },
  {
    id: 786,
    code: '004090',
    companyName: '한국석유',
    market: 'KOSPI',
    marketCap: 211357098000,
  },
  {
    id: 787,
    code: '002200',
    companyName: '한국수출포장',
    market: 'KOSPI',
    marketCap: 104400000000,
  },
  {
    id: 788,
    code: '002960',
    companyName: '한국쉘석유',
    market: 'KOSPI',
    marketCap: 376350000000,
  },
  {
    id: 789,
    code: '000240',
    companyName: '한국앤컴퍼니',
    market: 'KOSPI',
    marketCap: 1998386802000,
  },
  {
    id: 790,
    code: 123890,
    companyName: '한국자산신탁',
    market: 'KOSPI',
    marketCap: 604391541000,
  },
  {
    id: 791,
    code: '015760',
    companyName: '한국전력',
    market: 'KOSPI',
    marketCap: 17268833671300,
  },
  {
    id: 792,
    code: '006200',
    companyName: '한국전자홀딩스',
    market: 'KOSPI',
    marketCap: 68332578560,
  },
  {
    id: 793,
    code: '009540',
    companyName: '한국조선해양',
    market: 'KOSPI',
    marketCap: 9483597544000,
  },
  {
    id: 794,
    code: '023350',
    companyName: '한국종합기술',
    market: 'KOSPI',
    marketCap: 92089500000,
  },
  {
    id: 795,
    code: '025890',
    companyName: '한국주강',
    market: 'KOSPI',
    marketCap: 32683758510,
  },
  {
    id: 796,
    code: '000970',
    companyName: '한국주철관',
    market: 'KOSPI',
    marketCap: 230285050000,
  },
  {
    id: 797,
    code: 104700,
    companyName: '한국철강',
    market: 'KOSPI',
    marketCap: 467407500000,
  },
  {
    id: 798,
    code: '017960',
    companyName: '한국카본',
    market: 'KOSPI',
    marketCap: 527529084000,
  },
  {
    id: 799,
    code: 161890,
    companyName: '한국콜마',
    market: 'KOSPI',
    marketCap: 1379735154000,
  },
  {
    id: 800,
    code: '024720',
    companyName: '한국콜마홀딩스',
    market: 'KOSPI',
    marketCap: 570305273300,
  },
  {
    id: 801,
    code: 161390,
    companyName: '한국타이어앤테크놀로지',
    market: 'KOSPI',
    marketCap: 6714028739800,
  },
  {
    id: 802,
    code: '034830',
    companyName: '한국토지신탁',
    market: 'KOSPI',
    marketCap: 607236598150,
  },
  {
    id: 803,
    code: '007280',
    companyName: '한국특강',
    market: 'KOSPI',
    marketCap: 192205338360,
  },
  {
    id: 804,
    code: 168490,
    companyName: '한국패러랠',
    market: 'KOSPI',
    marketCap: 161640400000,
  },
  {
    id: 805,
    code: '010100',
    companyName: '한국프랜지',
    market: 'KOSPI',
    marketCap: 112209797700,
  },
  {
    id: 806,
    code: '047810',
    companyName: '한국항공우주',
    market: 'KOSPI',
    marketCap: 3231299797050,
  },
  {
    id: 807,
    code: 123690,
    companyName: '한국화장품',
    market: 'KOSPI',
    marketCap: 203260200000,
  },
  {
    id: 808,
    code: '003350',
    companyName: '한국화장품제조',
    market: 'KOSPI',
    marketCap: 174028800000,
  },
  {
    id: 809,
    code: '011500',
    companyName: '한농화성',
    market: 'KOSPI',
    marketCap: 228300813200,
  },
  {
    id: 810,
    code: '002390',
    companyName: '한독',
    market: 'KOSPI',
    marketCap: 379185334150,
  },
  {
    id: 811,
    code: '014790',
    companyName: '한라',
    market: 'KOSPI',
    marketCap: 223530916500,
  },
  {
    id: 812,
    code: '060980',
    companyName: '한라홀딩스',
    market: 'KOSPI',
    marketCap: 456582252000,
  },
  {
    id: 813,
    code: '053690',
    companyName: '한미글로벌',
    market: 'KOSPI',
    marketCap: 138613007500,
  },
  {
    id: 814,
    code: '042700',
    companyName: '한미반도체',
    market: 'KOSPI',
    marketCap: 1664324861050,
  },
  {
    id: 815,
    code: '008930',
    companyName: '한미사이언스',
    market: 'KOSPI',
    marketCap: 4617096314600,
  },
  {
    id: 816,
    code: 128940,
    companyName: '한미약품',
    market: 'KOSPI',
    marketCap: 3889206804000,
  },
  {
    id: 817,
    code: '009240',
    companyName: '한샘',
    market: 'KOSPI',
    marketCap: 2506363332000,
  },
  {
    id: 818,
    code: '020000',
    companyName: '한섬',
    market: 'KOSPI',
    marketCap: 1044312000000,
  },
  {
    id: 819,
    code: '003680',
    companyName: '한성기업',
    market: 'KOSPI',
    marketCap: 56258205900,
  },
  {
    id: 820,
    code: 105630,
    companyName: '한세실업',
    market: 'KOSPI',
    marketCap: 950000000000,
  },
  {
    id: 821,
    code: '069640',
    companyName: '한세엠케이',
    market: 'KOSPI',
    marketCap: 61771105770,
  },
  {
    id: 822,
    code: '016450',
    companyName: '한세예스24홀딩스',
    market: 'KOSPI',
    marketCap: 391600000000,
  },
  {
    id: 823,
    code: '010420',
    companyName: '한솔PNS',
    market: 'KOSPI',
    marketCap: 58200154080,
  },
  {
    id: 824,
    code: '009180',
    companyName: '한솔로지스틱스',
    market: 'KOSPI',
    marketCap: 140134867500,
  },
  {
    id: 825,
    code: 213500,
    companyName: '한솔제지',
    market: 'KOSPI',
    marketCap: 378429158400,
  },
  {
    id: 826,
    code: '014680',
    companyName: '한솔케미칼',
    market: 'KOSPI',
    marketCap: 2856469140000,
  },
  {
    id: 827,
    code: '004710',
    companyName: '한솔테크닉스',
    market: 'KOSPI',
    marketCap: 281282531280,
  },
  {
    id: 828,
    code: '004150',
    companyName: '한솔홀딩스',
    market: 'KOSPI',
    marketCap: 198910612095,
  },
  {
    id: 829,
    code: '025750',
    companyName: '한솔홈데코',
    market: 'KOSPI',
    marketCap: 178048979290,
  },
  {
    id: 830,
    code: '004960',
    companyName: '한신공영',
    market: 'KOSPI',
    marketCap: 284639269200,
  },
  {
    id: 831,
    code: '011700',
    companyName: '한신기계',
    market: 'KOSPI',
    marketCap: 91660376575,
  },
  {
    id: 832,
    code: '001750',
    companyName: '한양증권',
    market: 'KOSPI',
    marketCap: 220840064900,
  },
  {
    id: 833,
    code: '001755',
    companyName: '한양증권우',
    market: 'KOSPI',
    marketCap: 12967500000,
  },
  {
    id: 834,
    code: '018880',
    companyName: '한온시스템',
    market: 'KOSPI',
    marketCap: 9581710000000,
  },
  {
    id: 835,
    code: '009420',
    companyName: '한올바이오파마',
    market: 'KOSPI',
    marketCap: 1107501525600,
  },
  {
    id: 836,
    code: '014130',
    companyName: '한익스프레스',
    market: 'KOSPI',
    marketCap: 86880000000,
  },
  {
    id: 837,
    code: 300720,
    companyName: '한일시멘트',
    market: 'KOSPI',
    marketCap: 1111647717000,
  },
  {
    id: 838,
    code: '002220',
    companyName: '한일철강',
    market: 'KOSPI',
    marketCap: 85832333900,
  },
  {
    id: 839,
    code: '006390',
    companyName: '한일현대시멘트',
    market: 'KOSPI',
    marketCap: 734088295500,
  },
  {
    id: 840,
    code: '003300',
    companyName: '한일홀딩스',
    market: 'KOSPI',
    marketCap: 394776186000,
  },
  {
    id: 841,
    code: '051600',
    companyName: '한전KPS',
    market: 'KOSPI',
    marketCap: 1887750000000,
  },
  {
    id: 842,
    code: '052690',
    companyName: '한전기술',
    market: 'KOSPI',
    marketCap: 1987440000000,
  },
  {
    id: 843,
    code: 130660,
    companyName: '한전산업',
    market: 'KOSPI',
    marketCap: 334150000000,
  },
  {
    id: 844,
    code: '002320',
    companyName: '한진',
    market: 'KOSPI',
    marketCap: 659937776200,
  },
  {
    id: 845,
    code: '097230',
    companyName: '한진중공업',
    market: 'KOSPI',
    marketCap: 723653501890,
  },
  {
    id: 846,
    code: '003480',
    companyName: '한진중공업홀딩스',
    market: 'KOSPI',
    marketCap: 168319928400,
  },
  {
    id: 847,
    code: 180640,
    companyName: '한진칼',
    market: 'KOSPI',
    marketCap: 4807019358000,
  },
  {
    id: 848,
    code: '18064K',
    companyName: '한진칼우',
    market: 'KOSPI',
    marketCap: 25201163700,
  },
  {
    id: 849,
    code: '005110',
    companyName: '한창',
    market: 'KOSPI',
    marketCap: 100519354845,
  },
  {
    id: 850,
    code: '009460',
    companyName: '한창제지',
    market: 'KOSPI',
    marketCap: 178107445710,
  },
  {
    id: 851,
    code: '000880',
    companyName: '한화',
    market: 'KOSPI',
    marketCap: 2406175393500,
  },
  {
    id: 852,
    code: '00088K',
    companyName: '한화3우B',
    market: 'KOSPI',
    marketCap: 350563200000,
  },
  {
    id: 853,
    code: '088350',
    companyName: '한화생명',
    market: 'KOSPI',
    marketCap: 3348183150000,
  },
  {
    id: 854,
    code: '000370',
    companyName: '한화손해보험',
    market: 'KOSPI',
    marketCap: 545170733050,
  },
  {
    id: 855,
    code: '009830',
    companyName: '한화솔루션',
    market: 'KOSPI',
    marketCap: 8540584891050,
  },
  {
    id: 856,
    code: '009835',
    companyName: '한화솔루션우',
    market: 'KOSPI',
    marketCap: 62142656100,
  },
  {
    id: 857,
    code: 272210,
    companyName: '한화시스템',
    market: 'KOSPI',
    marketCap: 1934543326950,
  },
  {
    id: 858,
    code: '012450',
    companyName: '한화에어로스페이스',
    market: 'KOSPI',
    marketCap: 2346700500000,
  },
  {
    id: 859,
    code: '000885',
    companyName: '한화우',
    market: 'KOSPI',
    marketCap: 22287171000,
  },
  {
    id: 860,
    code: '003530',
    companyName: '한화투자증권',
    market: 'KOSPI',
    marketCap: 1087757219250,
  },
  {
    id: 861,
    code: '003535',
    companyName: '한화투자증권우',
    market: 'KOSPI',
    marketCap: 80400000000,
  },
  {
    id: 862,
    code: 195870,
    companyName: '해성디에스',
    market: 'KOSPI',
    marketCap: 589050000000,
  },
  {
    id: 863,
    code: 101530,
    companyName: '해태제과식품',
    market: 'KOSPI',
    marketCap: 299903266600,
  },
  {
    id: 864,
    code: 143210,
    companyName: '핸즈코퍼레이션',
    market: 'KOSPI',
    marketCap: 145609364880,
  },
  {
    id: 865,
    code: '000720',
    companyName: '현대건설',
    market: 'KOSPI',
    marketCap: 6091160345500,
  },
  {
    id: 866,
    code: 267270,
    companyName: '현대건설기계',
    market: 'KOSPI',
    marketCap: 1103244408000,
  },
  {
    id: 867,
    code: '000725',
    companyName: '현대건설우',
    market: 'KOSPI',
    marketCap: 21006900000,
  },
  {
    id: 868,
    code: '005440',
    companyName: '현대그린푸드',
    market: 'KOSPI',
    marketCap: 1104060646600,
  },
  {
    id: 869,
    code: '086280',
    companyName: '현대글로비스',
    market: 'KOSPI',
    marketCap: 7668750000000,
  },
  {
    id: 870,
    code: '064350',
    companyName: '현대로템',
    market: 'KOSPI',
    marketCap: 2603043688050,
  },
  {
    id: 871,
    code: '079430',
    companyName: '현대리바트',
    market: 'KOSPI',
    marketCap: 400437999000,
  },
  {
    id: 872,
    code: '012330',
    companyName: '현대모비스',
    market: 'KOSPI',
    marketCap: 27253014525000,
  },
  {
    id: 873,
    code: '010620',
    companyName: '현대미포조선',
    market: 'KOSPI',
    marketCap: 3466978533200,
  },
  {
    id: 874,
    code: '069960',
    companyName: '현대백화점',
    market: 'KOSPI',
    marketCap: 2050053831600,
  },
  {
    id: 875,
    code: '004560',
    companyName: '현대비앤지스틸',
    market: 'KOSPI',
    marketCap: 309115625500,
  },
  {
    id: 876,
    code: '004565',
    companyName: '현대비앤지스틸우',
    market: 'KOSPI',
    marketCap: 10501851200,
  },
  {
    id: 877,
    code: '004310',
    companyName: '현대약품',
    market: 'KOSPI',
    marketCap: 226880000000,
  },
  {
    id: 878,
    code: 322000,
    companyName: '현대에너지솔루션',
    market: 'KOSPI',
    marketCap: 360080000000,
  },
  {
    id: 879,
    code: '017800',
    companyName: '현대엘리베이',
    market: 'KOSPI',
    marketCap: 2163205123000,
  },
  {
    id: 880,
    code: 307950,
    companyName: '현대오토에버',
    market: 'KOSPI',
    marketCap: 3523981687000,
  },
  {
    id: 881,
    code: '011210',
    companyName: '현대위아',
    market: 'KOSPI',
    marketCap: 2520984194100,
  },
  {
    id: 882,
    code: 267260,
    companyName: '현대일렉트릭',
    market: 'KOSPI',
    marketCap: 776815759250,
  },
  {
    id: 883,
    code: '004020',
    companyName: '현대제철',
    market: 'KOSPI',
    marketCap: 7032592869500,
  },
  {
    id: 884,
    code: 267250,
    companyName: '현대중공업지주',
    market: 'KOSPI',
    marketCap: 5608509035000,
  },
  {
    id: 885,
    code: '005380',
    companyName: '현대차',
    market: 'KOSPI',
    marketCap: 50532526225500,
  },
  {
    id: 886,
    code: '005387',
    companyName: '현대차2우B',
    market: 'KOSPI',
    marketCap: 4250555041500,
  },
  {
    id: 887,
    code: '005389',
    companyName: '현대차3우B',
    market: 'KOSPI',
    marketCap: 264732115000,
  },
  {
    id: 888,
    code: '005385',
    companyName: '현대차우',
    market: 'KOSPI',
    marketCap: 2849732145000,
  },
  {
    id: 889,
    code: '001500',
    companyName: '현대차증권',
    market: 'KOSPI',
    marketCap: 418914856800,
  },
  {
    id: 890,
    code: '011760',
    companyName: '현대코퍼레이션',
    market: 'KOSPI',
    marketCap: 275823941100,
  },
  {
    id: 891,
    code: 227840,
    companyName: '현대코퍼레이션홀딩스',
    market: 'KOSPI',
    marketCap: 134237331000,
  },
  {
    id: 892,
    code: 126560,
    companyName: '현대퓨처넷',
    market: 'KOSPI',
    marketCap: 537292596960,
  },
  {
    id: 893,
    code: '001450',
    companyName: '현대해상',
    market: 'KOSPI',
    marketCap: 2217120000000,
  },
  {
    id: 894,
    code: '057050',
    companyName: '현대홈쇼핑',
    market: 'KOSPI',
    marketCap: 1099200000000,
  },
  {
    id: 895,
    code: '093240',
    companyName: '형지엘리트',
    market: 'KOSPI',
    marketCap: 174466868800,
  },
  {
    id: 896,
    code: '003010',
    companyName: '혜인',
    market: 'KOSPI',
    marketCap: 97761024430,
  },
  {
    id: 897,
    code: 111110,
    companyName: '호전실업',
    market: 'KOSPI',
    marketCap: 120400000000,
  },
  {
    id: 898,
    code: '008770',
    companyName: '호텔신라',
    market: 'KOSPI',
    marketCap: 3901263227400,
  },
  {
    id: 899,
    code: '008775',
    companyName: '호텔신라우',
    market: 'KOSPI',
    marketCap: 70751813900,
  },
  {
    id: 900,
    code: '002460',
    companyName: '화성산업',
    market: 'KOSPI',
    marketCap: 162482940000,
  },
  {
    id: 901,
    code: 378850,
    companyName: '화승알앤에이',
    market: 'KOSPI',
    marketCap: 125547848030,
  },
  {
    id: 902,
    code: 241590,
    companyName: '화승엔터프라이즈',
    market: 'KOSPI',
    marketCap: 1050610008850,
  },
  {
    id: 903,
    code: '006060',
    companyName: '화승인더',
    market: 'KOSPI',
    marketCap: 434262000000,
  },
  {
    id: 904,
    code: '013520',
    companyName: '화승코퍼레이션',
    market: 'KOSPI',
    marketCap: 164418362820,
  },
  {
    id: 905,
    code: '010690',
    companyName: '화신',
    market: 'KOSPI',
    marketCap: 280410892300,
  },
  {
    id: 906,
    code: 133820,
    companyName: '화인베스틸',
    market: 'KOSPI',
    marketCap: 85842750000,
  },
  {
    id: 907,
    code: '010660',
    companyName: '화천기계',
    market: 'KOSPI',
    marketCap: 66110000000,
  },
  {
    id: 908,
    code: '000850',
    companyName: '화천기공',
    market: 'KOSPI',
    marketCap: 85800000000,
  },
  {
    id: 909,
    code: '016580',
    companyName: '환인제약',
    market: 'KOSPI',
    marketCap: 414781561000,
  },
  {
    id: 910,
    code: '032560',
    companyName: '황금에스티',
    market: 'KOSPI',
    marketCap: 150240000000,
  },
  {
    id: 911,
    code: '004800',
    companyName: '효성',
    market: 'KOSPI',
    marketCap: 2138709037500,
  },
  {
    id: 912,
    code: '094280',
    companyName: '효성ITX',
    market: 'KOSPI',
    marketCap: 256592040000,
  },
  {
    id: 913,
    code: 298040,
    companyName: '효성중공업',
    market: 'KOSPI',
    marketCap: 696543735600,
  },
  {
    id: 914,
    code: 298050,
    companyName: '효성첨단소재',
    market: 'KOSPI',
    marketCap: 1774059408000,
  },
  {
    id: 915,
    code: 298020,
    companyName: '효성티앤씨',
    market: 'KOSPI',
    marketCap: 3609286788000,
  },
  {
    id: 916,
    code: 298000,
    companyName: '효성화학',
    market: 'KOSPI',
    marketCap: 1127709541000,
  },
  {
    id: 917,
    code: '093370',
    companyName: '후성',
    market: 'KOSPI',
    marketCap: 949219894750,
  },
  {
    id: 918,
    code: '081660',
    companyName: '휠라홀딩스',
    market: 'KOSPI',
    marketCap: 3341372210000,
  },
  {
    id: 919,
    code: '005870',
    companyName: '휴니드',
    market: 'KOSPI',
    marketCap: 122809330500,
  },
  {
    id: 920,
    code: '079980',
    companyName: '휴비스',
    market: 'KOSPI',
    marketCap: 382950000000,
  },
  {
    id: 921,
    code: '005010',
    companyName: '휴스틸',
    market: 'KOSPI',
    marketCap: 110625804250,
  },
  {
    id: 922,
    code: '069260',
    companyName: '휴켐스',
    market: 'KOSPI',
    marketCap: 903416794800,
  },
  {
    id: 923,
    code: '000540',
    companyName: '흥국화재',
    market: 'KOSPI',
    marketCap: 295194953775,
  },
  {
    id: 924,
    code: '000547',
    companyName: '흥국화재2우B',
    market: 'KOSPI',
    marketCap: 4961280000,
  },
  {
    id: 925,
    code: '000545',
    companyName: '흥국화재우',
    market: 'KOSPI',
    marketCap: 8908800000,
  },
  {
    id: 926,
    code: '003280',
    companyName: '흥아해운',
    market: 'KOSPI',
    marketCap: 30117000222,
  },
];
