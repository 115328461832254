import "./index.css";
import React from "../../../_snowpack/pkg/react.js";
const Title = () => {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("span", {
    className: "sub-title"
  }, "이거 사면 내 ", /* @__PURE__ */ React.createElement("span", {
    className: "strong"
  }, "평균 주식 단가"), "는 얼마?"), /* @__PURE__ */ React.createElement("div", {
    className: "title"
  }, "평균 단가 구하기"));
};
export default Title;
