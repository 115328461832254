import AveragePrice from "../../AveragePrice/index.js";
import CurrentHoldings from "../../CurrentHoldings/index.js";
import Footer from "../../Footer/index.js";
import NewHoldings from "../../NewHoldings/index.js";
import PageWrapper from "../../PageWrapper/index.js";
import React from "../../../../_snowpack/pkg/react.js";
import SearchCompany from "../../SearchCompany/index.js";
import Title from "../../Title/index.js";
const Home = () => {
  return /* @__PURE__ */ React.createElement(PageWrapper, null, /* @__PURE__ */ React.createElement(Title, null), /* @__PURE__ */ React.createElement(SearchCompany, null), /* @__PURE__ */ React.createElement(NewHoldings, null), /* @__PURE__ */ React.createElement(CurrentHoldings, null), /* @__PURE__ */ React.createElement(AveragePrice, null), /* @__PURE__ */ React.createElement(Footer, null));
};
export default Home;
