import "./index.css";
import React, {useContext, useEffect, useState} from "../../../_snowpack/pkg/react.js";
import HoldingsInput from "../HoldingsInput/index.js";
import {LOCALE} from "../../constants/locale.js";
import {StockContext} from "../../context/StockContext.js";
import {currencyFormatter} from "../../utils/numberFormatter.js";
const NewHoldings = () => {
  const {isLoading, company, newPrice, newQuantity, setNewQuantity} = useContext(StockContext);
  const totalPrice = currencyFormatter(LOCALE.KR, newPrice * newQuantity);
  const newKoreanPrice = currencyFormatter(LOCALE.KR, newPrice);
  const validateInput = ({target: {value}}) => {
    setNewQuantity(Number(value));
    if (Number(value) > 99999) {
      setNewQuantity(Number(value.slice(0, 5)));
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, !!company.companyName && isLoading && /* @__PURE__ */ React.createElement("div", {
    className: "loading"
  }, /* @__PURE__ */ React.createElement("img", {
    src: "https://s6.gifyu.com/images/loading7ac014ea623c7062.gif",
    alt: "loadingImage"
  })), !isLoading && /* @__PURE__ */ React.createElement("div", {
    className: "new-holdings-wrapper"
  }, !!company.companyName && newPrice !== 0 && /* @__PURE__ */ React.createElement("div", {
    className: "real-time-stock-price"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "real-time"
  }, "실시간"), /* @__PURE__ */ React.createElement("span", {
    className: "company-name"
  }, company.companyName), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", {
    className: "price"
  }, newKoreanPrice, "원 "), "/ 1주")), !!company.companyName && newPrice !== 0 && /* @__PURE__ */ React.createElement("div", {
    className: "money-needed"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "new-quantity-container"
  }, /* @__PURE__ */ React.createElement(HoldingsInput, {
    name: "newQuantity",
    onChange: validateInput,
    beforeText: "총 ",
    afterText: " 주 더 사고싶어요.",
    value: newQuantity === 0 ? "" : newQuantity
  })), /* @__PURE__ */ React.createElement("div", {
    className: "total-price"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "strong"
  }, totalPrice), " 원 더 필요해요."))));
};
export default NewHoldings;
