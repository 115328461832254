import "./index.css";
import NumberInput from "../NumberInput/index.js";
import React from "../../../_snowpack/pkg/react.js";
const HoldingsInput = ({name, onChange, beforeText, afterText, strong, ...args}) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: "current-holdings-input-wrapper"
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: name
  }, /* @__PURE__ */ React.createElement("span", {
    className: strong
  }, beforeText), /* @__PURE__ */ React.createElement(NumberInput, {
    name,
    onChange,
    ...args
  }), afterText));
};
export default HoldingsInput;
