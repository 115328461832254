import "./index.css";
import React, {useContext, useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {StockContext} from "../../context/StockContext.js";
import {getData} from "../../service/getData.js";
import {stockData} from "../../data/stock-code-kr.js";
const SearchCompany = () => {
  const [userInput, setUserInput] = useState("");
  const [isInputValid, setIsInputValid] = useState(true);
  const {setNewPrice, setIsLoading, setNewQuantity, setCurrentQuantity, setCompany} = useContext(StockContext);
  const setPrice = async (company) => {
    const newPrice = await getData(company);
    setNewPrice(newPrice);
    setNewQuantity(0);
    setCurrentQuantity(0);
    setIsLoading(false);
  };
  const search = async (e) => {
    e.preventDefault();
    const company = stockData.find(({companyName}) => companyName === userInput);
    if (company === void 0) {
      setIsInputValid(false);
      return;
    }
    setIsLoading(true);
    setCompany(company);
    setIsInputValid(true);
    await setPrice(company);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("form", {
    className: "company-search-form",
    onSubmit: search
  }, /* @__PURE__ */ React.createElement("input", {
    type: "text",
    placeholder: "카카오 / 삼성전자",
    className: "search-input",
    onChange: ({target}) => setUserInput(target.value.toUpperCase())
  }), /* @__PURE__ */ React.createElement("button", {
    className: "search-button",
    type: "submit"
  }, /* @__PURE__ */ React.createElement("img", {
    src: "https://img.icons8.com/fluent-systems-filled/48/000000/search.png",
    alt: "search"
  }))), !isInputValid && /* @__PURE__ */ React.createElement("div", {
    className: "alert"
  }, "종목을 찾지 못했습니다. 올바른 이름으로 검색해주세요!"));
};
export default SearchCompany;
