import "./index.css";
import React, {useContext} from "../../../_snowpack/pkg/react.js";
import {LOCALE} from "../../constants/locale.js";
import {StockContext} from "../../context/StockContext.js";
import {currencyFormatter} from "../../utils/numberFormatter.js";
const AveragePrice = () => {
  const {company, currentQuantity, newQuantity, currentHoldingsPrice, newPrice} = useContext(StockContext);
  const averagePrice = Math.floor((currentQuantity * currentHoldingsPrice + newQuantity * newPrice) / (newQuantity + currentQuantity));
  const formattedPrice = isNaN(averagePrice) ? "" : currencyFormatter(LOCALE.KR, averagePrice);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, !!company.companyName && !!newPrice && !isNaN(averagePrice) && /* @__PURE__ */ React.createElement("div", {
    className: "current-holdings-total-price"
  }, "지금 구매하시면 평균 가격이 ", /* @__PURE__ */ React.createElement("br", null), " ", /* @__PURE__ */ React.createElement("span", {
    className: "price"
  }, formattedPrice, "원"), "이 되네요!"));
};
export default AveragePrice;
