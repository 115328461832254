import "./index.css";
import React from "../../../_snowpack/pkg/react.js";
const Footer = () => {
  return /* @__PURE__ */ React.createElement("div", {
    className: "footer-container"
  }, /* @__PURE__ */ React.createElement("a", {
    target: "_blank",
    rel: "noopener noreferrer",
    href: "https://github.com/ddongule"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "footer-contents"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "icon"
  }, "© 2021"), " @ddongule")));
};
export default Footer;
